import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Formik, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import ModalDrawerSwitcher from '../../../../components/ModalDrawerSwitcher';
import Loader from '../../../../components/Loader';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Status from '../../../../components/Status';

import { upsertCostCenter } from '../../store/thunk';
import { handleRegistration, clearRegistration } from '../../store/actions';

import { validations } from './validations';

export default function CostCenterRegistration({ isOpen }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const page = useSelector(state => state.pagination.activePage);
  const pageSize = useSelector(state => state.pagination.size);
  const { qp } = useSelector(state => state.filters);
  const { isLoading, costCenterId, costCenterName, status } = useSelector(state => state.costCenters);
  const { option } = useSelector(state => state.modalDrawerSwitcher);

  const isModal = option == 'modal';

  const initialState = {
    costCenterId,
    costCenterName,
    status
  }

  const handleModal = () => {
    dispatch(handleRegistration(false));
    dispatch(clearRegistration());
  }

  const handleSave = values => {
    const properties = { page, pageSize, qp };
    const params = { costCenterId, ...values };

    dispatch(upsertCostCenter(userId, establishmentId, params, properties));
  }

  return(
    <Formik
      enableReinitialize
      initialValues={initialState}
      validationSchema={validations}
      onSubmit={params => handleSave(params)}
    >
      {({ ...formikProps }) => (
        <ModalDrawerSwitcher
          id="cost-center"
          title="Cadastro de centro de custo"
          maxWidth="sm"
          open={isOpen}
          onClose={handleModal}
        >
          <Form style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <div>
              <Loader isLoading={isLoading}>
                <Grid container spacing={2}>
                  <Grid item xs={isModal ? 8 : 12}>
                    <Input
                      autoFocus
                      id="categoryName"
                      name="costCenterName"
                      label="Nome"
                      value={formikProps.values.costCenterName}
                      error={formikProps.errors.costCenterName}
                      helperText={formikProps.errors.costCenterName}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  <Grid item xs={isModal ? 4 : 12}>
                    <Status
                      label="Status"
                      value={formikProps.values.status}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                </Grid>
              </Loader>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
              <Button
                color="tertiary"
                variant="outlined"
                loading={isLoading}
                onClick={handleModal}
              >
                Cancelar
              </Button>
              <Button
                color="success"
                loading={isLoading}
              >
                Salvar
              </Button>
            </div>
          </Form>
        </ModalDrawerSwitcher>
      )}
    </Formik>
  );
}

CostCenterRegistration.propTypes = {
  isOpen: PropTypes.bool.isRequired
};