import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  justifyContentSpaceBetween: {
    justifyContent: 'space-between'
  },
  justifyContentFlexEnd: {
    justifyContent: 'flex-end'
  },
	padding: {
		padding: 20
	},
	marginTop: {
		marginTop: 10
	},
	blue: {
		color: '#1E5168'
	},
	font18: {
		fontSize: 18
	},
  infoCard: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: 10,
    padding: 15,
    backgroundColor: 'rgb(241, 244, 249)',
    border: '1px solid #38a8d8',
    borderRadius: 10
  },
	infoCardText: {
		color: '#1E5168',
		fontSize: 15,
		fontWeight: 'bold'
	}
}));

export const useAutoCompleteStyles = makeStyles(({ palette }) => ({
  select: {
    fontWeight: props => props.displayEmpty && 500
  },
  iconOutlined: {
    color: palette.primary.main
  },
  root: {
    '& .MuiAutocomplete-inputRoot': {
      padding: '0 !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: props => props.displayEmpty && palette.primary.main,
      borderWidth: props => props.displayEmpty && 1.5
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: props => props.displayEmpty && palette.primary.main,
      borderWidth: props => props.displayEmpty && 2.5
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    marginRight: 2
  },
}));