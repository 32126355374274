import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { numericFormatter } from 'react-number-format';
import moment from 'moment';

import { Paper, Grid, Typography, Chip, Button as MUIButton } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import MTable from 'components/MTable';
import Button from 'components/Button';
import StorageMovement from 'components/StorageMovement';
import ServiceOrder from 'components/ServiceOrder/components';
import SellOrder from 'components/SellOrder/components';
import StorageHistoryResume from './StorageHistoryResume';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faEye } from '@fortawesome/free-solid-svg-icons';

import { setIsConsumeList, setIsSellList } from 'pages/Products/store/actions';
import { loadStorageToSellHistoryResume, loadStorageHistoryResume, loadStorageHistoryList } from '../store/thunk';
import { loadSellOrder } from 'pages/SellOrders/store/thunk';
import { loadServiceOrderProduct } from 'components/ServiceOrder/store/thunk';
import { toggleServiceOrder } from 'pages/OperationalHistory/store/actions';

import useDidMount from 'helpers/hooks/useDidMount';

const headers = [
  { title: 'Data/Hora', field: 'date' },
  { title: 'Entrada', field: 'entry' },
  { title: 'Saída', field: 'exit' },
  { title: 'Observação', field: 'observation' },
  { title: 'Saldo', field: 'balance' }
];

export default function StorageHistory() {
  const dispatch = useDispatch();

  const didComponentMount = useDidMount();

  const history = useHistory();

  const {
    isSell,
    isConsume,
    isConsumeAndIsSell,
    product: {
      name,
      storageProductUuid
    }
  } = history.location.state?.data;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { isConsumeList, isSellList } = useSelector(state => state.products);
  const { modal } = useSelector(state => state.operationalHistory);

  const {
    list,
    total,
    isLoading,
    isResumeLoading,
    resume: {
      toUse,
      toSell
    }
  } = useSelector(state => state.storageHistory);

  const defaultResume = {
    previousQuantity: 0,
    entries: 0,
    exits: 0,
    currentQuantity: 0
  }

  const [isSellOrderModalOpen, setIsSellOrderModalOpen] = useState(false);

  const [storageHistoryPage, setStorageHistoryPage] = useState(1);
  const storageHistoryPageSize = 10;
  const storageHistoryPageCount = Math.ceil(total / storageHistoryPageSize);

  const [isStorageMovementModalOpen, setIsStorageMovementModalOpen] = useState(false);

  const requestStorageHistory = ({ page }) => {
    dispatch(loadStorageHistoryList(userId, establishmentId, storageProductUuid, page));
  }

  useEffect(() => {
    requestStorageHistory({ page: 1 });

    if(isSellList) {
      dispatch(loadStorageToSellHistoryResume(userId, establishmentId, storageProductUuid));
      return;
    }

    if(isConsumeList) {
      dispatch(loadStorageHistoryResume(userId, establishmentId, storageProductUuid));
      return;
    }
  }, [isSellList, isConsumeList]);

  useEffect(() => {
    if(!didComponentMount) {
      requestStorageHistory({ page: storageHistoryPage });
    }
  }, [storageHistoryPage]);

  const getBalance = item => {
    if(isConsumeAndIsSell) {
      if(+item?.toSell == 1) {
        return parseInt(JSON.parse(item?.changedData)?.quantityToSell || 0);
      }

      if(+item?.toUse == 1) {
        return numericFormatter(JSON.parse(item?.changedData)?.quantityToUse?.toString() || '', { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 });
      }
    }

    if(isSell) {
      return parseInt(JSON.parse(item?.changedData)?.quantityToSell || 0);
    }

    if(isConsume) {
      return numericFormatter(JSON.parse(item?.changedData)?.quantityToUse?.toString() || '', { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 });
    }

    return '';
  }

  return(
    <Paper>
      {isSellOrderModalOpen && (
        <SellOrder
          isSellOrderModalOpen={isSellOrderModalOpen}
          setIsSellOrderModalOpen={setIsSellOrderModalOpen}
          sellOrdersPage={storageHistoryPage}
          sellOrdersPageSize={storageHistoryPageSize}
          storageProductUuid={storageProductUuid}
          pageOrigin='storageHistory'
        />
      )}
      {modal && (
        <ServiceOrder
          modal={modal}
          storageHistoryProps={{ storageHistoryPage, storageProductUuid }}
          pageOrigin='storageHistory'
        />
      )}
      {isStorageMovementModalOpen && (
        <StorageMovement
          isStorageMovementModalOpen={isStorageMovementModalOpen}
          setIsStorageMovementModalOpen={setIsStorageMovementModalOpen}
          productName={name}
          storageProductUuid={storageProductUuid}
          pageOrigin='/movimentacao-de-estoque'
          productType={isSell ? 'venda' : 'consumo'}
          isStorageMovementConsume={isConsume}
          isStorageMovementSell={isSell}
        />
      )}
      <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid item style={{ padding: 10 }}>
          <Chip
            style={{ backgroundColor: 'rgba(2, 42, 92, 0.2)' }}
            label={
              <Typography color='primary' variant='subtitle1'>{name}</Typography>
            }
          />
        </Grid>
        {isConsumeAndIsSell && (
          <Grid item style={{ padding: 10 }}>
            <MUIButton
              style={{ height: 40, borderTopRightRadius: 0, borderBottomRightRadius: 0, color: isConsumeList ? '#FFFFFF' : '#757575' }}
              color={isConsumeList ? 'primary' : 'tertiary'}
              variant={isConsumeList ? 'contained' : 'outlined'}
              onClick={() => {
                dispatch(setIsConsumeList(true));
                dispatch(setIsSellList(false));
              }}
            >
              Consumo
            </MUIButton>
            <MUIButton
              style={{ height: 40, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, color: isSellList ? '#FFFFFF' : '#757575' }}
              color={isSellList ? 'primary' : 'tertiary'}
              variant={isSellList ? 'contained' : 'outlined'}
              onClick={() => {
                dispatch(setIsConsumeList(false));
                dispatch(setIsSellList(true));
              }}
            >
              Venda
            </MUIButton>
          </Grid>
        )}
      </Grid>
      {(isConsume && !isConsumeAndIsSell) && (
        <StorageHistoryResume
          resume={toUse || defaultResume}
          isSell={isSellList}
          isConsume={isConsumeList}
          isResumeLoading={isResumeLoading}
        />
      )}
      {(isSell && !isConsumeAndIsSell) && (
        <StorageHistoryResume
          resume={toSell || defaultResume}
          isSell={isSellList}
          isConsume={isConsumeList}
          isResumeLoading={isResumeLoading}
        />
      )}
      {isConsumeAndIsSell && (
        <>
          {isConsumeList && (
            <StorageHistoryResume
              resume={toUse || defaultResume}
              isSell={isSellList}
              isConsume={isConsumeList}
              isResumeLoading={isResumeLoading}
            />
          )}
          {isSellList && (
            <StorageHistoryResume
              resume={toSell || defaultResume}
              isSell={isSellList}
              isConsume={isConsumeList}
              isResumeLoading={isResumeLoading}
            />
          )}
        </>
      )}
      <Grid container style={{ marginBottom: 20 }}>
        <Grid xs={12} style={{ marginLeft: 10 }}>
          <Button
            type='button'
            color='primary'
            startIcon={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FontAwesomeIcon
                  style={{ marginLeft: 10 }}
                  icon={faArrowRight}
                  color="#FFFFFF"
                  size="xs"
                />
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  color="#FFFFFF"
                  size="xs"
                />
              </div>
            }
            onClick={() => setIsStorageMovementModalOpen(true)}
          >
            Movimentar Estoque
          </Button>
        </Grid>
      </Grid>
      <MTable
        loading={isLoading}
        headers={headers}
        data={list?.map(item => {
          return {
            date: moment(item?.date).format('DD/MM/YYYY HH:mm:ss'),
            entry: +item?.shiftTypeId == 1 ? (isSell ? parseInt(item?.quantity || 0) : numericFormatter(item?.quantity?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })) : '-',
            exit: +item?.shiftTypeId == 2 ? (isSell ? parseInt(item?.quantity || 0) : numericFormatter(item?.quantity?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })) : '-',
            observation: item?.eventTypeName,
            serviceOrderServiceProductId: item?.serviceOrderServiceProductId ? item?.serviceOrderServiceProductId : null,
            orderUuid: item?.orderUuid ? item?.orderUuid : null,
            balance: getBalance(item)
          }
        })}
        actions={[
          rowData => !!rowData?.serviceOrderServiceProductId && ({
            icon: () => (
              <FontAwesomeIcon
                icon={faEye}
                color="#022A5C"
                size="xs"
              />
            ),
            tooltip: 'Visualizar OS',
            onClick: (_, rowData) => {
              dispatch(loadServiceOrderProduct(userId, establishmentId, rowData?.serviceOrderServiceProductId));
              dispatch(toggleServiceOrder(true));
            }
          }),
          rowData => !!rowData?.orderUuid && ({
            icon: () => (
              <FontAwesomeIcon
                icon={faEye}
                color="#022A5C"
                size="xs"
              />
            ),
            tooltip: 'Visualizar ordem de venda',
            onClick: (_, rowData) => {
              setIsSellOrderModalOpen(true);
              dispatch(loadSellOrder(userId, establishmentId, rowData?.orderUuid));
            }
          })
        ]}
      />
      <Grid
        container
        xs={12}
        style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          count={storageHistoryPageCount}
          page={storageHistoryPage}
          onChange={(_, page) => setStorageHistoryPage(page)}
        />
      </Grid>
    </Paper>
  );
}