import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, IconButton, Typography } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import ModalDrawerChangeView from 'components/ModalDrawerChangeView';

import { Close as CloseIcon } from '@material-ui/icons';

import { useStyles } from './styles';

const Modal = ({
  id,
  open,
  onClose,
  title,
  establishmentName,
  modalActions,
  maxWidth,
  hasSwitcher = false,
  children,
  scroll,
  overlayStyle,
  header = true
}) => {
  const classes = useStyles();

  return(
    <Dialog
      disableEnforceFocus={true}
      fullWidth
      aria-labelledby={id}
      onClose={onClose}
      open={open}
      maxWidth={maxWidth}
      scroll={scroll}
      classes={{ paper: classes.scrollBody, scrollBody: classes.scrollBody }}
      PaperProps={{
        style: { boxShadow: 'none' }
      }}
      BackdropProps={{
        style: overlayStyle || {}
      }}
    >
      {header && (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 24px' }}>
          <Typography variant="h6" color="primary">
            {title} {establishmentName && <small>({establishmentName})</small>}
          </Typography>
          <div>
            {hasSwitcher && (
              <ModalDrawerChangeView />
            )}
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      )}
      <MuiDialogContent
        dividers
        style={{
          overflowY: 'scroll'
        }}
      >
        {children}
      </MuiDialogContent>
      {modalActions && (
        <MuiDialogActions>{modalActions}</MuiDialogActions>
      )}
    </Dialog>
  );
}

Modal.defaultProps = {
  establishmentName: '',
  maxWidth: 'md',
  scroll: 'paper'
};

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  establishmentName: PropTypes.string,
  modalActions: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  children: PropTypes.string.isRequired,
  scroll: PropTypes.string
};

export default Modal;