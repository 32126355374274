import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Paper, Grid, Typography } from '@material-ui/core';
import Button from 'components/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar } from '@fortawesome/free-solid-svg-icons';

import { setToken } from 'services';
import { loadPaidServiceOrders } from 'pages/OperationalHistory/store/thunk';
import { setPaidServiceOrders } from 'pages/OperationalHistory/store/actions';

import useWindowDimensions from 'helpers/hooks/useWindowDimensions';

const Logo = require('../../../assets/img/logo_jump.svg');

export default function VehicleTracking() {
  const dispatch = useDispatch();

  const history = useHistory();

  const { width } = useWindowDimensions();
  const isSm = width < 800;
  const isMd = width < 1060;

  const { token } = useSelector(state => state.login)
  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { paidServiceOrders, isPaidServiceOrdersLoading } = useSelector(state => state.operationalHistory);

  const hasServices = +paidServiceOrders.length > 0;

  useEffect(() => {
    if(!token) {
      return history.push('/login');
    }

    if(token) {
      setToken(token);
    }
  }, [token]);

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadPaidServiceOrders(userId, establishmentId));
    }

    if(!establishmentId) {
      dispatch(setPaidServiceOrders([]));
    }
  }, []);

  useEffect(() => {
    if(establishmentId) {
      const requestInterval = setInterval(() => {
        dispatch(loadPaidServiceOrders(userId, establishmentId));
      }, 5000);

      return () => clearInterval(requestInterval);
    }
  }, [establishmentId]);

  const getGridColumns = () => {
    if(isSm) {
      return 'repeat(1, 1fr)';
    }

    if(isMd) {
      return 'repeat(3, 1fr)';
    }

    return 'repeat(5, 1fr)';
  }

  const getCardColor = order => {
    const { situationId } = order;

    if(+situationId == 1) {
      return '#FFA43A';
    }

    if(+situationId == 2) {
      return '#13BC4B';
    }

    return '#FFA43A';
  }

  return(
    <Paper style={{ height: '100vh', backgroundColor: '#FCFCFC' }}>
      <Grid container alignItems='flex-start' style={{ height: !hasServices ? '100vh' : 'auto' }}>
        <Grid container xs={12} justify='space-between' alignItems='center' style={{ padding: 10 }}>
          <img
            src={Logo}
            width={150}
          />
          <Button
            type='button'
            color='tertiary'
            variant='outlined'
            onClick={() => history.goBack()}
          >
            Sair
          </Button>
        </Grid>
        <Grid container xs={12} justify='center' alignItems='center'>
          {(!hasServices && !isPaidServiceOrdersLoading) && (
            <Grid item xs={12}>
              <Grid container direction='column' alignItems='center'>
                <FontAwesomeIcon
                  icon={faCar}
                  color="#1E5168"
                  size='5x'
                />
                <Typography
                  color='primary'
                  variant='h6'
                  style={{ marginTop: 10 }}
                >
                  Não há veículos pagos até o momento.
                </Typography>
              </Grid>
            </Grid>
          )}
          {hasServices && (
            <div style={{ display: 'grid', gap: 10, gridTemplateColumns: getGridColumns(), width: '100vw', padding: 10 }}>
              {paidServiceOrders?.map(order => {
                const observation = JSON.parse(order?.observations)?.observation || '-';

                return(
                  <div style={{ backgroundColor: getCardColor(order), padding: 10, textAlign: 'center' }}>
                    <Typography variant='h3' style={{ color: '#FFFFFF' }}>
                      {order?.plate || '-'}
                    </Typography>
                    <div style={{ marginTop: 10 }}>
                      <Typography style={{ color: '#FFFFFF', fontSize: '2.8rem' }}>
                        {observation}
                      </Typography>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}