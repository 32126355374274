import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assoc } from 'ramda';
import { Formik, Form } from 'formik';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faMinusCircle, faEye, faEyeSlash, faArrowRight, faArrowDown } from '@fortawesome/free-solid-svg-icons';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Collapse, TextField, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '../../../components/Card';
import Filter from '../../../components/Filter/components';
import FilterMobile from '../../../components/FilterMobile/components';
import MTable from '../../../components/MTable';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import ModalConfirmation from '../../../components/ModalConfirmation';

import { loadServices, loadSingleService, loadServicesCategories, upsertService, removeServices, changeVisibility } from '../store/thunk';
import { clearServicesSelected, selectSingleService, toggleRemoveModal, selectServices, clearForm } from '../store/actions';

import { convertFormattedMoneyToNumber, convertToDB } from '../../../helpers/converters';
import { formatFilters, formatServicesCategories } from '../../../helpers/formatters';
import { validations } from './validations';

import useWindowDimensions from '../../../helpers/hooks/useWindowDimensions';
import { useScreenMeasure } from '../../../helpers/hooks/useScreenMeasure';

import { useStyles } from './styles';

const headers = [
  { title: 'Categoria do serviço', field: 'categoriesLabel' },
  { title: 'Nome do serviço', field: 'description' },
  { title: 'Preço', field: 'amount' }
];

const width = window.innerWidth;
const tableRowWidth = width < 768 ? 120 : 100;

export default function Services() {
  const dispatch = useDispatch();

  const classes = useStyles();

  const [isMobile] = useScreenMeasure();

  const { width } = useWindowDimensions();
  const isSm = width < 960;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { selects, qp } = useSelector(state => state.filters);

  const {
    list,
    total,
    submit,
    serviceId,
    serviceIds,
    servicesCategories,
    isLoading,
    isRemoving,
    isUpdating
  } = useSelector(state => state.services);

  const [servicesPage, setServicesPage] = useState(1);
  const servicesPageSize = 10;
  const servicesPageCount = Math.ceil(total / servicesPageSize);

  const serviceDescriptionMaxLength = 120;

  const disableRemoveButton = serviceIds.length > 0 && serviceIds.length < 2
    ? !serviceIds.includes(serviceId)
    : serviceIds.length > 1;

  const filters = formatFilters(['status'], selects)
    ?.concat({
      advanced: false,
      id: "serviceCategoryId",
      label: "Categoria do Serviço",
      type: "select",
      value: "",
      options: servicesCategories
    });

  const handleLoadServices = ({ page, qp }) => {
    const params = {
      userId,
      establishmentId,
      page,
      pageSize: servicesPageSize,
      qp
    }

    dispatch(loadServices(params));
  }

  const handleRemoveSingleService = row => {
    const { serviceId } = row;

    dispatch(selectSingleService(serviceId));
    dispatch(toggleRemoveModal(!isRemoving));
  }

  const handleServiceEdit = (userId, establishmentId, service) => {
    const { serviceId } = service;
    dispatch(loadSingleService(userId, establishmentId, serviceId));
  }

  const handleServiceVisibility = (rowData, serviceId, status) => {
    const { amount, description } = rowData;

    const data = {
      amount: convertFormattedMoneyToNumber(amount),
      description
    }

    dispatch(changeVisibility(userId, establishmentId, serviceId, !status, data))
      .then(() => handleLoadServices({ page: servicesPage, qp }));
  }

  useEffect(() => {
    if(userId) {
      dispatch(loadServicesCategories(userId));
    }
  }, [userId]);

  useEffect(() => {
    if(establishmentId) {
      handleLoadServices({ page: 1, qp });
    }
  }, [establishmentId]);

  useEffect(() => {
    if(establishmentId) {
      handleLoadServices({ page: servicesPage, qp });
    }
  }, [servicesPage]);

  const handleServicesRegistration = (values, actions) => {
    const { resetForm } = actions;

    delete values['isCollapseOpen'];

    const params = {
      ...values,
      amount: convertToDB(values.amount),
      categories: values.categories?.filter(category => !!category)
    }

    dispatch(upsertService(userId, establishmentId, serviceId, params)).then(async () => {
      await handleLoadServices({ page: servicesPage, qp });
      await dispatch(clearForm());
      await resetForm();
    });
  }

  const handleRemoveModal = () => {
    dispatch(toggleRemoveModal(!isRemoving));
  }

  const handleRemoveServices = () => {
    dispatch(removeServices(userId, establishmentId, serviceIds)).then(() => {
      handleLoadServices({ page: servicesPage, qp });
    });
  }

  const handleSelectAllRows = rows => {
    dispatch(clearServicesSelected());

    rows.forEach(row => {
      const { serviceId } = row;
      dispatch(selectServices(serviceId, row.tableData.checked));
    });
  }

  const initialValues = {
    ...submit,
    isCollapseOpen: isUpdating && (!!submit?.detailing || submit?.categories?.length > 0)
  }

  return(
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card title="Serviços">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validations}
            onSubmit={handleServicesRegistration}
          >
            {({ ...formikProps }) => (
              <Form style={{ width: '100%' }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <Input
                      name="description"
                      label="Nome do Serviço"
                      value={formikProps.values.description || ""}
                      error={formikProps.errors.description}
                      helperText={formikProps.errors.description}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Input
                      name="amount"
                      type="money"
                      label="Preço"
                      placeholder="R$ 0,00"
                      value={formikProps.values.amount || ""}
                      error={formikProps.errors.amount}
                      helperText={formikProps.errors.amount}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  {!isSm && (
                    <Grid item xs={12} sm={4} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginTop: '10px' }}>
                      {isUpdating ? (
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Button
                              fullWidth
                              type="button"
                              variant="outlined"
                              color="error"
                              loading={isLoading}
                              onClick={() => dispatch(clearForm())}
                            >
                              Cancelar
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              fullWidth
                              type="submit"
                              color="success"
                              loading={isLoading}
                            >
                              Salvar
                            </Button>
                          </Grid>
                        </Grid>
                      ) : (
                        <Button
                          fullWidth
                          type="submit"
                          color="primary"
                          loading={isLoading}
                        >
                          Cadastrar
                        </Button>
                      )}
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={2}>
                  <Grid xs={12}>
                    <Button
                      type="button"
                      variant="text"
                      style={{ backgroundColor: 'transparent' }}
                      onClick={() => formikProps.setFieldValue('isCollapseOpen', !formikProps.values.isCollapseOpen)}
                    >
                      <Typography color="primary" style={{ textTransform: 'initial' }}>
                        <b>Categorias e Descrições</b>
                      </Typography>
                      <FontAwesomeIcon
                        icon={formikProps.values.isCollapseOpen ? faArrowDown : faArrowRight}
                        color="#1E5168"
                        size="lg"
                        style={{ marginLeft: 10 }}
                      />
                    </Button>
                  </Grid>
                </Grid>
                <Collapse in={formikProps.values.isCollapseOpen}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={3}>
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        id="serviceCategories"
                        classes={classes}
                        options={servicesCategories}
                        value={servicesCategories?.filter(category => formikProps.values.categories?.includes(category.value)) || []}
                        onChange={(_, categories) => {
                          return formikProps.setFieldValue('categories', categories?.map(category => category?.value));
                        }}
                        getOptionSelected={(option, value) => option.value == value.value}
                        getOptionLabel={option => option.label}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            color="primary"
                            label="Categoria do Serviço"
                          />
                        )}
                        renderOption={(option, { selected }) => (
                          <li color="primary">
                            <Checkbox checked={selected} />
                            {option.label}
                          </li>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Input
                        name="detailing"
                        label={`Descrição do Serviço (Max. ${serviceDescriptionMaxLength} caracteres)`}
                        maxLength={serviceDescriptionMaxLength}
                        value={formikProps.values.detailing || ""}
                        error={formikProps.errors.detailing}
                        helperText={formikProps.errors.detailing}
                        onChange={formikProps.handleChange}
                      />
                      <Typography variant="subtitle2" color="textSecondary">
                        Caracteres: {formikProps.values.detailing?.length || 0}/{serviceDescriptionMaxLength}
                      </Typography>
                    </Grid>
                  </Grid>
                </Collapse>
                {isSm && (
                  <Grid item xs={12} sm={4} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginTop: '10px' }}>
                    {isUpdating ? (
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Button
                            fullWidth
                            type="button"
                            color="error"
                            loading={isLoading}
                            onClick={() => dispatch(clearForm())}
                          >
                            Cancelar
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            fullWidth
                            type="submit"
                            color="success"
                            loading={isLoading}
                          >
                            Salvar
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Button
                        fullWidth
                        type="submit"
                        color="primary"
                        loading={isLoading}
                      >
                        Cadastrar
                      </Button>
                    )}
                  </Grid>
                )}
              </Form>
            )}
          </Formik>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          {isMobile ? (
            <FilterMobile
              filter
              hasSearchTerm
              filters={filters}
              handleToRemoveItems={handleRemoveModal}
              itemsToRemove={serviceIds}
              handlePageRequest={handleLoadServices}
            />
          ) : (
            <Filter
              filter
              hasSearchTerm
              filters={filters}
              handleToRemoveItems={handleRemoveModal}
              itemsToRemove={serviceIds}
              handlePageRequest={handleLoadServices}
            />
          )}
          <MTable
            loading={isLoading}
            headers={headers}
            data={list?.map(service => {
              return {
                ...service,
                categoriesLabel: formatServicesCategories(service?.categories, servicesCategories)
              }
            })}
            actionColumnWidth={tableRowWidth}
            onSelectionChange={handleSelectAllRows}
            rowStyle={{ height: "75px" }}
            actions={[
              rowData => ({
                icon: () => (
                  <FontAwesomeIcon
                    icon={faMinusCircle}
                    color="#F66D6E"
                    size="xs"
                    style={{ opacity: disableRemoveButton ? 0.5 : 1 }}
                  />
                ),
                onClick: () => handleRemoveSingleService(rowData),
                disabled: disableRemoveButton
              }),
              rowData => ({
                icon: () => (
                  <FontAwesomeIcon
                    icon={faPen}
                    color="#022A5C"
                    size="xs"
                  />
                ),
                onClick: () => handleServiceEdit(userId, establishmentId, assoc('isUpdating', true, rowData))
              }),
              rowData => ({
                icon: () => (
                  <FontAwesomeIcon
                    icon={rowData?.status ? faEye : faEyeSlash}
                    color="#022A5C"
                    size="xs"
                  />
                ),
                onClick: () => handleServiceVisibility(rowData, rowData?.serviceId, rowData?.status)
              })
            ]}
          />
          <Grid
            container
            xs={12}
            style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
          >
            <Pagination
              color="primary"
              variant="outlined"
              shape="rounded"
              count={servicesPageCount}
              page={servicesPage}
              onChange={(_, page) => setServicesPage(page)}
            />
          </Grid>
        </Paper>
      </Grid>
      <ModalConfirmation
        open={isRemoving}
        isLoading={isLoading}
        onClose={handleRemoveModal}
        handleConfirmation={handleRemoveServices}
      >
        Deseja excluir o serviço e todos os valores vinculados as categorias dos veículos?
      </ModalConfirmation>
    </Grid>
  );
}