import { newAPI } from 'services';
import qs from 'qs';

export const fetchProductBrands = async (userId, establishmentId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/storage/product-brands`);
  return response.data;
}

export const createProductBrand = async (userId, establishmentId, params) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/storage/product-brands`, qs.stringify(params));
  return response.data;
}

export const updateProductBrand = async (userId, establishmentId, id, params) => {
  const response = await newAPI.patch(`${userId}/establishment/${establishmentId}/storage/product-brands/${id}`, qs.stringify(params));
  return response.data;
}

export const deleteProductBrand = async (userId, establishmentId, id) => {
  const response = await newAPI.delete(`${userId}/establishment/${establishmentId}/storage/product-brands/${id}`);
  return response.data;
}