import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { compose, over, lensProp } from 'ramda';

import { Formik, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';

import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import Select from '../../../components/Select';
import Input from '../../../components/Input';
import Loader from '../../../components/Loader';
import Button from '../../../components/Button';

import { upsertConfigurations, loadConfigurations } from '../store/thunk';
import { loadFilters } from '../../../components/Filter/store/thunk';
import { convertToOptions, convertToMinutes } from '../../../helpers/converters';
import { validations } from './validations';

const Title = ({ children }) => (
  <Typography
    variant="subtitle1"
    style={{ color: '#1E5168', fontWeigth: 'regular' }}
    gutterBottom
  >
    {children}
  </Typography>
);

const Subtitle = ({ children }) => (
  <Typography variant="subtitle2" color="textSecondary" gutterBottom>
    <i>{children}</i>
  </Typography>
);

export default function ReservationsConfig() {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { selects } = useSelector(state => state.filters);
  const { isLoading, hasConfiguration, submit } = useSelector(state => state.reservationsConfig);

  const availableTypePrices = convertToOptions(['typePrice'], selects);

  useEffect(() => {
    if (establishmentId) {
      dispatch(loadConfigurations());
      dispatch(loadFilters(userId, establishmentId));
    }
  }, [establishmentId]);

  const handleReservationsConfig = values => {
    const formatDomain = domain =>
      values.domainType === 1
        ? `${domain}`
        : `${domain}.reserva.jumppark.com.br`;

    const params = compose(
      over(lensProp('domain'), formatDomain),
      over(lensProp('toleranceTime'), convertToMinutes),
      over(lensProp('timeBetweenReservations'), convertToMinutes)
    );

    return dispatch(upsertConfigurations(params(values), hasConfiguration));
  }

  return(
    <Card title="Configure a operação de acordo com a sua preferência">
      <Formik
        enableReinitialize
        initialValues={submit}
        validationSchema={validations}
        onSubmit={handleReservationsConfig}
      >
        {({ ...formikProps }) => (
          <Form style={{ flex: 1 }}>
            <Loader isLoading={isLoading}>
              <Grid item>
                <Title>
                  Escolha o tipo de <strong>domínio da reserva</strong>
                </Title>
                <Subtitle>
                  Selecione se irá usar o subdomínio da Jump ou terá um nome
                  personalizado para o seu site
                </Subtitle>
                <Select
                  name="domainType"
                  label="Selecione o tipo de domínio"
                  options={[
                    { label: 'Domínio próprio', value: 1 },
                    { label: 'Domínio da Jump', value: 2 },
                  ]}
                  value={formikProps.values.domainType}
                  error={formikProps.errors.domainType}
                  helperText={formikProps.errors.domainType}
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Collapse in={formikProps.values.domainType}>
                <Grid item>
                  <Input
                    name="domain"
                    label="Domínio"
                    placeholder="https://"
                    disabled={!formikProps.values.domainType}
                    value={formikProps.values.domain}
                    error={formikProps.errors.domain}
                    helperText={formikProps.errors.domain}
                    onChange={formikProps.handleChange}
                  />
                </Grid>
              </Collapse>
              <Grid item>
                <Title>
                  Tempo de <strong>tolerância</strong>
                </Title>
                <Subtitle>
                  Tempo para o cliente chegar no estabelecimento depois do
                  horário de check-in
                </Subtitle>
                <Input
                  withoutFullWidth
                  name="toleranceTime"
                  type="timeRange"
                  label="Tolerância"
                  placeholder="00:00"
                  value={formikProps.values.toleranceTime}
                  error={formikProps.errors.toleranceTime}
                  helperText={formikProps.errors.toleranceTime}
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item>
                <Title>
                  Tempo <strong>mínimo</strong> para reservar
                </Title>
                <Subtitle>
                  Tempo mínimo que o cliente tem que ficar no estabelecimento
                  para poder efetuar uma reserva
                </Subtitle>
                <Input
                  withoutFullWidth
                  name="timeBetweenReservations"
                  type="timeRange"
                  label="Tempo mínimo"
                  placeholder="00:00"
                  value={formikProps.values.timeBetweenReservations}
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item>
                <Title>
                  Tabela de cobrança <strong>da reserva</strong>
                </Title>
                <Subtitle>
                  Tabela usada para cobrar o motorista durante a estadia no
                  período reservado.
                </Subtitle>
                <Select
                  multiple
                  name="typePrices"
                  label="Selecione a(s) tabela(s)"
                  options={availableTypePrices}
                  value={formikProps.values.typePrices}
                  error={formikProps.errors.typePrices}
                  helperText={formikProps.errors.typePrices}
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item>
                <Title>
                  Tabela de cobrança <strong>da reserva excedente</strong>
                </Title>
                <Subtitle>
                  Tabela usada para cobrar caso o veículo não saia no período
                  estipulado pela reserva.
                </Subtitle>
                <Select
                  name="typePriceExcess"
                  label="Selecione a(s) tabela(s)"
                  options={availableTypePrices}
                  value={formikProps.values.typePriceExcess}
                  error={formikProps.errors.typePriceExcess}
                  helperText={formikProps.errors.typePriceExcess}
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item>
                <Title>
                  Taxa de <strong>diferença</strong>
                </Title>
                <Subtitle>
                  Porcentagem de diferença entre a reserva online e a
                  pré-reserva
                </Subtitle>
                <Input
                  withoutFullWidth
                  name="differenceFee"
                  type="percentage"
                  label="Porcentagem"
                  placeholder="0%"
                  value={formikProps.values.differenceFee}
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item>
                <Title>
                  Taxa de <strong>overbooking</strong>
                </Title>
                <Subtitle>
                  Porcentagem de reservas que podem ser feitas após a lotação do
                  estacionamento.
                </Subtitle>
                <Input
                  withoutFullWidth
                  name="overbookingFee"
                  type="percentage"
                  label="Porcentagem"
                  placeholder="0%"
                  value={formikProps.values.overbookingFee}
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <CardFooter>
                <Button color="success" loading={isLoading}>
                  Salvar
                </Button>
              </CardFooter>
            </Loader>
          </Form>
        )}
      </Formik>
    </Card>
  );
}

Title.propTypes = {
  children: PropTypes.element.isRequired
};

Subtitle.propTypes = {
  children: PropTypes.element.isRequired
};