import React from 'react';
import { useSelector } from 'react-redux';

import Modal from 'components/Modal';
import Drawer from 'components/Drawer';

export default function ModalDrawerSwitcher({ id, title, open, onClose, maxWidth, modalActions, anchor, children }) {
  const { option } = useSelector(state => state.modalDrawerSwitcher);

  if(option == 'modal') {
    return(
      <Modal
        id={id}
        title={title}
        maxWidth={maxWidth}
        modalActions={modalActions}
        hasSwitcher={true}
        open={open}
        onClose={onClose}
      >
        {children}
      </Modal>
    );
  }

  if(option == 'drawer') {
    return(
      <Drawer
        anchor={anchor}
        id={id}
        title={title}
        hasSwitcher={true}
        open={open}
        onClose={onClose}
      >
        {children}
      </Drawer>
    );
  }
}