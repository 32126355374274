import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Form } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import Select from '../../../../../../components/Select';
import Input from '../../../../../../components/Input';
import Button from '../../../../../../components/Button';

import { updateRegime } from '../../../../store/thunk';

import { useStyles } from './styles';

export default function Restrictions() {
  const dispatch = useDispatch();

  const styles = useStyles();

  const { userId } = useSelector(state => state.profile);

  const {
    isRestrictionsLoading,
    restrictions,
    isRpsConfig,
    basicInfo: {
      cityCode
    }
  } = useSelector(state => state.provisionalReceiptConfig);

  const binaryOptions = [
    { value: '1', label: 'Sim' },
    { value: '0', label: 'Não' }
  ];

  const handleSubmit = values => {
    delete values['restrictionsCheckbox'];
    delete values['sequence'];
    return dispatch(updateRegime(userId, cityCode, values));
  }

  return(
    <Formik
      enableReinitialize
      initialValues={{
        ...restrictions,
        restrictionsCheckbox: isRpsConfig
      }}
      onSubmit={handleSubmit}
    >
      {({ ...formikProps }) => {
        return(
          <Form>
            <Grid container>
              <Grid item xs={12} style={{ display: 'flex', gap: 20, alignItems: 'center', marginTop: 30, marginBottom: 20, paddingBottom: 10, borderBottom: '1px solid #E4E7EA' }}>
                <Typography color="primary">
                  <b>Restrições de envio de NFS-e de cada prefeitura</b>
                </Typography>
                <Button
                  type='submit'
                  color='success'
                  loading={isRestrictionsLoading}
                  disabled={!cityCode}
                >
                  Salvar
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" color="primary" className={styles.text}>
                  <b>Cliente/Tomador:</b>
                </Typography>
                <Grid container>
                  <Grid xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" color="primary" className={styles.text}>
                      Obrigatoriedade no cadastro de cliente:
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Select
                      name="requiredClient"
                      options={binaryOptions}
                      value={formikProps.values.requiredClient?.toString()}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" color="primary" className={styles.text}>
                      Obrigatoriedade no nome:
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Select
                      disabled={+formikProps.values.requiredClient == 0}
                      name="requiredClientName"
                      options={binaryOptions}
                      value={formikProps.values.requiredClientName?.toString()}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  <Grid xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid container>
                      <Grid xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6" color="primary" className={styles.text}>
                          Preencher
                        </Typography>
                      </Grid>
                      <Grid xs={4}>
                        <Input
                          disabled={+formikProps.values.requiredClient == 0}
                          name="clientNameDefault"
                          type="text"
                          value={formikProps.values.clientNameDefault}
                          onChange={formikProps.handleChange}
                        />
                      </Grid>
                      <Grid xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6" color="primary" className={styles.text} style={{ marginLeft: 10 }}>
                          caso campo vazio
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" color="primary" className={styles.text}>
                      Obrigatoriedade no CPF/CNPJ:
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Select
                      disabled={+formikProps.values.requiredClient == 0}
                      name="requiredClientDocumentId"
                      options={binaryOptions}
                      value={formikProps.values.requiredClientDocumentId?.toString()}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  <Grid xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid container>
                      <Grid xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6" color="primary" className={styles.text}>
                          Preencher
                        </Typography>
                      </Grid>
                      <Grid xs={4}>
                        <Input
                          disabled={+formikProps.values.requiredClient == 0}
                          name="clientDocumentIdDefault"
                          type="text"
                          value={formikProps.values.clientDocumentIdDefault}
                          onChange={formikProps.handleChange}
                        />
                      </Grid>
                      <Grid xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6" color="primary" className={styles.text} style={{ marginLeft: 10 }}>
                          caso campo vazio
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" color="primary" className={styles.text}>
                      Obrigatoriedade no endereço do cliente:
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Select
                      disabled={+formikProps.values.requiredClient == 0}
                      name="requiredClientAddress"
                      options={binaryOptions}
                      value={formikProps.values.requiredClientAddress?.toString()}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  <Grid xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid container>
                      <Grid xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6" color="primary" className={styles.text}>
                          Preencher
                        </Typography>
                      </Grid>
                      <Grid xs={4}>
                        <Input
                          disabled={+formikProps.values.requiredClient == 0}
                          name="clientAddressDefault"
                          type="text"
                          value={formikProps.values.clientAddressDefault}
                          onChange={formikProps.handleChange}
                        />
                      </Grid>
                      <Grid xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6" color="primary" className={styles.text} style={{ marginLeft: 10 }}>
                          caso campo vazio
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" color="primary" className={styles.text}>
                  <b>Empresa:</b>
                </Typography>
                <Grid container>
                  <Grid xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" color="primary" className={styles.text}>
                      CNAE:
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Select
                      name="requiredCnae"
                      options={binaryOptions}
                      value={formikProps.values.requiredCnae?.toString()}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" color="primary" className={styles.text}>
                      Código Serviço:
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Select
                      name="requiredMunicipalServiceCode"
                      options={binaryOptions}
                      value={formikProps.values.requiredMunicipalServiceCode?.toString()}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" color="primary" className={styles.text}>
                      Código Item Lista Serviço:
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Select
                      name="requiredServiceItem"
                      options={binaryOptions}
                      value={formikProps.values.requiredServiceItem?.toString()}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                </Grid>
                <br />
                <Typography variant="h6" color="primary" className={styles.text}>
                  <b>Cancelamento de NFS-e:</b>
                </Typography>
                <Grid container>
                  <Grid xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" color="primary" className={styles.text}>
                      Cancelamento NF-e precisa do cadastro de cliente:
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Select
                      name="requiredClientToCancel"
                      options={binaryOptions}
                      value={formikProps.values.requiredClientToCancel?.toString()}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid xs={8} style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" color="primary" className={styles.text}>
                      Cancelamento de NF-e precisa do CPF/CNPJ do cliente:
                    </Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Select
                      name="requiredClientDocumentIdToCancel"
                      options={binaryOptions}
                      value={formikProps.values.requiredClientDocumentIdToCancel?.toString()}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}