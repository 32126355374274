import { StyleSheet, Font } from '@react-pdf/renderer';

import RobotoRegular from './fonts/Roboto-Regular.ttf';
import RobotoBold from './fonts/Roboto-Bold.ttf';

const BORDER_STYLE = 'solid 1px';
const BORDER_COLOR = '#666666';
const BACKGROUND_COLOR = '#e2e2e2';
const FONT_COLOR = '#505050';
const SUCCESS_COLOR = '#4dbd74';
const DANGER_COLOR = '#f86c6b';
const PRIMARY_COLOR = '#20a8d8';
const DEFAULT_COLOR = '#1e5168';
const COLOR_BLACK = '#000000';
const COLOR_DEFAULT_BLACK = '#212121';

Font.register({
  family: 'Roboto-Regular',
  fonts: [
    {
      src: RobotoRegular,
      fontWeight: '400'
    }
  ]
});

Font.register({
  family: 'Roboto-Bold',
  fonts: [
    {
      src: RobotoBold,
      fontWeight: '700'
    }
  ]
});

export const styles = StyleSheet.create({
  fontSize20: {
    fontSize: 20
  },
  fontSize15: {
    fontSize: 15
  },
  fontSize13: {
    fontSize: 13
  },
  fontSize11: {
    fontSize: 11
  },
  fontSize8: {
    fontSize: 8
  },
  fontSize6: {
    fontSize: 6.5
  },
  fontBold: {
    fontFamily: 'Roboto-Bold'
  },
  noBorderTop: {
    borderTop: 'none'
  },
  noMargin: {
    margin: 0
  },
  marginBottom5: {
    marginBottom: 5
  },
  tableCellBorderBottom: {
    borderBottomColor: BORDER_COLOR,
    borderBottomWidth: 1
  },
  colorDefaultBlack: {
    color: COLOR_DEFAULT_BLACK
  },
  colorBlack: {
    color: COLOR_BLACK
  },
  defaultColor: {
    color: DEFAULT_COLOR
  },
  secondaryColor: {
    color: '#C4C4C4'
  },
  body: {
    padding: 10
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom: 10
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tableColHeader: {
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    fontFamily: 'Roboto-Bold',
    margin: 5,
    fontSize: 10,
    fontWeight: 700,
    color: FONT_COLOR
  },
  tableCell: {
    fontFamily: 'Roboto-Regular',
    margin: 5,
    fontSize: 8,
    color: FONT_COLOR
  },
  headerRow: {
    marginBottom: 10
  },
  text: {
    fontFamily: 'Roboto-Regular',
    fontSize: 10,
    marginBottom: 10,
    color: FONT_COLOR
  },
  footer: {
    display: 'table',
    width: 'auto',
    backgroundColor: BACKGROUND_COLOR,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    paddingTop: 8,
    paddingLeft: 8
  },
  title: {
    fontFamily: 'Roboto-Bold',
    fontSize: 12,
    fontWeight: '700',
    color: FONT_COLOR,
    textTransform: 'capitalize',
    marginBottom: 5
  },
  subtitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 10,
    color: FONT_COLOR
  },
  label: {
    fontFamily: 'Roboto-Bold',
    fontSize: 10,
    fontWeight: '700',
    color: FONT_COLOR,
    marginBottom: 5
  },
  input: {
    fontFamily: 'Roboto-Regular',
    fontSize: 10,
    color: FONT_COLOR
  },
  column: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  fullBorder: {
    borderLeftWidth: 1,
    borderTopWidth: 1
  },
  flexEnd: {
    justifyContent: 'flex-end'
  },
  focusBackground: {
    backgroundColor: BACKGROUND_COLOR
  },
  cashFlowCell: {
    width: `${100 / 3}%`,
    paddingTop: 5,
    paddingLeft: 5,
    borderWidth: 3,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 1
  },
  cashFlowSuccess: {
    borderBottomColor: SUCCESS_COLOR,
    borderRightColor: BORDER_COLOR
  },
  cashFlowDanger: {
    borderBottomColor: DANGER_COLOR,
    borderRightColor: BORDER_COLOR
  },
  cashFlowPrimary: {
    borderBottomColor: PRIMARY_COLOR,
    borderRightColor: BORDER_COLOR
  },
  fontSuccess: {
    fontFamily: 'Roboto-Regular',
    color: SUCCESS_COLOR
  },
  fontDanger: {
    fontFamily: 'Roboto-Regular',
    color: DANGER_COLOR
  },
  fontPrimary: {
    fontFamily: 'Roboto-Regular',
    color: PRIMARY_COLOR
  },
  borderLeft: {
    borderLeftWidth: 1,
    borderLeftColor: BORDER_COLOR
  },
  image: {
    height: '50px',
    width: 'auto'
  },
  textCenter: {
    textAlign: 'center'
  }
});

export const colSize = size => {
  switch (size) {
    case 1: {
      return '65%';
    }
    case 2: {
      return '35%';
    }
    default: {
      return '100%';
    }
  }
}

export const tableCol = size => [
  styles.tableCol,
  {
    width: colSize(size),
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10
  }
]

export const table = [styles.table, styles.fullBorder];