import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Grid, Paper, Typography, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from 'components/Select';
import Button from 'components/Button';
import ModalDrawerSwitcher from 'components/ModalDrawerSwitcher';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { registerNewServiceNotificationConfig, upsertServiceNotificationConfig } from './store/thunk';
import { loadServices } from 'pages/Services/store/thunk';
import { loadFilters } from 'components/Filter/store/thunk';
import { loadTags } from '../../store/thunk';
import { loadServicesCategories } from 'pages/Services/store/thunk';
import { fetchServiceConfig } from '../../store/services';

import { formatPhone, formatWhatsAppMessageLineBreak, formatServicesCategories } from 'helpers/formatters';

import { useStyles, useAutoCompleteStyles } from './styles';

const DEFAULT_FIRST_NOTIFICATION =
  'Olá, {cliente_nome}! Sabia que faz {prazo_lembrete} dias que fez o serviço {nome_servico}? \n\nSeria interessante fazer uma manutenção e manter o seu carro em dia. \n\nO que acha de agendar um serviço? \n\nEstamos a disposição.'

const DEFAULT_SECOND_NOTIFICATION =
  'Olá, {cliente_nome}! Entramos em contato recentemente para lembrar sobre a manutenção do serviço {nome_servico} feito no dia {prazo_lembrete}. \n\nTem interesse em fazer o serviço novamente e manter seu carro em dia? \n\nEstamos a disposição.';

export default function ServiceNotificationConfig() {
  const dispatch = useDispatch();

	const classes = useStyles();
  const autoCompleteStyles = useAutoCompleteStyles();

	const userId = useSelector(state => state.profile.userId);
  const establishmentId = useSelector(state => state.businessInfo.establishmentId);
	const { clientId } = useSelector(state => state.clientRegistration.basicInfo);
	const phone = useSelector(state => state.businessInfo.submit.phone);
  const { servicesCategories } = useSelector(state => state.services);

  const currentNotificationCategory = useSelector(state => state.notificationsConfig.currentCategory) || 1;

	const services = useSelector(state => state.filters)
    ?.selects
    ?.find(filter => filter?.id == 'services')
    ?.options
    ?.map(service => {
      return {
        value: service?.serviceId,
        label: service?.description,
        categories: service?.categories?.filter(category => !!category)
      }
    })
    ?.filter(service => !!service?.value);

	const tags = useSelector(state => state.notificationsConfig.tags).map(tagConfig => {
    return {
      value: tagConfig.tag,
      label: tagConfig.description
    }
  });

  const configList = useSelector(state => state.services.list).map(service => {
		return {
			serviceId: service.serviceId,
			daysAfter: null,
			contents: [DEFAULT_FIRST_NOTIFICATION, DEFAULT_SECOND_NOTIFICATION]
		}
	});

	const [notificationConfig, setNotificationConfig] = useState(configList);

  const [isTestNotificationModalOpen, setIsTestNotificationModalOpen] = useState(false);
	const [isFirstNotification, setIsFirstNotification] = useState(false);

  const [id, setId] = useState(null);
  const [status, setStatus] = useState(1);
	const [firstNotification, setFirstNotification] = useState(DEFAULT_FIRST_NOTIFICATION);
	const [secondNotification, setSecondNotification] = useState(DEFAULT_SECOND_NOTIFICATION);
	const [daysAfterSale, setDaysAfterSale] = useState(null);
	const [selectedService, setSelectedService] = useState(null);
  const [userPhone, setUserPhone] = useState(phone || null);

	useEffect(() => {
		const params = {
			userId,
			establishmentId,
			clientId,
			page: 1,
			pageSize: 50,
      qp: ''
		}

		dispatch(loadServices(params));
		dispatch(loadTags(params));
    dispatch(loadFilters(userId, establishmentId));
    dispatch(loadServicesCategories(userId));
	}, [establishmentId]);

  useEffect(() => {
    if(selectedService) {
      (async () => {
        const params = {
          userId,
          establishmentId,
          notificationCategoryId: currentNotificationCategory,
          serviceId: selectedService
        }

        const service = await fetchServiceConfig(params);

        const config = notificationConfig.find(notification => notification.serviceId == selectedService);

        setId(service?.id);
        setStatus(service?.status == 0 || undefined ? 0 : 1);
        setDaysAfterSale(service?.daysAfterSale || config?.daysAfter || '');
        setFirstNotification(service?.contents[0] || config?.contents[0] || DEFAULT_FIRST_NOTIFICATION);
        setSecondNotification(service?.contents[1] || config?.contents[1] || DEFAULT_SECOND_NOTIFICATION);
      })();
    }
  }, [selectedService]);

	useEffect(() => {
		if(selectedService) {
			const prevConfig = notificationConfig.find(notification => notification.serviceId == selectedService);
			const configIndex = notificationConfig.findIndex(notification => notification.serviceId == selectedService);

			const config = {
				serviceId: selectedService,
				daysAfter: daysAfterSale || prevConfig?.daysAfter,
				contents: [firstNotification, secondNotification] || prevConfig?.contents
			}

			const updatedConfig = [...notificationConfig];
			updatedConfig[configIndex] = config;

			setNotificationConfig(updatedConfig);
		}
	}, [daysAfterSale, firstNotification, secondNotification]);

	const handleCorrectPhoneFormat = phone => {
		const formattedPhone = phone.replace(/[ +_()-]/g, '');
		return `+55${formattedPhone}`;
	}

	const handleSendTestNotification = notification => {
    const formattedNotification = formatWhatsAppMessageLineBreak(notification);
		const phone = handleCorrectPhoneFormat(userPhone);
		const message = `https://wa.me/${phone}/?text=${formattedNotification}`;

	  window.open(message, '_blank').focus();
	}

	const handleSelectedTag = (tag, notification, setNotification) => {
		setNotification(`${notification}${tag}`);
	}

  const handleSaveNotification = () => {
    const notificationShape = {
      status: status.toString(),
      notificationCategoryId: currentNotificationCategory,
      services: [selectedService],
      contents: [firstNotification, secondNotification],
      daysAfterSale
    }

    const params = {
      notificationSettingId: id,
      userId,
      establishmentId,
      notificationShape
    }

    const canUserRegisterNotificationConfig =
      selectedService &&
      firstNotification &&
      secondNotification &&
      daysAfterSale;

    if(canUserRegisterNotificationConfig) {
      if(id) {
        return upsertServiceNotificationConfig(params);
      }

      return registerNewServiceNotificationConfig(params);
    }

    toast.error('Para configurar um lembrete é necessário preencher todos os campos.');
  }

  const handleValidatePhoneNumber = (number) => {
    const formattedNumber = formatPhone(number);
    setUserPhone(formattedNumber);
  }

	return(
		<Paper className={classes.padding}>
			<Typography className={classes.blue}>Configure os lembretes pra cada serviço</Typography>
			<Grid container className={classes.infoCard}>
				<Typography className={classes.infoCardText}>Atenção:</Typography>
				<Typography className={classes.infoCardText}>
					1. Para aumentar a conversão do retorno dos clientes, configure cada
					lembrete individualmente para cada serviço.
				</Typography>
				<Typography className={classes.infoCardText}>
					2. Se não for editado, o lembrete seguirá um padrão definido pelo próprio sistema.
				</Typography>
			</Grid>
			<Grid container className={[classes.marginTop, classes.justifyContentSpaceBetween]}>
				<Grid item xs={12} md={6} style={{ padding: 5 }}>
          <Autocomplete
            id="serviceOptions"
            getOptionLabel={option => option.label}
            classes={autoCompleteStyles}
            options={services}
            loading={false}
            value={services?.find(service => +service?.value == +selectedService) || {}}
            loadingText={<p>Carregando...</p>}
            noOptionsText={<p>Sem opções</p>}
            onChange={(_, service) => {
              if(service) {
                setSelectedService(service.value);
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Serviço"
                variant="outlined"
              />
            )}
            renderOption={props => {
              return(
                <span {...props}>
                  <Typography color='primary'>{props.label}</Typography>
                  {(props?.categories?.length > 0) && (
                    <Typography color='textSecondary'>Categoria(s): {formatServicesCategories(props?.categories, servicesCategories)}</Typography>
                  )}
                </span>
              );
            }}
          />
				</Grid>
				<Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={12} md={6} style={{ padding: 5 }}>
              <TextField
                fullWidth
                variant='outlined'
                label='Enviar X dias após execução'
                value={daysAfterSale || ''}
                onChange={event => setDaysAfterSale(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 5 }}>
              <Select
                label='Status'
                options={[
                  { value: 0, label: 'Desativado' },
                  { value: 1, label: 'Ativado' }
                ]}
                value={status}
                onChange={event => setStatus(event.target.value)}
              />
            </Grid>
          </Grid>
				</Grid>
			</Grid>
			<Grid container className={classes.marginTop}>
        <Grid container xs={12} className={classes.justifyContentSpaceBetween}>
          <Grid item xs={12} md={6} container justifyContent='flex-start' alignItems='center'>
            <Typography className={[classes.blue, classes.font18]}>1º Lembrete de Serviço</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Select
              label='Variável de texto'
              options={tags}
              onChange={event => handleSelectedTag(event.target.value, firstNotification, setFirstNotification)}
            />
          </Grid>
        </Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						multiline
						rows={9}
						variant='outlined'
						label='Lembrete'
						value={firstNotification}
						onChange={event => setFirstNotification(event.target.value)}
					/>
				</Grid>
				<Grid xs={12} container className={classes.justifyContentFlexEnd}>
					<Button
            onClick={() => {
              setIsFirstNotification(true);
              setIsTestNotificationModalOpen(true);
            }}
          >
            Testar
          </Button>
				</Grid>
			</Grid>
			<Grid container className={classes.marginTop}>
        <Grid container xs={12} className={classes.justifyContentSpaceBetween}>
          <Grid item xs={12} md={6} container direction='row' justifyContent='flex-start' alignItems='center'>
            <Typography className={[classes.blue, classes.font18]}>2º Lembrete de Serviço (quando é adiado)</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Select
              label='Variável de texto'
              options={tags}
              onChange={event => handleSelectedTag(event.target.value, secondNotification, setSecondNotification)}
            />
          </Grid>
        </Grid>
				<Grid item xs={12}>
					<TextField
						fullWidth
						multiline
						rows={9}
						variant='outlined'
						label='Lembrete'
						value={secondNotification}
						onChange={event => setSecondNotification(event.target.value)}
					/>
				</Grid>
				<Grid xs={12} container className={classes.justifyContentFlexEnd}>
          <Button
            onClick={() => {
              setIsFirstNotification(false);
              setIsTestNotificationModalOpen(true);
            }}
          >
            Testar
          </Button>
				</Grid>
			</Grid>
			<Grid xs={12} container className={[classes.marginTop, classes.justifyContentFlexEnd]}>
				<Button
          color='success'
          onClick={handleSaveNotification}
        >
          Salvar
        </Button>
			</Grid>
			{isTestNotificationModalOpen && (
        <ModalDrawerSwitcher
          id='notification-test-drawer'
          title='Testar lembrete'
          open={isTestNotificationModalOpen}
          onClose={() => setIsTestNotificationModalOpen(false)}
        >
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <Grid item xs={12}>
              <Typography color='textSecondary' style={{ margin: '10px 0 10px 0' }}>
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  color="#F5B941"
                  size="lg"
                  style={{ marginRight: 10 }}
                />
                Confirme o número para envio da mensagem teste.
              </Typography>
              <TextField
                fullWidth
                variant='outlined'
                label='Número'
                value={userPhone || null}
                onChange={event => handleValidatePhoneNumber(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', gap: 10 }}>
              <Button
                color='primary'
                onClick={() => {
                  isFirstNotification
                    ? handleSendTestNotification(firstNotification)
                    : handleSendTestNotification(secondNotification);
                }}
              >
                Testar
              </Button>
            </Grid>
				  </div>
			  </ModalDrawerSwitcher>
      )}
	  </Paper>
	);
}