import React from 'react';

import { Drawer as DrawerComponent, Typography, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import ModalDrawerChangeView from 'components/ModalDrawerChangeView';

const DrawerIcon = require('../../assets/img/icon_drawer.svg');

export default function Drawer({ open, onClose, id, title, hasSwitcher = false, children }) {
  return(
    <DrawerComponent
      anchor="right"
      open={open}
      onClose={onClose}
      id={id}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0 0 10px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={DrawerIcon} />
          <Typography color='textSecondary' style={{ marginLeft: 5 }}>
            {title}
          </Typography>
        </div>
        <div>
          {hasSwitcher && (
            <ModalDrawerChangeView />
          )}
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div style={{ padding: 10, height: '100%' }}>
        {children}
      </div>
    </DrawerComponent>
  );
}