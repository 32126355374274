import * as types from './constants';

export const setProductBrands = data => ({
  type: types.SET_PRODUCT_BRANDS,
  data
});

export const setProductBrandsIsLoading = isLoading => ({
  type: types.SET_PRODUCT_BRANDS_IS_LOADING,
  isLoading
});

export const setSingleProductBrandIsLoading = isLoading => ({
  type: types.SET_SINGLE_PRODUCT_BRAND_IS_LOADING,
  isLoading
});

export const setSingleProductBrand = data => ({
  type: types.SET_SINGLE_PRODUCT_BRAND,
  data
});

export const clearSingleProductBrand = () => ({
  type: types.CLEAR_SINGLE_PRODUCT_BRAND
});