import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, Typography, TextField, Tooltip, FormControlLabel, Radio } from '@material-ui/core';
import ModalDrawerSwitcher from 'components/ModalDrawerSwitcher';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Button from 'components/Button';

import { clearComissionsErrors } from '../store/actions';
import { loadComissionConfigList, upsertComissionConfig } from '../store/thunk';
import { loadServicesCategories } from 'pages/Services/store/thunk';
import { loadFilters } from 'components/Filter/store/thunk';

import { formatServicesCategories } from 'helpers/formatters';

import { useStyles } from './styles';

export default function Comissions({ open, onClose }) {
  const dispatch = useDispatch();

  const autoCompleteStyles = useStyles();

  const allEstablishments = useSelector(state => state.login.establishments);
  const { userId } = useSelector(state => state.profile);
  const { establishmentId, establishments } = useSelector(state => state.businessInfo);
  const { submit, isLoading } = useSelector(state => state.comissions);
  const { selects } = useSelector(state => state.filters);
  const { servicesCategories } = useSelector(state => state.services);

  const establishmentUuid = establishments?.find(establishment => +establishment?.establishmentId == +establishmentId)?.uuid || '';
  const alternativeEstablishmentUuid = allEstablishments?.find(establishment => +establishment?.establishmentId == +establishmentId)?.uuid || '';

  const serviceCategories = useSelector(state => state.filters)
    ?.selects
    ?.find(filter => filter?.id == 'serviceCategory')
    ?.options;

  const services = selects
    ?.find(service => service?.id == 'services')?.options
    ?.map(service => {
      const categories = Array.isArray(service?.categories)
        ? service?.categories?.map(category => +category)
        : [];

      const selectedCategories = serviceCategories
        ?.filter(category => categories?.includes(+category?.value))
        ?.map(category => category?.label)
        ?.join(', ');

      return {
        label: service?.description,
        value: service?.serviceId,
        categories: service?.categories?.filter(category => !!category)
      }
    })
    ?.filter(service => !!service?.value);

  const profiles = useSelector(state => state.comissionedList.filters.commissionProfiles)?.options
    ?.map(profile => {
      return {
        label: profile?.name,
        value: profile?.id
      }
    })
    ?.filter(profile => profile?.value != '');

  const [selectedServiceId, setSelectedServiceId] = useState();

  const [servicesList, setServicesList] = useState({
    establishmentUuid,
    uuid: '',
    serviceId: selectedServiceId,
    profiles: []
  });

  const requestLoadComissionConfigList = (establishmentId, serviceId) => {
    dispatch(loadComissionConfigList({
      userId,
      establishmentId,
      serviceId
    }));
  }

  useEffect(() => {
    dispatch(clearComissionsErrors());
  }, []);

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadServicesCategories(userId));
      dispatch(loadFilters(userId, establishmentId));
    }
  }, [establishmentId]);

  useEffect(() => {
    if(services?.length > 0) {
      setSelectedServiceId(services[0]?.value);
      requestLoadComissionConfigList(establishmentId, services[0]?.value);
    }
  }, []);

  useEffect(() => {
    const profilesToServicesList = profiles?.map(profile => {
      return {
        profileId: profile?.value,
        type: '1',
        amount: ''
      };
    });

    const servicesWithProfiles = {
      ...submit?.uuid
        ? submit
        : { ...servicesList, uuid: '', serviceId: selectedServiceId, profiles: profilesToServicesList }
    }

    setServicesList(servicesWithProfiles);
  }, [submit]);

  const handleServiceListChange = (profileId, params) => {
    const newProfiles = servicesList.profiles?.map(profile => {
      return +profile?.profileId == +profileId ? {
        ...profile,
        ...params
      } : {
        ...profile
      }
    });

    const newServicesList = {
      ...servicesList,
      profiles: newProfiles
    }

    setServicesList(newServicesList);
  }

  const handleSave = () => {
    const profiles = servicesList.profiles;

    const newProfiles = profiles?.map(profile => {
      delete profile?.label;
      return profile;
    });

    const submitServicesList = {
      ...servicesList,
      establishmentUuid: !!establishmentUuid ? establishmentUuid : alternativeEstablishmentUuid,
      serviceId: selectedServiceId,
      profiles: newProfiles
    }

    dispatch(upsertComissionConfig(userId, establishmentId, submitServicesList))
      .then(() => onClose());
  }

  return(
    <ModalDrawerSwitcher
      id='comissions-config-drawer'
      title='Configurações do comissionamento'
      open={open}
      onClose={onClose}
    >
      <Grid xs={12} alignItems='center' style={{ height: '100%' }}>
        <Loader isLoading={isLoading}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <div>
              <Grid container spacing={2} style={{ padding: '0 15px 0 15px' }}>
                <Grid item xs={12} spacing={2}>
                  <Autocomplete
                    id="serviceOptions"
                    getOptionLabel={option => option.label}
                    classes={autoCompleteStyles}
                    options={services}
                    loading={false}
                    value={services?.find(service => +service?.value == +selectedServiceId) || services[0]}
                    loadingText={<p>Carregando...</p>}
                    noOptionsText={<p>Sem opções</p>}
                    onChange={(_, service) => {
                      if(service) {
                        setSelectedServiceId(service.value);
                        requestLoadComissionConfigList(establishmentId, service.value);
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Serviço"
                        variant="outlined"
                      />
                    )}
                    renderOption={props => {
                      return(
                        <span {...props}>
                          <Typography color='primary'>{props.label}</Typography>
                          {(props?.categories?.length > 0) && (
                            <Typography color='textSecondary'>Categoria(s): {formatServicesCategories(props?.categories, servicesCategories)}</Typography>
                          )}
                        </span>
                      );
                    }}
                  />
                </Grid>
              </Grid>
              {servicesList?.profiles?.map(profile => (
                <Grid container spacing={2} style={{ padding: '0 15px 0 15px' }}>
                  <Grid item xs={12}>
                    <Typography variant='subtitle2' color='primary' style={{ borderBottom: '1px solid #E4E7EA' }}>
                      {profiles?.find(profileList => +profileList?.value == +profile?.profileId)?.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} spacing={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Input
                      label='Valor'
                      type='money'
                      id={profile?.profileId}
                      name={profile?.profileId}
                      value={profile?.amount}
                      error={null}
                      helperText={null}
                      onChange={event => handleServiceListChange(profile?.profileId, { amount: event.target.value })}
                    />
                  </Grid>
                  <Grid item xs={6} spacing={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '60%', marginTop: 2.5 }}>
                      <FormControlLabel
                        id='percentage-radio'
                        name='percentageRadio'
                        label={
                          <Tooltip
                            title='Valor percentual'
                            placement='top'
                          >
                            <Typography color='textSecondary'>
                              Percentual (%)
                            </Typography>
                          </Tooltip>
                        }
                        control={
                          <Radio
                            style={{ padding: 2.5 }}
                            color='primary'
                            onClick={() => handleServiceListChange(profile?.profileId, { type: '1' })}
                            checked={+profile?.type == 1}
                          />
                        }
                      />
                      <FormControlLabel
                        id='monetary-radio'
                        name='monetaryRadio'
                        label={
                          <Tooltip
                            title='Valor absoluto em real'
                            placement='top'
                          >
                            <Typography color='textSecondary'>
                              Monetário ($)
                            </Typography>
                          </Tooltip>
                        }
                        control={
                          <Radio
                            style={{ padding: 2.5 }}
                            color='primary'
                            onClick={() => handleServiceListChange(profile?.profileId, { type: '2' })}
                            checked={+profile?.type == 2}
                          />
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              ))}
            </div>
            <div>
              <Grid container spacing={2} style={{ padding: '0 15px 10px 15px' }}>
                <Grid item xs={12} spacing={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type='submit'
                    color='success'
                    onClick={handleSave}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Loader>
      </Grid>
    </ModalDrawerSwitcher>
  );
}