import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography, Button as MUIButton } from '@material-ui/core';
import { Row } from '../../Resources';
import ActivitiesRegistrationHeader from 'components/ActivitiesRegistrationDetails/ActivitiesRegistrationHeader';

import { convertToReal } from 'helpers/converters';

export default function ConfigurationUpdate({ configuration, lastRegister, activityData }) {
  const IS_CURRENT = 'current';
  const IS_PREVIOUS = 'previous';

  const [selectedConfig, setSelectedConfig] = useState(IS_CURRENT);

  const { printTypes } = lastRegister;

  return(
    <Grid container>
      <Grid xs={12}>
        <ActivitiesRegistrationHeader
          activityData={activityData}
        />
        <Grid container style={{ marginTop: 20 }}>
          <Grid md={6} style={{ backgroundColor:  (selectedConfig == IS_CURRENT) ? 'rgba(44, 169, 214, 0.24)' : '#E4E7EA', borderBottom: (selectedConfig == IS_CURRENT) ? '2px solid #022A5C' : '' }}>
            <MUIButton
              style={{ width: '100%', textTransform: 'capitalize', padding: 15 }}
              onClick={() => setSelectedConfig(IS_CURRENT)}
            >
              {(selectedConfig == IS_CURRENT) ? (
                <Typography color='primary'>
                  <b>Configuração Atual</b>
                </Typography>
              ) : (
                <Typography color='textSecondary'>Configuração Atual</Typography>
              )}
            </MUIButton>
          </Grid>
          <Grid md={6} style={{ backgroundColor:  (selectedConfig == IS_PREVIOUS) ? 'rgba(44, 169, 214, 0.24)' : '#E4E7EA', borderBottom: (selectedConfig == IS_PREVIOUS) ? '2px solid #022A5C' : '' }}>
            <MUIButton
              style={{ width: '100%', textTransform: 'capitalize', padding: 15 }}
              onClick={() => setSelectedConfig(IS_PREVIOUS)}
            >
              {(selectedConfig == IS_PREVIOUS) ? (
                <Typography color='primary'>
                  <b>Configuração Anterior</b>
                </Typography>
              ) : (
                <Typography color='textSecondary'>Configuração Anterior</Typography>
              )}
            </MUIButton>
          </Grid>
        </Grid>
        <Grid container>
          {(selectedConfig == IS_CURRENT) && (
            <Grid xs={12}>
              <Grid style={{ backgroundColor: '#FCFCFC', border: '1px solid #E4E7EA', padding: 8 }}>
                <Row>
                  <Typography color='primary'>
                    <b>Comprovantes</b>
                  </Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Emissão de ticket</Typography>
                  <Typography color='textSecondary'>{printTypes?.find(printType => +printType?.value == +configuration?.print)?.label}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Observação na emissão</Typography>
                  <Typography color='textSecondary'>{configuration?.printNote}</Typography>
                </Row>
                <div style={{ marginTop: 15 }} />
                <Row>
                  <Typography color='primary'>
                    <b>Operação</b>
                  </Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Somente serviços</Typography>
                  <Typography color='textSecondary'>{+configuration?.serviceOnly == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Convênio manual</Typography>
                  <Typography color='textSecondary'>{+configuration?.manualCovenant == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Horário manual</Typography>
                  <Typography color='textSecondary'>{+configuration?.manualTime == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Entrada sem cartão e impressão</Typography>
                  <Typography color='textSecondary'>{+configuration?.recurrentClientEntryOption == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Pagamento antecipado com saída</Typography>
                  <Typography color='textSecondary'>{+configuration?.prePaidExit == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Comprovante ao selecionar uma tabela convênio</Typography>
                  <Typography color='textSecondary'>{+configuration?.requireReceiptCovantTypePrice == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Comprovante ao fazer um registro de despesa</Typography>
                  <Typography color='textSecondary'>{+configuration?.requireReceiptExpense == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <div style={{ marginTop: 15 }} />
                <Row>
                  <Typography color='primary'>
                    <b>Entrada de Veículo</b>
                  </Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Digitação ou foto da placa do veículo</Typography>
                  <Typography color='textSecondary'>{+configuration?.requiredPlateOrPhoto == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Digitação da placa do veículo</Typography>
                  <Typography color='textSecondary'>{+configuration?.requiredVehiclePlate == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Registro de foto da placa do veículo</Typography>
                  <Typography color='textSecondary'>{+configuration?.requiredEntryPhoto == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Digitação do modelo do veículo</Typography>
                  <Typography color='textSecondary'>{+configuration?.requiredVehicleModel == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Digitação da cor do veículo</Typography>
                  <Typography color='textSecondary'>{+configuration?.requiredVehicleColor == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Registro de avaria do veículo (Checklist)</Typography>
                  <Typography color='textSecondary'>{+configuration?.requiredVehicleDamage == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Obrigar cadastro de cliente com nome e telefone</Typography>
                  <Typography color='textSecondary'>{+configuration?.requiredClientRegisterInfo == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <div style={{ marginTop: 15 }} />
                <Row>
                  <Typography color='primary'>
                    <b>Caixa</b>
                  </Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Obrigar o caixa estar aberto para dar entrada num veículo</Typography>
                  <Typography color='textSecondary'>{+configuration?.requiredOpenedCashierForVehicleEntry == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Reserva de caixa</Typography>
                  <Typography color='textSecondary'>{convertToReal(configuration?.cashReserve)}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Fechamento de caixa</Typography>
                  <Typography color='textSecondary'>{+configuration?.withdrawal == 0 ? 'Automático' : 'Manual'}</Typography>
                </Row>
              </Grid>
            </Grid>
          )}
          {(selectedConfig == IS_PREVIOUS) && (
            <Grid xs={12}>
              <Grid style={{ backgroundColor: '#FCFCFC', border: '1px solid #E4E7EA', padding: 8 }}>
                <Row>
                  <Typography color='primary'>
                    <b>Comprovantes</b>
                  </Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Emissão de ticket</Typography>
                  <Typography color='textSecondary'>{printTypes?.find(printType => +printType?.value == +lastRegister?.print)?.label}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Observação na emissão</Typography>
                  <Typography color='textSecondary'>{lastRegister?.printNote}</Typography>
                </Row>
                <div style={{ marginTop: 15 }} />
                <Row>
                  <Typography color='primary'>
                    <b>Operação</b>
                  </Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Somente serviços</Typography>
                  <Typography color='textSecondary'>{+lastRegister?.serviceOnly == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Convênio manual</Typography>
                  <Typography color='textSecondary'>{+lastRegister?.manualCovenant == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Horário manual</Typography>
                  <Typography color='textSecondary'>{+lastRegister?.manualTime == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Entrada sem cartão e impressão</Typography>
                  <Typography color='textSecondary'>{+lastRegister?.recurrentClientEntryOption == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Pagamento antecipado com saída</Typography>
                  <Typography color='textSecondary'>{+lastRegister?.prePaidExit == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Comprovante ao selecionar uma tabela convênio</Typography>
                  <Typography color='textSecondary'>{+lastRegister?.requireReceiptCovantTypePrice == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Comprovante ao fazer um registro de despesa</Typography>
                  <Typography color='textSecondary'>{+lastRegister?.requireReceiptExpense == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <div style={{ marginTop: 15 }} />
                <Row>
                  <Typography color='primary'>
                    <b>Entrada de Veículo</b>
                  </Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Digitação ou foto da placa do veículo</Typography>
                  <Typography color='textSecondary'>{+lastRegister?.requiredPlateOrPhoto == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Digitação da placa do veículo</Typography>
                  <Typography color='textSecondary'>{+lastRegister?.requiredVehiclePlate == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Registro de foto da placa do veículo</Typography>
                  <Typography color='textSecondary'>{+lastRegister?.requiredEntryPhoto == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Digitação do modelo do veículo</Typography>
                  <Typography color='textSecondary'>{+lastRegister?.requiredVehicleModel == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Digitação da cor do veículo</Typography>
                  <Typography color='textSecondary'>{+lastRegister?.requiredVehicleColor == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Registro de avaria do veículo (Checklist)</Typography>
                  <Typography color='textSecondary'>{+lastRegister?.requiredVehicleDamage == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Obrigar cadastro de cliente com nome e telefone</Typography>
                  <Typography color='textSecondary'>{+lastRegister?.requiredClientRegisterInfo == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <div style={{ marginTop: 15 }} />
                <Row>
                  <Typography color='primary'>
                    <b>Caixa</b>
                  </Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Obrigar o caixa estar aberto para dar entrada num veículo</Typography>
                  <Typography color='textSecondary'>{+lastRegister?.requiredOpenedCashierForVehicleEntry == 1 ? 'Ativado' : 'Desativado'}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Reserva de caixa</Typography>
                  <Typography color='textSecondary'>{convertToReal(lastRegister?.cashReserve)}</Typography>
                </Row>
                <Row>
                  <Typography color='primary'>Fechamento de caixa</Typography>
                  <Typography color='textSecondary'>{+lastRegister?.withdrawal == 0 ? 'Automático' : 'Manual'}</Typography>
                </Row>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

ConfigurationUpdate.propTypes = {
  configuration: PropTypes.object.isRequired,
  lastRegister: PropTypes.object.isRequired,
  activityData: PropTypes.object.isRequired
};