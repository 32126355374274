import { toast } from 'react-toastify';

import {
  fetchProductBrands,
  createProductBrand,
  updateProductBrand,
  deleteProductBrand
} from './services';

import {
  setProductBrands,
  setProductBrandsIsLoading,
  setSingleProductBrandIsLoading
} from './actions';

export const loadProductBrands = (userId, establishmentId) => dispatch => {
  dispatch(setProductBrandsIsLoading(true));

  return fetchProductBrands(userId, establishmentId)
    .then(({ data }) => dispatch(setProductBrands(data)))
    .catch(() => dispatch(setProductBrands([])))
    .finally(() => dispatch(setProductBrandsIsLoading(false)));
}

export const upsertProductBrand = (userId, establishmentId, id, params) => dispatch => {
  dispatch(setSingleProductBrandIsLoading(true));

  if(id) {
    return updateProductBrand(userId, establishmentId, id, params)
      .then(() => {
        toast.success('Marca editada com sucesso.');
        dispatch(loadProductBrands(userId, establishmentId));
        return Promise.resolve();
      })
      .catch(() => {
        toast.error('Houve um erro ao editar a marca.');
        return Promise.reject();
      })
      .finally(() => dispatch(setSingleProductBrandIsLoading(false)));
  }

  return createProductBrand(userId, establishmentId, params)
    .then(() => {
      toast.success('Marca cadastrada com sucesso.');
      dispatch(loadProductBrands(userId, establishmentId));
      return Promise.resolve();
    })
    .catch(() => {
      toast.error('Houve um erro ao cadastrar a marca.');
      return Promise.reject();
    })
    .finally(() => dispatch(setSingleProductBrandIsLoading(false)));
}

export const removeProductBrand = (userId, establishmentId, id) => dispatch => {
  dispatch(setProductBrandsIsLoading(true));

  return deleteProductBrand(userId, establishmentId, id)
    .then(() => {
      dispatch(loadProductBrands(userId, establishmentId));
      toast.success('Marca excluída com sucesso.');
    })
    .catch(() => toast.error('Houve um problema ao excluir a marca'))
    .finally(() => dispatch(setProductBrandsIsLoading(false)));
}