import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ModalDrawerSwitcher from '../ModalDrawerSwitcher';
import ServiceOrderUpdate from './ActivitiesRegistrationSources/ServiceOrder/ServiceOrderUpdate';
import ServiceOrderRemotion from './ActivitiesRegistrationSources/ServiceOrder/ServiceOrderRemotion';
import InvoiceCancelling from './ActivitiesRegistrationSources/Invoice/InvoiceCancelling';
import InvoiceUpdate from './ActivitiesRegistrationSources/Invoice/InvoiceUpdate';
import InvoiceUndoPayment from './ActivitiesRegistrationSources/Invoice/InvoiceUndoPayment';
import PayrollUpdate from './ActivitiesRegistrationSources/Payroll/PayrollUpdate';
import PayrollUndoPayment from './ActivitiesRegistrationSources/Payroll/PayrollUndoPayment';
import PayrollSheetUpdate from './ActivitiesRegistrationSources/PayrollSheet/PayrollSheetUpdate';
import PayrollSheetUndoPayment from './ActivitiesRegistrationSources/PayrollSheet/PayrollSheetUndoPayment';
import ConfigurationUpdate from './ActivitiesRegistrationSources/Configuration/ConfigurationUpdate';
import PasswordUpdate from './ActivitiesRegistrationSources/Password/PasswordUpdate';
import SellOrderRemotion from './ActivitiesRegistrationSources/SellOrder/SellOrderRemotion';

import { loadServiceOrder } from 'components/ServiceOrder/store/thunk';
import { loadInvoiceDetails } from 'pages/ClientInvoices/store/thunk';
import { loadSinglePayroll } from 'pages/ComissionedPayrolls/store/thunk';
import { loadSinglePayrollSheet } from 'pages/ComissionedPayrollSheet/store/thunk';
import { loadConfigurations } from 'pages/Operational/store/thunk';
import { loadEmployeeRegister } from 'pages/EmployeesList/store/thunk';

export default function ActivitiesRegistrationDetails({
  title,
  isOpenActivitiesRegistrationDetails,
  setIsOpenActivitiesRegistrationDetails,
  data
}) {
  const dispatch = useDispatch();

  const {
    activitySourceId,
    activityTypeId,
    registerId,
    lastRegister,
    userName,
    description,
    createdAt,
    updatedAt
  } = data;

  const activityData = {
    userName,
    description,
    createdAt,
    updatedAt
  }

  const establishmentId = data?.establishmentId;

  const { userId } = useSelector(state => state.profile);
  const serviceOrder = useSelector(state => state.serviceOrder);
  const invoice = useSelector(state => state.clientInvoices.submit);
  const payroll = useSelector(state => state.comissionedPayrolls.submit);
  const payrollSheet = useSelector(state => state.comissionedPayrollSheet.submit);
  const { name } = useSelector(state => state.employeesList.employeeRegister);
  const { cashier, operation, receipt, vehicleEntry } = useSelector(state => state.operational);

  const isOsUpdate = activitySourceId == 1 && activityTypeId == 1;
  const isOsRemotion = activitySourceId == 1 && activityTypeId == 2;
  const isInvoiceUpdate = activitySourceId == 2 && activityTypeId == 1;
  const isInvoiceCancelling = activitySourceId == 2 && activityTypeId == 3;
  const isInvoiceUndoPayment = activitySourceId == 2 && activityTypeId == 5;
  const isPayrollUpdate = activitySourceId == 7 && activityTypeId == 1;
  const isPayrollUndoPayment = activitySourceId == 7 && activityTypeId == 5;
  const isPayrollSheetUpdate = activitySourceId == 6 && activityTypeId == 1;
  const isPayrollSheetUndoPayment = activitySourceId == 6 && activityTypeId == 5;
  const isConfigurationUpdate = activitySourceId == 8 && activityTypeId == 1;
  const isPasswordUpdate = activitySourceId == 9 && activityTypeId == 1;
  const isSellOrderRemotion = activitySourceId == 11 && activityTypeId == 2;

  useEffect(() => {
    if(isOsUpdate) {
      const accountProps = {
        serviceOrderId: registerId,
        userId,
        establishmentId
      }

      dispatch(loadServiceOrder(accountProps));
    }

    if(isInvoiceUpdate) {
      const {
        establishmentId,
        clientId,
        invoiceDateTime
      } = lastRegister?.invoice;

      dispatch(loadInvoiceDetails(userId, establishmentId, clientId, invoiceDateTime));
    }

    if(isPayrollUpdate) {
      dispatch(loadSinglePayroll(userId, establishmentId, registerId));
    }

    if(isPayrollSheetUpdate) {
      dispatch(loadSinglePayrollSheet(userId, establishmentId, registerId));
    }

    if(isConfigurationUpdate) {
      dispatch(loadConfigurations(userId, establishmentId));
    }

    if(isPasswordUpdate) {
      dispatch(loadEmployeeRegister(userId, registerId));
    }
  }, []);

  return(
    <ModalDrawerSwitcher
      id="activitiesRegistrationDetails"
      title={title}
      open={isOpenActivitiesRegistrationDetails}
      onClose={() => setIsOpenActivitiesRegistrationDetails(false)}
    >
      {isOsUpdate && (
        <ServiceOrderUpdate
          serviceOrder={serviceOrder}
          lastRegister={lastRegister}
          activityData={activityData}
        />
      )}
      {isOsRemotion && (
        <ServiceOrderRemotion
          lastRegister={lastRegister}
          activityData={activityData}
        />
      )}
      {isInvoiceCancelling && (
        <InvoiceCancelling
          lastRegister={lastRegister}
          activityData={activityData}
        />
      )}
      {isInvoiceUpdate && (
        <InvoiceUpdate
          invoice={invoice}
          lastRegister={lastRegister}
          activityData={activityData}
        />
      )}
      {isInvoiceUndoPayment && (
        <InvoiceUndoPayment
          lastRegister={lastRegister}
          activityData={activityData}
        />
      )}
      {isPayrollUpdate && (
        <PayrollUpdate
          lastRegister={lastRegister}
          activityData={activityData}
          payroll={payroll}
        />
      )}
      {isPayrollUndoPayment && (
        <PayrollUndoPayment
          lastRegister={lastRegister}
          activityData={activityData}
        />
      )}
      {isPayrollSheetUpdate && (
        <PayrollSheetUpdate
          lastRegister={lastRegister}
          activityData={activityData}
          payrollSheet={payrollSheet}
        />
      )}
      {isPayrollSheetUndoPayment && (
        <PayrollSheetUndoPayment
          lastRegister={lastRegister}
          activityData={activityData}
        />
      )}
      {isConfigurationUpdate && (
        <ConfigurationUpdate
          configuration={{ ...cashier, ...operation, ...receipt, ...vehicleEntry }}
          activityData={activityData}
          lastRegister={lastRegister}
        />
      )}
      {isPasswordUpdate && (
        <PasswordUpdate
          data={{ description, userName, name, createdAt }}
        />
      )}
      {isSellOrderRemotion && (
        <SellOrderRemotion
          lastRegister={JSON.parse(lastRegister)}
          activityData={activityData}
        />
      )}
    </ModalDrawerSwitcher>
  );
}

ActivitiesRegistrationDetails.propTypes = {
  title: PropTypes.string.isRequired,
  isOpenActivitiesRegistrationDetails: PropTypes.bool.isRequired,
  setIsOpenActivitiesRegistrationDetails: PropTypes.func.isRequired,
  data: PropTypes.any.isRequired
};