import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Container from '@material-ui/core/Container';
import Button from '../../../components/Button';
import { Formik, Form } from 'formik';

import { Basic, Storage, Identifiers } from './steps';

import { setProductBasic, setProductStorage } from '../store/actions';
import { loadProduct, upsertProduct, insertProductIdentifiers } from '../store/thunk';
import { loadProductsFilters } from 'pages/Products/store/thunk';

import { basicValidations } from './steps/Basic/validations';
import { storageValidations } from './steps/Storage/validations';

import { useStyles } from './styles';

export default function ProductsSellRegistration() {
  const dispatch = useDispatch();

  const history = useHistory();

  const classes = useStyles();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { isLoading } = useSelector(state => state.productsSellRegistration);
  const { productUuid } = useSelector(state => state.productsSellRegistration);
  const { storages, suppliers } = useSelector(state => state.products.filters);
  const submitBasic = useSelector(state => state.productsSellRegistration.basic);
  const submitStorage = useSelector(state => state.productsSellRegistration.storage);
  const submitIdentifiers = useSelector(state => state.productsSellRegistration.identifiers);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if(productUuid) {
      dispatch(loadProduct(userId, establishmentId, productUuid));
    }
  }, [productUuid]);

  useEffect(() => {
    dispatch(setProductStorage({ ...submitStorage, storageId: storages[0]?.id || '' }));
  }, [storages]);

  useEffect(() => {
    dispatch(loadProductsFilters(userId, establishmentId));
  }, []);

  const configurations = [
    {
      index: 0,
      id: 'basic',
      title: 'Básicas',
      component: Basic,
      validations: basicValidations
    },
    {
      index: 1,
      id: 'storage',
      title: 'Estoque',
      component: Storage,
      validations: storageValidations
    },
    {
      index: 2,
      id: 'identifiers',
      title: 'Identificadores',
      component: Identifiers,
      validations: null
    }
  ];

  const currentConfiguration = configurations.find(config => config.index == currentIndex) || {};

  const handleSubmit = params => {
    if(currentConfiguration.id == 'basic') {
      dispatch(setProductBasic(params));
      return handleNextStep();
    }

    if(currentConfiguration.id == 'storage') {
      dispatch(setProductStorage(params));

      const formattedParams = {
        ...submitBasic,
        ...params,
        supplierId: suppliers?.find(supplier => supplier?.uuid == submitBasic?.supplierId)?.id || ''
      }

      if(!formattedParams?.productBrandId) {
        delete formattedParams['productBrandId'];
      }

      if(!formattedParams?.productTypeId) {
        delete formattedParams['productTypeId'];
      }

      dispatch(upsertProduct(userId, establishmentId, productUuid, formattedParams))
        .then(() => handleNextStep());
    }

    if(currentConfiguration.id == 'identifiers') {
      dispatch(insertProductIdentifiers(userId, establishmentId, productUuid, { ...params, storageProductUuid: productUuid }))
        .then(() => history.push('/produtos'));
    }
  }

  const handlePreviousStep = () => {
    if(currentIndex == 0) {
      return history.push('/produtos');
    }

    setCurrentIndex(currentIndex - 1);
  }

  const handleNextStep = () => {
    if(currentIndex == configurations[configurations.length - 1].index) {
      return;
    }

    setCurrentIndex(currentIndex + 1);
  }

  const getInitialValues = () => {
    if(currentConfiguration.id == 'basic') {
      return submitBasic;
    }

    if(currentConfiguration.id == 'storage') {
      return submitStorage;
    }

    if(currentConfiguration.id == 'identifiers') {
      return submitIdentifiers;
    }

    return {}
  }

  return(
    <Grid>
      <Stepper
        alternativeLabel
        activeStep={currentIndex}
        nonLinear
        style={{ background: 'transparent', padding: 0, paddingBottom: 24 }}
      >
        {configurations.map((nav, index) => (
          <Step key={nav.id}>
            <StepButton
              completed={currentIndex > index}
              onClick={() => setCurrentIndex(index)}
            >
              {nav.title}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Grid>
        <Formik
          enableReinitialize
          initialValues={getInitialValues()}
          validationSchema={currentConfiguration.validations}
          validateOnChange={false}
          onSubmit={handleSubmit}
        >
          {({ ...formikProps }) => {
            return(
              <Form>
                {(currentIndex == currentConfiguration.index) && (
                  <currentConfiguration.component formikProps={formikProps} />
                )}
                <Grid className={classes.footer}>
                  <Container>
                    <Grid container justify="space-between">
                      <Button
                        loading={isLoading}
                        type="button"
                        color="tertiary"
                        variant="outlined"
                        onClick={handlePreviousStep}
                      >
                        {+currentIndex == 0 ? 'Cancelar' : 'Voltar'}
                      </Button>
                      <Button
                        loading={isLoading}
                        color="primary"
                        type="submit"
                      >
                        Avançar
                      </Button>
                    </Grid>
                  </Container>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}