import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import LayoutRoute from '../components/LayoutRoute/components';

import { setAPI, setNewAPI } from '../services';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Login,
  Dashboard,
  NotificationsList,
  NotificationsConfig,
  InitialRegistration,
  PasswordRecovery,
  PasswordReset,
  Profile,
  CashFlow,
  Transactions,
  OperationalHistory,
  SessionsRegistration,
  ActivitiesRegistration,
  Reservations,
  Services,
  EmployeesRegistration,
  EmployeesList,
  PriceTable,
  BusinessInfo,
  ClientList,
  ListClients,
  ClientInvoices,
  ClientRegistration,
  Accounts,
  Categories,
  CostCenters,
  PaymentMethods,
  ReservationsConfig,
  ProvisionalReceipt,
  ProvisionalReceiptConfig,
  ProvisionalReceiptProductConfig,
  ProvisionalReceiptTypeSelection,
  PageNotFound,
  Receipt,
  Operation,
  Cashier,
  ComissionedList,
  ComissionedRegistration,
  ComissionedPayrolls,
  ComissionedPayrollList,
  ComissionedPayrollRegistration,
  ComissionedPayrollSheet,
  VehicleDamage,
  Products,
  ProductsRegistration,
  ProductsSellRegistration,
  ProductsUseAndSellRegistration,
  ProductsTypeSelection,
  ProductsCategories,
  ProductsBrands,
  StorageHistory,
  SellOrders,
  SupplierList,
  SupplierRegistrationPage,
  VehicleTracking
} from '../pages';

setAPI();
setNewAPI();

const Routes = () => {
  return (
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        style={{ zIndex: 1999 }}
      />
      <Switch>
        <Route
          exact
          path="/"
          component={Login}
        />
        <Route
          exact
          path="/area-cliente"
          component={Login}
        />
        <Route
          exact
          path="/esqueci-a-senha"
          component={PasswordRecovery}
        />
        <Route
          exact
          path="/resetar-senha/:token/:email"
          component={PasswordReset}
        />
        <Route
          path="/:resaleId?/cadastro-inicial"
          component={InitialRegistration}
        />
        <Route
          path="/acompanhamento-veiculos"
          component={VehicleTracking}
        />
        <LayoutRoute
          showTimeRange
          path="/visao-geral"
          component={Dashboard}
        />
        <LayoutRoute
          hideEstablishments
          path="/perfil"
          name="Perfil"
          component={Profile}
        />
        <LayoutRoute
          singleEstablishment
          path="/fluxo-de-caixa"
          name="Fluxo de Caixa"
          component={CashFlow}
        />
        <LayoutRoute
          showDate
          singleEstablishment
          enableSelectFutureDates
          path="/transacoes"
          name="Transações"
          component={Transactions}
        />
        <LayoutRoute
          singleEstablishment
          enableSelectFutureDates
          path="/lembretes"
          name="Lembretes"
          component={NotificationsList}
        />
        <LayoutRoute
          singleEstablishment
          path="/configuracao-de-lembrete"
          name="Configuração de Lembretes"
          component={NotificationsConfig}
        />
        <LayoutRoute
          singleEstablishment
          showDate
          name="Notas fiscais"
          path="/notas-fiscais"
          component={ProvisionalReceipt}
        />
        <LayoutRoute
          singleEstablishment
          path="/tipo-de-nota-fiscal"
          name="Seleção de tipo de nota fiscal"
          component={ProvisionalReceiptTypeSelection}
        />
        <LayoutRoute
          showDate
          path="/historico-operacional"
          name="Histórico operacional"
          component={OperationalHistory}
        />
        <LayoutRoute
          path="/registro-de-sessoes"
          name="Registro de sessões"
          component={SessionsRegistration}
        />
        <LayoutRoute
          showDate
          path="/registro-de-atividades"
          name="Registro de atividades"
          component={ActivitiesRegistration}
        />
        <LayoutRoute
          path="/historico-de-avarias"
          name="Registro de avarias"
          component={VehicleDamage}
        />
        <LayoutRoute
          singleEstablishment
          path="/fornecedores"
          name="Fornecedores"
          component={SupplierList}
        />
        <LayoutRoute
          hideEstablishments
          showEstablishmentName
          path="/novo-fornecedor"
          name="Cadastrar Fornecedor"
          component={SupplierRegistrationPage}
          previousPaths={[
            { name: 'Fornecedores', path: '/fornecedores' }
          ]}
        />
        <LayoutRoute
          hideEstablishments
          showEstablishmentName
          path="/editar-fornecedor"
          name="Editar Fornecedor"
          component={SupplierRegistrationPage}
          previousPaths={[
            { name: 'Fornecedores', path: '/fornecedores' }
          ]}
        />
        <LayoutRoute
          singleEstablishment
          path="/produtos"
          name="Produtos"
          component={Products}
        />
        <LayoutRoute
          hideEstablishments
          path="/novo-produto"
          name="Cadastrar Produto para Consumo"
          component={ProductsRegistration}
          previousPaths={[
            { name: 'Produtos', path: '/produtos' }
          ]}
        />
        <LayoutRoute
          hideEstablishments
          path="/editar-produto"
          name="Editar Produto para Consumo"
          component={ProductsRegistration}
          previousPaths={[
            { name: 'Produtos', path: '/produtos' }
          ]}
        />
        <LayoutRoute
          hideEstablishments
          path="/novo-produto-venda"
          name="Cadastrar Produto para Venda"
          component={ProductsSellRegistration}
          previousPaths={[
            { name: 'Produtos', path: '/produtos' }
          ]}
        />
        <LayoutRoute
          hideEstablishments
          path="/editar-produto-venda"
          name="Editar Produto para Venda"
          component={ProductsSellRegistration}
          previousPaths={[
            { name: 'Produtos', path: '/produtos' }
          ]}
        />
        <LayoutRoute
          hideEstablishments
          path="/novo-produto-consumo-e-venda"
          name="Cadastrar Produto para Consumo e Venda"
          component={ProductsUseAndSellRegistration}
          previousPaths={[
            { name: 'Produtos', path: '/produtos' }
          ]}
        />
        <LayoutRoute
          hideEstablishments
          path="/editar-produto-consumo-e-venda"
          name="Editar Produto para Consumo e Venda"
          component={ProductsUseAndSellRegistration}
          previousPaths={[
            { name: 'Produtos', path: '/produtos' }
          ]}
        />
        <LayoutRoute
          hideEstablishments
          path="/objetivo-do-produto"
          name="Objetivo do Produto"
          component={ProductsTypeSelection}
          previousPaths={[
            { name: 'Produtos', path: '/produtos' }
          ]}
        />
        <LayoutRoute
          singleEstablishment
          path="/categorias-de-produtos"
          name="Categorias"
          component={ProductsCategories}
          previousPaths={[
            { name: 'Produtos', path: '/produtos' }
          ]}
        />
        <LayoutRoute
          singleEstablishment
          path="/marcas-de-produtos"
          name="Marcas"
          component={ProductsBrands}
          previousPaths={[
            { name: 'Produtos', path: '/produtos' }
          ]}
        />
        <LayoutRoute
          hideEstablishments
          path="/movimentacao-de-estoque"
          name="Movimentação de Estoque"
          component={StorageHistory}
          previousPaths={[
            { name: 'Produtos', path: '/produtos' }
          ]}
        />
        <LayoutRoute
          singleEstablishment
          showDate
          path="/historico-de-venda"
          name="Ordens de Pedido"
          component={SellOrders}
        />
        <LayoutRoute
          showDate
          enableSelectFutureDates
          path="/reserva-de-vagas"
          name="Reserva de vagas"
          component={Reservations}
        />
        <LayoutRoute
          singleEstablishment
          name="Estabelecimento"
          path="/estabelecimento"
          component={BusinessInfo}
        />
        <LayoutRoute
          hideEstablishments
          name="Novo estabelecimento"
          path="/novo-estabelecimento"
          component={BusinessInfo}
        />
        <LayoutRoute
          singleEstablishment
          name="Tabelas de preço"
          path="/tabelas-de-preco"
          component={PriceTable}
        />
        <LayoutRoute
          singleEstablishment
          name="Serviços"
          path="/servicos"
          component={Services}
        />
        <LayoutRoute
          name="Usuários"
          path="/usuarios"
          component={EmployeesList}
        />
        <LayoutRoute
          hideEstablishments
          name="Cadastrar usuário"
          path="/novo-usuario"
          previousPaths={[
            { name: 'Lista de Usuários', path: '/usuarios' },
          ]}
          component={EmployeesRegistration}
        />
        <LayoutRoute
          hideEstablishments
          name="Editar usuário"
          path="/editar-usuario"
          previousPaths={[
            { name: 'Lista de usuários', path: '/usuarios' },
          ]}
          component={EmployeesRegistration}
        />
        <LayoutRoute
          singleEstablishment
          name="Funcionários"
          path="/funcionarios"
          component={ComissionedList}
        />
        <LayoutRoute
          hideEstablishments
          name="Novo funcionário"
          path="/novo-funcionario"
          previousPaths={[
            { name: 'Lista de Funcionários', path: '/funcionarios' }
          ]}
          component={ComissionedRegistration}
        />
        <LayoutRoute
          hideEstablishments
          name="Editar funcionário"
          path="/editar-funcionario"
          previousPaths={[
            { name: 'Lista de Funcionários', path: '/funcionarios' }
          ]}
          component={ComissionedRegistration}
        />
        <LayoutRoute
          singleEstablishment
          name="Comissionamento"
          path="/comissionamento"
          component={ComissionedPayrollList}
        />
        <LayoutRoute
          hideEstablishments
          showEstablishmentName
          name="Editar comissionado"
          path="/editar-comissionado"
          previousPaths={[
            { name: 'Comissionamento', path: '/comissionamento' }
          ]}
          component={ComissionedPayrollRegistration}
        />
        <LayoutRoute
          hideEstablishments
          showEstablishmentName
          name="Folhas de comissão"
          path="/folhas-de-comissao"
          previousPaths={[
            { name: 'Comissionamento', path: '/comissionamento' }
          ]}
          component={ComissionedPayrollSheet}
        />
        <LayoutRoute
          name="Clientes"
          path="/clientes"
          component={ClientList}
        />
        <LayoutRoute
          name="Área do Cliente"
          path="/cobranca/boletos"
          component={ListClients}
        />
        <LayoutRoute
          hideEstablishments
          showEstablishmentName
          name="Cadastro de cliente"
          path="/cadastro-de-cliente"
          previousPaths={[{ name: 'Lista de clientes', path: '/clientes' }]}
          component={ClientRegistration}
        />
        <LayoutRoute
          hideEstablishments
          showEstablishmentName
          name="Faturas"
          path="/faturas"
          previousPaths={[{ name: 'Lista de clientes', path: '/clientes' }]}
          component={ClientInvoices}
        />
        <LayoutRoute
          hideEstablishments
          showEstablishmentName
          name="Folhas de pagamento"
          path="/folhas-de-pagamento"
          previousPaths={[
            { name: 'Lista de Funcionários', path: '/funcionarios' }
          ]}
          component={ComissionedPayrolls}
        />
        <LayoutRoute
          singleEstablishment
          name="Comprovantes"
          path="/comprovantes"
          component={Receipt}
        />
        <LayoutRoute
          singleEstablishment
          name="Operação"
          path="/operacao"
          component={Operation}
        />
        <LayoutRoute
          singleEstablishment
          name="Caixa"
          path="/caixa"
          component={Cashier}
        />
        <LayoutRoute
          name="Contas"
          path="/contas"
          component={Accounts}
        />
        <LayoutRoute
          singleEstablishment
          name="Meios de pagamento"
          path="/meios-de-pagamento"
          component={PaymentMethods}
        />
        <LayoutRoute
          singleEstablishment
          name="Categorias"
          path="/categorias"
          component={Categories}
        />
        <LayoutRoute
          singleEstablishment
          name="Centros de Custo"
          path="/centros-de-custo"
          component={CostCenters}
        />
        <LayoutRoute
          singleEstablishment
          name="Notas fiscais"
          path="/configuracoes-de-notas-fiscais"
          previousPaths={[
            { name: 'Lista de notas fiscais', path: '/notas-fiscais' }
          ]}
          component={ProvisionalReceiptConfig}
        />
        <LayoutRoute
          singleEstablishment
          name="Notas fiscais de produto"
          path="/configuracoes-de-notas-fiscais-de-produto"
          previousPaths={[
            { name: 'Lista de notas fiscais', path: '/notas-fiscais' }
          ]}
          component={ProvisionalReceiptProductConfig}
        />
        <LayoutRoute
          singleEstablishment
          name="Configurações de reserva"
          path="/configuracoes-de-reserva"
          component={ReservationsConfig}
        />
        <LayoutRoute
          hideEstablishments
          path="*"
          name="Página não encontrada"
          component={PageNotFound}
        />
        <Redirect to="/pagina-nao-encontrada" />
      </Switch>
    </Router>
  );
};

export default Routes;