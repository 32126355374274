import { toast } from 'react-toastify';

import {
  fetchPayrolls,
  fetchSinglePayroll,
  fetchPayrollExport,
  requestClosePayroll,
  updatePayroll,
  requestPayPayroll,
  requestUndoPayPayroll,
  fetchPartialPayment,
  fetchSinglePartialPayment,
  requestPayPartialPayroll,
  requestUndoPayPartialPayroll
} from './services';

import {
  setPayrollList,
  setPayroll,
  setPartialPaymentList,
  setIsLoading,
  setIsLoadingSinglePayroll,
  clearPayroll,
  clearPayrollList
} from './actions';

export const loadPayrolls = ({ userId, establishmentId, pg, limit, employeeId, orderBy, sort }) => dispatch => {
  dispatch(setIsLoading(true));

  return fetchPayrolls({ userId, establishmentId, pg, limit, employeeId, orderBy, sort })
    .then(res => dispatch(setPayrollList(res?.data?.content)))
    .catch(() => dispatch(clearPayrollList()))
    .finally(() => dispatch(setIsLoading(false)));
}

export const loadSinglePayroll = (userId, establishmentId, payrollUuid) => dispatch => {
  dispatch(setIsLoadingSinglePayroll(true));

  return fetchSinglePayroll(userId, establishmentId, payrollUuid)
    .then(res => dispatch(setPayroll({ ...res.data, uuid: payrollUuid })))
    .catch(() => dispatch(clearPayroll()))
    .finally(() => dispatch(setIsLoadingSinglePayroll(false)));
}

export const loadPayrollExport = (userId, establishmentId, payrollUuid) => dispatch => {
  dispatch(setIsLoadingSinglePayroll(true));

  return fetchPayrollExport(userId, establishmentId, payrollUuid)
    .then(res => res)
    .finally(() => dispatch(setIsLoadingSinglePayroll(false)));
}

export const closePayroll = (userId, establishmentId, payrollUuid, closingDate) => dispatch => {
  dispatch(setIsLoadingSinglePayroll(true));

  return requestClosePayroll(userId, establishmentId, payrollUuid, closingDate)
    .then(res => {
      dispatch(setPayroll({ ...res.data }));
      toast.success('Folha de pagamento fechada com sucesso!');
    })
    .finally(() => dispatch(setIsLoadingSinglePayroll(false)));
}

export const upsertPayroll = (userId, establishmentId, payrollUuid, params) => dispatch => {
  dispatch(setIsLoadingSinglePayroll(true));

  return updatePayroll(userId, establishmentId, payrollUuid, params)
    .then(() => {
      dispatch(loadSinglePayroll(userId, establishmentId, payrollUuid));
      toast.success('Folha de pagamento atualizada com sucesso!');
    })
    .finally(() => dispatch(setIsLoadingSinglePayroll(false)));
}

export const payPayroll = (userId, establishmentId, payrollUuid, params) => dispatch => {
  dispatch(setIsLoadingSinglePayroll(true));

  return requestPayPayroll(userId, establishmentId, payrollUuid, params)
    .then(() => toast.success('Pagamento efetuado com sucesso!'))
    .finally(() => dispatch(setIsLoadingSinglePayroll(false)));
}

export const undoPayPayroll = (userId, establishmentId, payrollUuid, params) => dispatch => {
  dispatch(setIsLoadingSinglePayroll(true));

  return requestUndoPayPayroll(userId, establishmentId, payrollUuid, params)
    .then(() => {
      toast.success('Pagamento desfeito com sucesso!');
      dispatch(loadSinglePayroll(userId, establishmentId, payrollUuid));
    })
    .finally(() => dispatch(setIsLoadingSinglePayroll(false)));
}

export const loadPartialPayment = (userId, establishmentId, payrollUuid) => dispatch => {
  dispatch(setIsLoadingSinglePayroll(true));

  return fetchPartialPayment(userId, establishmentId, payrollUuid)
    .then(({ data }) => dispatch(setPartialPaymentList(data?.content)))
    .finally(() => dispatch(setIsLoadingSinglePayroll(false)));
}

export const loadSinglePartialPayment = (userId, establishmentId, payrollUuid, partialPaymentUuid) => dispatch => {
  dispatch(setIsLoadingSinglePayroll(true));

  return fetchSinglePartialPayment(userId, establishmentId, payrollUuid, partialPaymentUuid)
    .then(({ data }) => data)
    .finally(() => dispatch(setIsLoadingSinglePayroll(false)));
}

export const payPartialPayroll = (userId, establishmentId, payrollUuid, params) => dispatch => {
  dispatch(setIsLoadingSinglePayroll(true));

  return requestPayPartialPayroll(userId, establishmentId, payrollUuid, params)
    .then(() => {
      dispatch(loadPartialPayment(userId, establishmentId, payrollUuid));
      dispatch(loadSinglePayroll(userId, establishmentId, payrollUuid));
      toast.success('Pagamento efetuado com sucesso!');
    })
    .finally(() => dispatch(setIsLoadingSinglePayroll(false)));
}

export const undoPayPartialPayroll = (userId, establishmentId, payrollUuid, partialPaymentUuid, params) => dispatch => {
  dispatch(setIsLoadingSinglePayroll(true));

  return requestUndoPayPartialPayroll(userId, establishmentId, payrollUuid, partialPaymentUuid, params)
    .then(() => {
      dispatch(loadPartialPayment(userId, establishmentId, payrollUuid));
      toast.success('Pagamento parcial desfeito com sucesso!');
    })
    .finally(() => dispatch(setIsLoadingSinglePayroll(false)));
}