import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { compose, findIndex, propEq, remove } from 'ramda';
import moment from 'moment';

import { Formik, Form } from 'formik';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Badge, Collapse, FormControlLabel, Switch, Typography, TextField } from '@material-ui/core';
import ModalDrawerSwitcher from '../ModalDrawerSwitcher';
import Loader from '../Loader';
import Select from '../Select';
import Input from '../Input';
import Button from '../Button';
import ExportPDF from '../ExportPDF';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import {
  loadInvoiceServiceContractDetails,
  loadReceiptInvoicePrint,
  removeServiceContract,
  upsertInvoice,
  payInvoice,
  undoPayment,
  emailInvoice
} from '../../pages/ClientInvoices/store/thunk';

import { handlePaying, handleEmailing, handleCanceling, handleCancelingPayment } from '../../pages/ClientInvoices/store/actions';
import { convertToOptions, convertAmountToDB, convertInvoicePrint, convertDateToDB } from '../../helpers/converters';

import { validations } from './validations';

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -50,
    top: 14,
    padding: '14px 10px',
    fontSize: '15px',
    color: '#FFFFFF',
    borderRadius: '5px'
  }
}))(Badge);

const useStyles = makeStyles({
  success: { backgroundColor: '#4caf50' },
  danger: { backgroundColor: '#f44336' },
  warning: { backgroundColor: '#ff9800' },
  padding: { padding: 10 },
  textInfo: {
    margin: 0,
    color: '#f44336',
    marginTop: 10
  },
  serviceContractTitle: {
    fontSize: 18,
    fontWeight: 'bold'
  }
});

export default function InvoiceServiceContract({
  isOpenInvoiceServiceContract,
  setIsOpenInvoiceServiceContract,
  calculatedInvoiceDateTime,
  isInvoicePaid,
  pageOrigin
}) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { activePage: page, size: pageSize } = useSelector(state => state.pagination);
  const { selects, qp } = useSelector(state => state.filters);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const clientId = useSelector(state => state.clientRegistration.basicInfo.clientId);
  const { option } = useSelector(state => state.modalDrawerSwitcher);

  const isModal = option == 'modal';

  const availablePaymentMethods = convertToOptions(['paymentMethod'], selects);
  const accounts = convertToOptions(['account'], selects);
  const findCashier = findIndex(propEq('label', 'Caixa'));
  const availableAccountsFilter = compose(remove(findCashier, 1), remove(0, 1));
  const availableAccounts = availableAccountsFilter(accounts);

  const {
    isDetailing,
    isPaying,
    isEmailing,
    isCanceling,
    isCancelingPayment,
    invoiceDateTime,
    submit: {
      clientName,
      dueDateTime,
      currentAmount,
      paymentDateTime,
      paymentMethod,
      amountReceived,
      servicesContract,
      servicesUsage,
      expirationDate,
      transactionId,
      email,
      situationId,
      invoiceSituation,
      userOutputName,
      accountId,
      serviceContractId
    }
  } = useSelector(state => state.clientInvoices);

  const formikInitialValues = {
    clientName,
    dueDateTime,
    currentAmount,
    paymentDateTime,
    paymentMethod,
    amountReceived,
    servicesContract,
    servicesUsage,
    expirationDate,
    transactionId,
    accountId,
    email
  }

  useEffect(() => {
    const newInvoiceDateTime = calculatedInvoiceDateTime || invoiceDateTime;
    dispatch(loadInvoiceServiceContractDetails(userId, establishmentId, clientId, newInvoiceDateTime));
  }, []);

  const cannotUserUndoPayment = transactionId && servicesUsage.length > 0;

  const handlePrintInvoice = () => {
    const params = {
      userId,
      establishmentId,
      clientId,
      invoiceDateTime: calculatedInvoiceDateTime || invoiceDateTime
    }

    return dispatch(loadReceiptInvoicePrint(params));
  }

  const handleRemoveServiceContract = () => {
    const params = {
      qp,
      page,
      pageSize,
      startDate,
      endDate
    }

    dispatch(removeServiceContract(userId, establishmentId, serviceContractId, params, setIsOpenInvoiceServiceContract));
  }

  const togglePaymentOptions = () => {
    dispatch(handlePaying());
  }

  const toggleSendInvoice = () => {
    dispatch(handleEmailing());
  }

  const handleSendInvoice = ({ email }) => {
    dispatch(emailInvoice(userId, establishmentId, clientId, invoiceDateTime, { email }));
  }

  const toggleCancelInvoice = () => {
    dispatch(handleCanceling());
  }

  const toggleCancelPayment = () => {
    dispatch(handleCancelingPayment());
  }

  const handlePayment = (
    {
      amount,
      amountReceived,
      paymentDateTime,
      paymentMethod,
      dueDateTime,
      currentAmount
    },
    { resetForm }
  ) => {
    const currentTime = moment().format('HH:mm:ss');
    const convertDateToDB = date => `${date} 00:00:01`;

    const convertDateWithCurrentTimeToDB = date => `${moment(`${date} ${currentTime}`).format('YYYY-MM-DD HH:mm:ss')}`;

    const handleAmountToSend = amount =>
      amountReceived
        ? convertAmountToDB(amountReceived)
        : convertAmountToDB(amount);

    const params = isPaying
      ? 
        {
          amount: handleAmountToSend(handleAmountToSend(amount)),
          paymentDateTime: convertDateWithCurrentTimeToDB(paymentDateTime),
          paymentMethod
        }
      :
        {
          situationId,
          amount: convertAmountToDB(currentAmount),
          dueDateTime: convertDateToDB(dueDateTime)
        }

    const properties = {
      userId,
      establishmentId,
      clientId,
      invoiceDateTime,
      page,
      pageSize,
      startDate,
      endDate,
      pageOrigin,
      qp
    }

    if(isPaying) {
      dispatch(payInvoice(params, properties)).then(error => {
        if(!error) {
          resetForm();
        }
      });
    } else {
      dispatch(upsertInvoice(params, properties)).then(error => {
        if(!error) {
          resetForm();
        }
      });
    }
  }

  const handleCancelPayment = ({ reason, userPassword }, { resetForm }) => {
    const params = {
      situationId: 1,
      reason,
      userPassword
    }

    const properties = {
      userId,
      establishmentId,
      clientId,
      invoiceDateTime: calculatedInvoiceDateTime,
      page,
      pageSize,
      startDate,
      endDate,
      pageOrigin,
      qp
    }

    return dispatch(undoPayment(params, properties)).then(error => {
      if(!error) {
        resetForm();
      }
    });
  }

  const handleSubmit = (values, actions) => {
    if(!isEmailing && !isCanceling && !isCancelingPayment) {
      return dispatch(handlePayment(values, actions));
    }

    if(isEmailing) {
      return dispatch(handleSendInvoice(values, actions));
    }

    if(isCancelingPayment) {
      return dispatch(handleCancelPayment(values, actions));
    }
  }

  const badgeColor = () => {
    if(invoiceSituation === 'Cancelada') {
      return classes.danger;
    }

    if(invoiceSituation === 'Paga') {
      return classes.success;
    }

    return classes.warning;
  }

  const handleSaveButton = () => {
    if(isPaying) {
      return 'Pagar fatura';
    }

    return 'Salvar';
  }

  const labelStatus = () => {
    return(
      <div>
        <StyledBadge
          badgeContent={invoiceSituation}
          classes={{ badge: badgeColor() }}
        >
          Detalhamento da fatura
        </StyledBadge>
      </div>
    );
  }

  const invoicePaid =
    isInvoicePaid ||
    invoiceSituation === 'Paga' ||
    invoiceSituation === 'Cancelada';

  return(
    <>
      <ModalDrawerSwitcher
        id="accounts"
        title={labelStatus()}
        scroll="body"
        maxWidth="md"
        open={isOpenInvoiceServiceContract}
        onClose={() => setIsOpenInvoiceServiceContract(false)}
      >
        <Formik
          enableReinitialize
          initialValues={formikInitialValues}
          validationSchema={validations}
          onSubmit={handleSubmit}
        >
          {({ ...formikProps }) => (
            <Form style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              <div>
                <Loader isLoading={isDetailing}>
                  <Grid container spacing={2}>
                    <Grid item md={isModal ? 6 : 12} xs={12}>
                      <Input
                        disabled
                        id="clientName"
                        name="clientName"
                        label="Cliente"
                        value={formikProps.values.clientName}
                      />
                    </Grid>
                    <Grid item md={isModal ? 6 : 12} xs={12}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          disabled={invoicePaid}
                          inputFormat="DD/MM/YYYY"
                          label="Data do vencimento"
                          value={formikProps.values.dueDateTime}
                          onChange={date => formikProps.setFieldValue('dueDateTime', convertDateToDB(date))}
                          renderInput={params => (
                            <TextField
                              {...params}
                              fullWidth
                              name="dueDateTime"
                              variant='outlined'
                              inputProps={{
                                ...params.inputProps
                              }}
                              SelectProps={{
                                MenuProps: {
                                  disableEnforceFocus: true
                                }
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    {!!transactionId && (
                      <Grid item md={(isModal && !!transactionId) ? 6 : 12} xs={12}>
                        <Select
                          disabled
                          id="account"
                          name="account"
                          label="Selecione a conta destino"
                          value={
                            formikProps.values.account ||
                            accountId ||
                            (availableAccounts.length > 0 ? availableAccounts[0].value : '')
                          }
                          options={accounts}
                          error={formikProps.errors.account}
                          helperText={formikProps.errors.account}
                          onChange={formikProps.handleChange}
                        />
                      </Grid>
                    )}
                    <Grid item md={(isModal && !!transactionId) ? 6 : 12} xs={12}>
                      <Input
                        id="currentAmount"
                        name="currentAmount"
                        label="Valor"
                        type="money"
                        disabled={isPaying || invoicePaid}
                        value={formikProps.values.currentAmount}
                        error={formikProps.errors.currentAmount}
                        helperText={formikProps.errors.currentAmount}
                        onChange={formikProps.handleChange}
                      />
                    </Grid>
                  </Grid>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                      <Typography color="primary" className={classes.serviceContractTitle}>
                        Pacote de Serviços
                      </Typography>
                      {(!!expirationDate && expirationDate != '0001-01-01') && (
                        <p style={{ color: '#1E5168' }}>(expiração em {moment(expirationDate).format('LL')})</p>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        {+formikProps.values?.servicesContract.length === 0 && (
                          <Typography className={classes.padding}>Nenhum pacote de serviço foi encontrado</Typography>
                        )}
                        {formikProps.values?.servicesContract?.map(services => {
                          return(
                            <Grid item md={6} xs={12}>
                              <Typography>{services.count} {services.description}</Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item md={isModal ? 3 : 12} xs={12}>
                      <FormControlLabel
                        id="invoiceSituation"
                        name="invoiceSituation"
                        label="Recebido"
                        control={
                          <Switch
                            color="primary"
                            disabled={formikProps.values.currentDueDateTime !== (formikProps.values.dueDateTime || invoicePaid)}
                            onChange={togglePaymentOptions}
                            checked={formikProps.values.isPaying || invoiceSituation === 'Paga' || isInvoicePaid}
                          />
                        }
                      />
                    </Grid>
                    {situationId === 2 && (
                      <Grid item md={isModal ? 9 : 12} xs={12}>
                        <p>Recebido por {userOutputName} ás {moment(formikProps.values.paymentDateTime).format('DD/MM/YYYY HH:mm')}</p>
                      </Grid>
                    )}
                  </Grid>
                  <Collapse in={isPaying || invoiceSituation === 'Paga' || isInvoicePaid}>
                    <Grid container spacing={2}>
                      <Grid item md={isModal ? 3 : 12} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            disabled={invoicePaid}
                            inputFormat="DD/MM/YYYY"
                            label="Data do recebimento"
                            value={
                              moment(formikProps.values.paymentDateTime).format('DD/MM/YYYY') != '01/01/0001' &&
                              moment(paymentDateTime).format('DD/MM/YYYY') != '01/01/0001'
                                ? formikProps.values.paymentDateTime
                                : moment().format('YYYY-MM-DD')
                            }
                            onChange={date => formikProps.setFieldValue('paymentDateTime', convertDateToDB(date))}
                            renderInput={params => (
                              <TextField
                                {...params}
                                fullWidth
                                name="dueDateTime"
                                variant='outlined'
                                inputProps={{
                                  ...params.inputProps
                                }}
                                SelectProps={{
                                  MenuProps: {
                                    disableEnforceFocus: true
                                  }
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item md={isModal ? 6 : 12} xs={12}>
                        <Select
                          id="paymentMethod"
                          name="paymentMethod"
                          label="Selecione o meio de pagamento"
                          disabled={invoicePaid}
                          options={availablePaymentMethods}
                          error={isCanceling ? null : formikProps.errors.paymentMethod}
                          helperText={formikProps.errors.paymentMethod}
                          value={formikProps.values.paymentMethod || moment.now()}
                          onChange={formikProps.handleChange}
                        />
                      </Grid>
                      <Grid item md={isModal ? 3 : 12} xs={12}>
                        <Input
                          id="amountReceived"
                          name="amountReceived"
                          type="money"
                          label="Valor recebido"
                          placeholder="R$ 0,00"
                          disabled={invoicePaid}
                          value={formikProps.values.amountReceived}
                          error={formikProps.errors.amountReceived}
                          helperText={formikProps.errors.amountReceived}
                          onChange={formikProps.handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                  <Collapse in={isEmailing}>
                    <Grid container spacing={2}>
                      <Grid xs={12}>
                        <Input
                          id="email"
                          name="email"
                          label="E-mail"
                          value={formikProps.values.email}
                          error={formikProps.errors.email}
                          helperText={formikProps.errors.email}
                          onChange={formikProps.handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                  <Collapse in={isCanceling}>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12}>
                        <Input
                          id="reason"
                          name="reason"
                          label={situationId === 3 ? 'Motivo da restauração' : 'Motivo do cancelamento'}
                          value={formikProps.values.reason}
                          error={formikProps.errors.reason}
                          helperText={formikProps.errors.reason}
                          onChange={formikProps.handleChange}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Input
                          id="userPassword"
                          type="password"
                          label="Confirme sua senha"
                          error={formikProps.errors.userPassword}
                          helperText={formikProps.errors.userPassword}
                          onChange={formikProps.handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </Loader>
              </div>
              <div style={{ display: 'flex', justifyContent: isModal ? 'flex-end' : 'flex-start', gap: 10, flexWrap: 'wrap', marginTop: isModal ? 10 : 0 }}>
                <Button
                  type="button"
                  color="error"
                  variant="outlined"
                  startIcon={
                    <FontAwesomeIcon
                      icon={faTrash}
                      size='xs'
                      color='#E53935'
                    />
                  }
                  loading={isDetailing}
                  onClick={handleRemoveServiceContract}
                >
                  Remover Pacote de Serviço
                </Button>
                {isEmailing && (
                  <>
                    <Button
                      color="error"
                      variant="outlined"
                      loading={isDetailing}
                      onClick={toggleSendInvoice}
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      loading={isDetailing}
                    >
                      Enviar fatura
                    </Button>
                  </>
                )}
                {isCanceling && (
                  <>
                    <Button
                      color="error"
                      variant="outlined"
                      loading={isDetailing}
                      onClick={toggleCancelInvoice}
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      className="mr-2"
                      loading={isDetailing}
                    >
                      Confirmar
                    </Button>
                  </>
                )}
                {isCancelingPayment && (
                  <>
                    <Button
                      color="error"
                      variant="outlined"
                      loading={isDetailing}
                      onClick={toggleCancelPayment}
                    >
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      className="mr-2"
                      loading={isDetailing}
                    >
                      Confirmar
                    </Button>
                  </>
                )}
                {(!isEmailing && !isCanceling && !isCancelingPayment) && (
                  <>
                    {situationId !== 2 && (
                      <Button
                        color={situationId === 3 ? 'primary' : 'error'}
                        variant={situationId === 3 ? 'contained' : 'outlined'}
                        loading={isDetailing}
                        onClick={toggleCancelInvoice}
                      >
                        {situationId === 3 ? 'Restaurar' : 'Cancelar'} fatura
                      </Button>
                    )}
                    <Button
                      color="tertiary"
                      variant="outlined"
                      disabled={!situationId}
                      loading={isDetailing}
                      onClick={toggleSendInvoice}
                    >
                      Enviar fatura
                    </Button>
                    <ExportPDF
                      type="button"
                      exportType="receiptInvoice"
                      color="tertiary"
                      variant="outlined"
                      fileName="fatura"
                      disabled={!situationId}
                      loading={isDetailing}
                      formatComponentData={convertInvoicePrint}
                      requestData={handlePrintInvoice}
                    >
                      Baixar fatura
                    </ExportPDF>
                    <Button
                      type={invoiceSituation === 'Paga' ? 'button' : 'submit'}
                      color="primary"
                      loading={isDetailing}
                      disabled={(invoiceSituation === 'Cancelada') || (invoiceSituation === 'Paga' && cannotUserUndoPayment) || (servicesUsage.length > 0)}
                    >
                      {handleSaveButton()}
                    </Button>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </ModalDrawerSwitcher>
    </>
  );
}

InvoiceServiceContract.propTypes = {
  pageOrigin: PropTypes.string,
  isOpenInvoiceServiceContract: PropTypes.bool.isRequired,
  setIsOpenInvoiceServiceContract: PropTypes.func.isRequired,
  calculatedInvoiceDateTime: PropTypes.string,
  isInvoicePaid: PropTypes.bool
};

InvoiceServiceContract.defaultProps = {
  pageOrigin: 'invoices'
};