import {
  compose,
  over,
  lensProp,
  findIndex,
  prop,
  propEq,
  remove,
  concat,
  filter,
  without,
  prepend,
  map,
  assoc,
  values,
  flip,
  includes,
  forEachObjIndexed,
  when,
  has,
  replace,
  ifElse,
  equals,
  always,
  not,
  props,
  mapObjIndexed,
  set,
  omit
} from 'ramda';

import {
  convertToDB,
  convertToReal,
  convertToMinutes,
  convertMinutesToPeriod
} from './converters';

import moment from 'moment';
import VMasker from 'vanilla-masker';
import parse from 'html-react-parser';
import { renameProp, limitNameSize } from './common';

export const formatServicesCategories = (categories, servicesCategories) => {
  const formattedCategories = categories
    ?.filter(category => !!category)
    ?.map(category => +category);

  const categoriesLabel = servicesCategories
    ?.filter(serviceCategoryId => formattedCategories?.includes(+serviceCategoryId?.value))
    ?.map(serviceCategoryId => serviceCategoryId?.label)
    ?.join(', ');

  return categoriesLabel || '-';
}

export const capitalizeString = string => {
  const uppercase = string?.toLowerCase()?.charAt(0)?.toUpperCase() || '';
  const lowercase = string?.toLowerCase()?.slice(1) || '';

  return `${uppercase}${lowercase}`;
}

export const formatVehicle = vehicle => {
  if(!vehicle?.vehicleModel) {
    return '';
  }

  if(vehicle?.vehicleModel && !vehicle?.vehicleColor) {
    return vehicle?.vehicleModel;
  }

  return `${vehicle?.vehicleModel} ${vehicle?.vehicleColor}`;
}

export const formatItems = ({ items, time, currency }) =>
  items.map(item =>
    compose(
      over(lensProp('since'), time),
      over(lensProp('period'), time),
      over(lensProp('price'), currency)
    )(item)
  );

// Remove empty periods from timeRanges and specificTimeRanges.
// Concat and format them to DB.
export const formatTimeRanges = (timeRanges, specificTimeRanges) => {
  const formatter = ranges => {
    let result = map(
      range => omit(['typePrice', 'establishmentId'], range),
      ranges
    );
    const findEmptyPeriod = period => findIndex(propEq('period', ''), period);
    const removeEmptyPeriod = (index, arr) => remove(index, 1, arr);

    if (findEmptyPeriod(ranges) >= 0) {
      const index = findEmptyPeriod(ranges);
      result = removeEmptyPeriod(index, ranges);
    }

    return result;
  };

  const formatedTimeRanges = formatter(timeRanges);
  const formatedSpecificTimeRanges = formatter(specificTimeRanges);

  const items = concat(formatedTimeRanges, formatedSpecificTimeRanges);
  return formatItems({ items, time: convertToMinutes, currency: convertToDB });
};

export const updateItems = props => {
  const initialState = { since: '', period: '', price: '' };
  const defaultState = [initialState];
  const formatter = range => {
    return formatItems({
      items: range,
      time: convertMinutesToPeriod,
      currency: convertToReal,
    });
  };
  const items = prop('items', props);
  let timeRanges = filter(item => item.since === 0, items);
  let specificTimeRanges = without(timeRanges, items);

  timeRanges =
    timeRanges.length > 0
      ? prepend(initialState, formatter(timeRanges))
      : defaultState;
  specificTimeRanges =
    specificTimeRanges.length > 0
      ? prepend(initialState, formatter(specificTimeRanges))
      : defaultState;

  return { timeRanges, specificTimeRanges };
};

export const permanence = data =>
  moment
    .duration(moment(data[0]) - moment(data[1]))
    .format('D[d] HH[h] mm[min]');

export const formatDate = date =>
  date && date !== '0001-01-01 00:00:00' ? moment(date).format('L') : '--';

export const formatDateOnly = dateTime =>
  dateTime && dateTime !== '0001-01-01 00:00:00'
    ? moment(dateTime).format('DD/MM/YYYY')
    : '--';

export const formatTimeOnly = dateTime =>
  dateTime && dateTime !== '0001-01-01 00:00:00'
    ? moment(dateTime).format('HH:mm:ss')
    : '--';

export const formatDateMonthName = date => {
  if (!date) {
    return null;
  }
  const month = parseInt(date.slice(4), 10);
  const formatMonth = month - 1;
  const monthName = moment()
    .month(formatMonth)
    .format('MMMM');

  return monthName.replace(/^./, monthName[0].toUpperCase());
};

export const formatPermanence = data => {
  const entryDateTime = data[1];
  const exitDateTime = data[0];

  if (exitDateTime === '0001-01-01 00:00:00') {
    return '--';
  }

  const permanenceDuration = moment.duration(
    moment(exitDateTime) - moment(entryDateTime)
  );

  const { years, months, days, hours, minutes } = permanenceDuration._data;
  let formatPermanenceType = 'D[d] HH[h] mm[min]';

  if (!years && !months && !days && !hours && !minutes) {
    formatPermanenceType = 'ss[seg]';
  }

  return permanenceDuration.format(formatPermanenceType);
};

export const formatPermanenceInMinutes = period => {
  if (!period) {
    return '--';
  }

  const permanenceDuration = moment.duration(period, 'seconds');

  return permanenceDuration.format('DD:HH:mm:ss');
};

export const formatEmptyValue = value => value || '--';
export const formatEmptyArray = arr => (arr.length > 0 ? arr : '--');

export const formatFilter = (property, filters) =>
  compose(
    prop('options'),
    over(
      lensProp('options'),
      compose(
        map(
          compose(
            renameProp(`${property}Name`, 'label'),
            renameProp(`${property}Id`, 'value')
          )
        )
      )
    ),
    prop(property)
  )(filters);

export const formatOptions = data => {
  const formatter = options => {
    const insertSelect = compose(assoc('type', 'select'), assoc('value', ''));

    const handleOptions = option => {
      let result = prepend({ label: '--', value: '' }, option);

      if (result.length > 1) {
        result = map(
          compose(
            renameProp(`${prop('id', options)}Name`, 'label'),
            renameProp(`${prop('id', options)}Id`, 'value')
          ),
          result
        );
      }

      return result;
    };

    const formatOptions = over(
      lensProp('options'),
      handleOptions,
      insertSelect(options)
    );

    return formatOptions;
  };

  return map(formatter, values(data));
};

export const formatFilters = (filters, selects) =>
  filter(compose(flip(includes)(filters), prop('id')), selects);

export const formatQuery = (search, filtrate) => {
  let qp = '';

  if (filtrate) {
    const formatFilters = compose(
      when(
        has('clientDocument'),
        over(lensProp('clientDocument'), replace(/\D/g, ''))
      ),
      when(
        has('status'),
        over(lensProp('status'), status => (status === 2 ? 0 : status))
      )
    );

    forEachObjIndexed((value, key) => {
      if (key === 'accounts[]') {
        value.map(account => (qp += `&${key}=${account}`));
      } else {
        qp += `&${key}=${value}`;
      }
    }, formatFilters(filtrate));
  }

  if (search) {
    qp += `&search=${search}`;
  }

  return qp;
};

export const formatEstablishments = (establishments, isAdminProfile) => {
  const transformToOption = establishment => {
    const { establishmentId, establishmentName, resaleId } = establishment;
    const labelEstablishment = isAdminProfile ? `${establishmentId} ` : '';

    const associateLabelAndValue = compose(
      assoc('label', isAdminProfile ? `${!!resaleId ? `[${resaleId}]` : ''} ${labelEstablishment}${establishmentName}` : `${labelEstablishment}${establishmentName}`),
      assoc('value', establishmentId)
    );

    return associateLabelAndValue(establishment);
  }

  const availableEstablishments = map(
    transformToOption,
    filter(establishment => establishment !== null, establishments)
  );

  const formatLabel = establishment => {
    const { addresses, label } = establishment;
    let { city, district, street, number } = {};

    if(addresses) {
      city = addresses[0]?.city;
      district = addresses[0]?.district;
      street = addresses[0]?.street;
      number = addresses[0]?.number;
    }

    const address = number
      ? `${street}, ${number} - ${district}, ${city}`
      : `${street} - ${district}, ${city}`;

    if(street) {
      return set(
        lensProp('label'),
        `${limitNameSize(label, 35)} \n${limitNameSize(address, 60)}`,
        establishment
      );
    }

    return establishment;
  }

  const formattedEstablishments = map(formatLabel, availableEstablishments);

  return formattedEstablishments.length > 0 ? formattedEstablishments : [];
}

export const formatAvailableEstablishments = establishments => {
  const formattedEstablishments = formatEstablishments(establishments);
  return filter(e => e.value, formattedEstablishments);
};

export const formatEstablishmentsToUuidAssoc = (establishments, isAdminProfile) => {
  const transformToOption = establishment => {
    const { establishmentId, establishmentName, uuid } = establishment;
    const labelEstablishment = isAdminProfile ? `${establishmentId} ` : '';

    const associateLabelAndValue = compose(
      assoc('label', `${labelEstablishment}${establishmentName}`),
      assoc('value', uuid)
    );

    return associateLabelAndValue(establishment);
  }

  const availableEstablishments = map(
    transformToOption,
    filter(establishment => establishment !== null, establishments)
  );

  const formatLabel = establishment => {
    const { addresses, label } = establishment;
    const { city, district, street, number } = addresses[0];
    const address = `${street}, ${number} - ${district}, ${city}`;

    if(street) {
      return set(
        lensProp('label'),
        `${limitNameSize(label, 28)}\n${limitNameSize(address, 48)}`,
        establishment
      );
    }

    return establishment;
  }

  const formattedEstablishments = map(formatLabel, availableEstablishments);

  return formattedEstablishments.length > 0 ? formattedEstablishments : [];
}

export const formatAvailableEstablishmentsToUuid = establishments => {
  const formattedEstablishments = formatEstablishmentsToUuidAssoc(establishments);
  return filter(e => e.value, formattedEstablishments);
}

export const formatDateTime = dateTime => {
  const formatedDateTime = ifElse(
    time => not(equals(time, '0001-01-01 00:00:00')),
    time => moment(time).format('DD/MM/YYYY HH:mm:ss'),
    always('--')
  );

  return formatedDateTime(dateTime);
};

export const formatServiceOrders = data => {
  const observationsSelect = observations => {
    try {
      let observationsString = '';
      if (observations) {
        observationsString += observations.observation
          ? `Observação do aplicativo: ${observations.observation}; `
          : '';
        observationsString += observations.editObservation
          ? `Observação da edição: ${observations.editObservation}; `
          : '';
        observationsString += observations.cancelObservation
          ? `Observação do cancelamento: ${observations.cancelObservation}; `
          : '';
        observationsString += observations.deleteObservation
          ? `Observação da remoção: ${observations.deleteObservation}; `
          : '';
        observationsString += observations.changePriceObservation
          ? `Observação da troca de valor: ${observations.changePriceObservation}; `
          : '';
        return observationsString;
      }
    } catch (err) {
      console.log(err);
    }
  }

  const formatter = order => {
    return compose(
      over(lensProp('paymentMethodName'), formatEmptyValue),
      over(lensProp('amountTotal'), convertToReal),
      over(lensProp('permanenceToExport'), formatPermanence),
      over(lensProp('permanence'), formatPermanence),
      assoc('entryDate', formatDateOnly(order.entryDateTime)),
      assoc('entryTime', formatTimeOnly(order.entryDateTime)),
      assoc('exitDate', formatDateOnly(order.exitDateTime)),
      assoc('exitTime', formatTimeOnly(order.exitDateTime)),
      assoc('financialSituationName', order.financialSituationName),
      assoc(
        'permanenceToExport',
        props(['exitDateTime', 'entryDateTime'], order)
      ),
      assoc('permanence', props(['exitDateTime', 'entryDateTime'], order)),
      assoc('observation', observationsSelect(order.observations))
    )(order);
  }

  return map(formatter, data);
}

const calculateBalance = ({ previousElement, currentElement }) => {
  const result = +(
    new Number(previousElement) + new Number(currentElement)
  ).toFixed(2);
  return result;
};

const filterToSum = value => {
  let result = true;

  if (value.situationId === 1 || value.situationId === 2) {
    if (
      value.previsionDateTime &&
      value.previsionDateTime !== '0001-01-01 00:00:00' &&
      value.previsionDateTime !== '' &&
      moment(value.previsionDateTime).isBefore(moment())
    ) {
      result = false;
    } else if (
      value.transactionDateTime &&
      value.transactionDateTime !== '0001-01-01 00:00:00' &&
      value.transactionDateTime !== '' &&
      moment(value.transactionDateTime).isBefore(moment())
    ) {
      result = false;
    }
  }

  if (value.categorySourceId === 7 && value.situationId === 1) {
    result = false;
  }

  return result;
};

export const formatTransaction = (data, isExport) => {
  const { previousBalance, content } = data;
  let newBalance;

  const preparer = (value, index) => {
    newBalance = calculateBalance({
      previousElement: index === '0' ? previousBalance : newBalance,
      currentElement: filterToSum(value) && value.amount,
    });

    return compose(
      over(lensProp('transactionDateTime'), formatDateTime),
      assoc('category', prop('categoryId', value)),
      set(lensProp('description'), parse(value.description)),
      set(
        lensProp('amount'),
        isExport ? parseFloat(value.amount)?.toString()?.replace('.', ',') : value.amount
      ),
      set(lensProp('balance'), convertToReal(newBalance))
    )(value, index);
  };
  const result = mapObjIndexed(preparer, content);

  const transactions = Object.keys(result).map(key => result[key]);

  return transactions;
};

export const formatSessionsRegistration = (data, establishments) => {
  const formatter = session => {
    const { establishmentId } = session;
    const filterEstablishment =
      establishments &&
      establishments.filter(e => e.establishmentId === establishmentId);
    const establishmentName =
      filterEstablishment.length > 0
        ? filterEstablishment[0].establishmentName
        : '--';
    const formattedSessions = compose(
      assoc('establishmentName', establishmentName),
      over(lensProp('startDateTime'), formatDateTime),
      over(
        lensProp('endDateTime'),
        ifElse(
          equals('0001-01-01 00:00:00'),
          () => 'Usuário Ativo',
          formatDateTime
        )
      )
    );

    return formattedSessions(session);
  };

  return map(formatter, data);
};

export const formatPhone = value => {
  if (value) {
    let phonePattern = '(99) 9999-9999';
    const phone = value?.toString()?.replace(/[^0-9.]+/g, '');
    if (phone.length > 10) {
      phonePattern = '(99) 99999-9999';
    }
    return VMasker.toPattern(value || '', phonePattern);
  }

  return '';
};

export const formatterClients = data => {
  const formatter = client => {
    const { clientInvoiceSituationId, dueDateTime, clientTypeId } = client;

    const returnSituationId = () => {
      if (clientTypeId === 1) {
        return '';
      }

      return moment(dueDateTime).isBefore(moment(), 'day') &&
        clientInvoiceSituationId === 1
        ? 2
        : 1;
    }

    const formatClient = compose(
      assoc('clientInvoiceSituation', returnSituationId()),
      over(lensProp('dueDateTime'), formatDate),
      over(lensProp('invoiceAmount'), convertToReal),
      over(lensProp('email'), formatEmptyValue)
    );

    return formatClient(client);
  }

  return map(formatter, data);
}

export const formatPostalCode = value => {
  if (value) {
    return VMasker.toPattern(value, '99999-999');
  }

  return '';
};

export const formatDocument = value => {
  if (value) {
    let documentPattern = '999.999.999-99';
    const document = value.replace(/[^0-9.]+/g, '').replace(/\./g, '');

    if (document.length > 11) {
      documentPattern = '99.999.999/9999-99';
    }
    return VMasker.toPattern(value, documentPattern);
  }

  return '';
};

export const formatToOnlyNumbers = value =>
  value && value.replace(/[^0-9]/g, '');

export const formatDaysBetweenDates = (startDate, endDate) => {
  const dates = [];

  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }

  return map(date => moment(date).format('DD/MM'), dates);
};

export const formatToBusinessAccounts = (accounts, cashierId) => {
  if (cashierId) {
    return accounts;
  }

  return accounts.filter(acc => acc.accountTypeId !== 1);
};

export const formatWhatsAppMessageLineBreak = message => {
  return message.replaceAll('\n', '%0a');
}

export const formatToNumbersOnly = value => {
  const isValueNull =
    value == null || value == undefined || value == '';

  if(isValueNull) {
    return;
  }

  const onlyNumbersPattern = /\d+/g;
  return value.toString().match(onlyNumbersPattern).join('');
}