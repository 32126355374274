import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';

import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import MaterialIcon from 'material-icons-react';

import Card from '../../../components/Card';
import Loader from '../../../components/Loader';
import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { clearBusinessInfo, setEditingState, setEstablishmentId } from '../store/actions';
import { loadProvisionalReceiptInfo } from '../../ProvisionalReceiptConfig/store/thunk';

import {
  loadBusinessInfo,
  loadAddress,
  upsertEstablishment,
  changeEstablishmentPermission,
  setDebitWarning,
  setActivePremiumPackage,
  setDisablePremiumPackage
} from '../store/thunk';

import { handleMultipleSelection } from '../../../helpers/common';
import { useScreenMeasure } from '../../../helpers/hooks/useScreenMeasure';
import useWindowDimensions from '../../../helpers/hooks/useWindowDimensions';
import { RESTRICTION_PAGE } from '../../../helpers/restrictions';

import { validations } from './validations';
import { businessTypes } from './resources';

import { useStyles } from './styles';

export default function BusinessInfo({ formikProps }) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const [isMobile] = useScreenMeasure();
  const { width } = useWindowDimensions();
  const isSm = width < 768;

  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();

  const registerPath = path.split('/').includes('novo-estabelecimento');
  const businessPath = path.split('/').includes('cadastro-inicial');
  const configurationPath = path.split('/').includes('configuracoes-de-notas-fiscais');

  const isEstablishmentEdit = location.pathname == '/estabelecimento';
  const isEstablishmentRegistration = path.split('/').includes('cadastro-inicial') && path.split('/').includes('estabelecimento');

  const { userId, userRestrictions, submit: { profileId } } = useSelector(state => state.profile);
  const { establishmentId, submit, isLoading, isEditing, isPostalCodeLoading } = useSelector(state => state.businessInfo);
  const establishmentCreatedByUserId = useSelector(state => state.businessInfo.submit.userId);

  const initialValues = { userId, establishmentId, ...submit };

  const isUserAdmin = +profileId == 1;
  const premiumPackage = +submit?.premiumPackage;

  const canUserSeeEstablishmentTypeSelection =
    profileId == 1 || (!configurationPath && !isEstablishmentEdit);

  useEffect(() => {
    if(establishmentId && !registerPath) {
      const params = {
        userId,
        establishmentId
      }

      dispatch(loadBusinessInfo(params));
    }
  }, [establishmentId]);

  useEffect(() => {
    if(
      userRestrictions?.includes(RESTRICTION_PAGE.VIEW_ESTABLISHMENT) &&
      userRestrictions?.includes(RESTRICTION_PAGE.CREATE_ESTABLISHMENT) &&
      userRestrictions?.includes(RESTRICTION_PAGE.EDIT_ESTABLISHMENT)
    ) {
      toast.error('Ação não autorizada.');
      history.push('/visao-geral');
    }

    if(registerPath) {
      dispatch(clearBusinessInfo());
      dispatch(setEstablishmentId({ establishmentId: '' }));
    }

    if(configurationPath) {
      dispatch(loadProvisionalReceiptInfo(userId, establishmentId));
    }
  }, [userRestrictions, registerPath, configurationPath]);

  useEffect(() => {
    const newEstablishmentPath = path.split('/').includes('novo-estabelecimento');

    if(newEstablishmentPath && !isEditing) {
      dispatch(setEditingState(true));
      dispatch(setEstablishmentId({ establishmentId: '' }));
      dispatch(clearBusinessInfo());
    }
  }, [isEditing]);

  const handleEstablishmentTypes = (e, formikNewProps) => {
    const { values } = formikNewProps;
    const { checked, value } = e.target;
    const type = parseInt(value, 10);
    const establishmentTypes = handleMultipleSelection(checked, type, values.establishmentTypes);

    formikNewProps.setFieldValue('establishmentTypes', establishmentTypes);
    formikProps.setFieldValue('establishmentTypes', establishmentTypes);
  }

  const handlePostalCode = (postalCode, setFieldValue) => {
    if(postalCode?.length === 9) {
      return dispatch(loadAddress(postalCode)).then(data => {
        const { logradouro, bairro, localidade, uf } = data;

        setFieldValue('street', logradouro);
        setFieldValue('district', bairro);
        setFieldValue('city', localidade);
        setFieldValue('state', uf);

        formikProps.setFieldValue('street', logradouro);
        formikProps.setFieldValue('district', bairro);
        formikProps.setFieldValue('city', localidade);
        formikProps.setFieldValue('state', uf);
      });
    }
  }

  const handleEstablishmentPermission = e => {
    const { value } = e.currentTarget;
    const appButton = value === '0' ? 1 : 0;
    const param = { appPermission: appButton }
    dispatch(changeEstablishmentPermission(param, userId, establishmentId, true));
  }

  const handleEstablishmentDebit = e => {
    const { value } = e.currentTarget;
    const appButton = value === '0' ? 1 : 0;
    const params = { debitWarning: appButton }

    dispatch(setDebitWarning(userId, establishmentId, params));
  }

  const handleBusinessInfoSave = values => {
    const isStoreOnly = values?.establishmentTypes?.length == 1 && +values?.establishmentTypes[0] == 3;

    values.userId = userId;

    if(isStoreOnly) {
      values.vacanciesMarks = 0;
    }

    return dispatch(upsertEstablishment(values, true)).then(
      async establishmentData => {
        const { establishmentTypes, establishmentId, isUpdated } = establishmentData;

        if(isUpdated) {
          return dispatch(setEditingState(false));
        }

        await dispatch(setEstablishmentId({ establishmentId }));

        if(registerPath) {
          await history.push('/visao-geral');
          return;
        }

        if(establishmentTypes.includes(1) || establishmentTypes.includes('1')) {
          await history.push('/tabelas-de-preco');
        } else {
          await history.push('/servicos');
        }

        await dispatch(setEditingState(false));
      }
    );
  }

  return (
    <Card title="Preencha as informações básicas do negócio">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validations}
        onSubmit={handleBusinessInfoSave}
      >
        {({ ...formikNewProps }) => {
          const {
            setFieldValue,
            handleChange,
            errors,
            values: {
              debitWarning,
              appPermission,
              establishmentTypes,
              establishmentName,
              companyName,
              documentId,
              phone,
              email,
              postalCode,
              street,
              number,
              complement,
              district,
              city,
              state,
              vacanciesMarks
            }
          } = formikNewProps;

          const isStoreOnly = establishmentTypes?.length == 1 && +establishmentTypes[0] == 3;

          return(
            <Grid item xs={12}>
              <Form>
                <Loader isLoading={isLoading}>
                  {(businessPath || registerPath) && (
                    <Grid>
                      {!isEstablishmentRegistration && (
                        <Grid container className={classes.infoCard}>
                          <Typography className={classes.infoCardText}>Atenção:</Typography>
                          <Typography className={classes.infoCardText}>
                            Ao adicionar um novo estabelecimento está sujeito a cobrança, segundo as regras <a href="https://jumppark.com.br/planos-e-precos/" target="_blank">neste link</a>.
                          </Typography>
                          <Typography className={classes.infoCardText}>
                            Qualquer dúvida, entre em contato com a Geovanna do financeiro pelo chat.
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  )}
                  {canUserSeeEstablishmentTypeSelection && (
                    <Grid>
                      <Typography
                        style={{ fontSize: 12 }}
                        paragraph
                        variant="subtitle3"
                        color="primary"
                      >
                        Selecione o(s) serviço(s) que o seu negócio oferece:
                      </Typography>
                      <FormGroup column>
                        <Grid container spacing={2}>
                          {businessTypes.map(business => (
                            <Grid item md={12 / businessTypes.length} xs={12}>
                              <FormControlLabel
                                labelPlacement="start"
                                className={
                                  errors.establishmentTypes ||
                                  formikProps.errors.establishmentTypes
                                    ? [classes.checkbox, classes.checkboxError]
                                    : classes.checkbox
                                }
                                label={
                                  <Grid
                                    container
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{ height: '90px' }}
                                  >
                                    <MaterialIcon
                                      icon={business.icon}
                                      size={36}
                                      color="#022A5C"
                                    />
                                    <Typography
                                      color="primary"
                                      style={{
                                        textAlign: 'center',
                                        marginTop: '10px',
                                        fontSize: isMobile ? '14px' : 'inherit'
                                      }}
                                    >
                                      {business.label}
                                    </Typography>
                                  </Grid>
                                }
                                control={
                                  <Checkbox
                                    type="checkbox"
                                    value={business.id}
                                    checked={establishmentTypes?.includes(business.id)}
                                    onChange={e => handleEstablishmentTypes(e, formikNewProps)}
                                  />
                                }
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </FormGroup>
                      <FormHelperText error>
                        {errors.establishmentTypes || formikProps.errors.establishmentTypes}
                      </FormHelperText>
                    </Grid>
                  )}
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <Input
                        autoFocus
                        name="establishmentName"
                        label="Nome do estabelecimento"
                        value={
                          establishmentName ||
                          formikProps.values.establishmentName
                        }
                        error={
                          isEstablishmentRegistration
                            ? formikProps.errors.establishmentName
                            : errors.establishmentName
                        }
                        helperText={
                          isEstablishmentRegistration
                            ? formikProps.errors.establishmentName
                            : errors.establishmentName
                        }
                        onChange={e => {
                          handleChange(e);
                          formikProps.handleChange(e);
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Input
                        name="documentId"
                        type="document"
                        label="CNPJ (Opcional)"
                        placeholder="00.000.000/0000-00"
                        value={documentId || formikProps.values.documentId}
                        error={errors.documentId || formikProps.errors.documentId}
                        helperText={errors.documentId || formikProps.errors.documentId}
                        onChange={e => {
                          handleChange(e);
                          formikProps.handleChange(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <Input
                        noAutoComplete
                        name="phone"
                        type="phone"
                        label="Telefone"
                        placeholder="(00) 00000-0000"
                        value={phone || formikProps.values.phone}
                        error={errors.phone || formikProps.errors.phone}
                        helperText={errors.phone || formikProps.errors.phone}
                        onChange={e => {
                          handleChange(e);
                          formikProps.handleChange(e);
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Input
                        name="email"
                        type="email"
                        label="Email"
                        placeholder="usuario@exemplo.com.br"
                        value={email || formikProps.values.email}
                        error={errors.email || formikProps.errors.email}
                        helperText={errors.email || formikProps.errors.email}
                        onChange={e => {
                          handleChange(e);
                          formikProps.handleChange(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                  {configurationPath && (
                    <Grid item xs={12}>
                      <Input
                        name="companyName"
                        label="Razão social"
                        value={companyName || formikProps.values.companyName}
                        error={errors.companyName || formikProps.errors.companyName}
                        helperText={errors.companyName || formikProps.errors.companyName}
                        onChange={e => {
                          handleChange(e);
                          formikProps.handleChange(e);
                        }}
                      />
                    </Grid>
                  )}
                  <Grid container spacing={2}>
                    <Grid item lg={2} sm={6} xs={12}>
                      {isMobile && (
                        <Typography variant="subtitle2">
                          Local onde o serviço é prestado
                        </Typography>
                      )}
                      <Input
                        name="postalCode"
                        type="postalCode"
                        label="CEP"
                        placeholder="00000-000"
                        value={postalCode || formikProps.values.postalCode}
                        error={errors.postalCode || formikProps.errors.postalCode}
                        onChange={e => {
                          handleChange(e);
                          formikProps.handleChange(e);
                          handlePostalCode(e.target.value, setFieldValue);
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Typography variant="subtitle2" />
                      <Input
                        name="street"
                        type="text"
                        label="Endereço"
                        placeholder="Endereço"
                        loading={isPostalCodeLoading}
                        value={street || formikProps.values.street || ''}
                        error={
                          isEstablishmentRegistration
                            ? formikProps.errors.street
                            : errors.street
                        }
                        helperText={
                          isEstablishmentRegistration
                            ? formikProps.errors.street
                            : errors.street
                        }
                        onChange={e => {
                          handleChange(e);
                          formikProps.handleChange(e);
                        }}
                      />
                    </Grid>
                    <Grid item lg={2} sm={6} xs={12}>
                      <Input
                        name="number"
                        type="number"
                        label="Número"
                        placeholder="0"
                        loading={isPostalCodeLoading}
                        value={number || formikProps.values.number || ''}
                        error={
                          isEstablishmentRegistration
                            ? formikProps.errors.number
                            : errors.number
                        }
                        helperText={
                          isEstablishmentRegistration
                            ? formikProps.errors.number
                            : errors.number
                        }
                        onChange={e => {
                          handleChange(e);
                          formikProps.handleChange(e);
                        }}
                      />
                    </Grid>
                    <Grid item lg={2} sm={6} xs={12}>
                      <Input
                        name="complement"
                        type="text"
                        label="Complemento"
                        placeholder="Ex.: 3˚ andar"
                        value={complement || formikProps.values.complement || ''}
                        error={errors.complement}
                        helperText={errors.complement}
                        loading={isPostalCodeLoading}
                        onChange={e => {
                          handleChange(e);
                          formikProps.handleChange(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item sm={5} xs={12}>
                      <Input
                        name="district"
                        type="text"
                        label="Bairro"
                        placeholder="Bairro"
                        loading={isPostalCodeLoading}
                        value={district || formikProps.values.district || ''}
                        error={
                          isEstablishmentRegistration
                            ? formikProps.errors.district
                            : errors.district
                        }
                        helperText={
                          isEstablishmentRegistration
                            ? formikProps.errors.district
                            : errors.district
                        }
                        onChange={e => {
                          handleChange(e);
                          formikProps.handleChange(e);
                        }}
                      />
                    </Grid>
                    <Grid item sm={5} xs={12}>
                      <Input
                        name="city"
                        type="text"
                        label="Cidade"
                        placeholder="Cidade"
                        loading={isPostalCodeLoading}
                        value={city || formikProps.values.city || ''}
                        error={
                          isEstablishmentRegistration
                            ? formikProps.errors.city
                            : errors.city
                        }
                        helperText={
                          isEstablishmentRegistration
                            ? formikProps.errors.city
                            : errors.city
                        }
                        onChange={e => {
                          handleChange(e);
                          formikProps.handleChange(e);
                        }}
                      />
                    </Grid>
                    <Grid item sm={2} xs={12}>
                      <Input
                        name="state"
                        type="text"
                        label="UF"
                        placeholder="UF"
                        loading={isPostalCodeLoading}
                        value={state || formikProps.values.state || ''}
                        error={
                          isEstablishmentRegistration
                            ? formikProps.errors.state
                            : errors.state
                        }
                        helperText={
                          isEstablishmentRegistration
                            ? formikProps.errors.state
                            : errors.state
                          }
                        onChange={e => {
                          handleChange(e);
                          formikProps.handleChange(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                  {!isStoreOnly && (
                    <>
                      {!configurationPath && (
                        <Grid container spacing={2}>
                          <Grid item xs={(!!userId && isUserAdmin && isEstablishmentEdit) ? 6 : 12}>
                            <Input
                              name="vacanciesMarks"
                              type="number"
                              label="Total de ocupação do pátio"
                              placeholder="0"
                              value={vacanciesMarks || formikProps.values.vacanciesMarks}
                              error={
                                isEstablishmentRegistration
                                  ? formikProps.errors.vacanciesMarks
                                  : errors.vacanciesMarks
                              }
                              helperText={
                                isEstablishmentRegistration
                                  ? formikProps.errors.vacanciesMarks
                                  : errors.vacanciesMarks
                              }
                              onChange={e => {
                                handleChange(e);
                                formikProps.handleChange(e);
                              }}
                            />
                          </Grid>
                          {(!!userId && isUserAdmin && isEstablishmentEdit) && (
                            <Grid item xs={6}>
                              <Input
                                disabled
                                name="userId"
                                type="number"
                                label="Estabelecimento criado por"
                                value={establishmentCreatedByUserId}
                              />
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </>
                  )}
                </Loader>
                {registerPath || (!businessPath && !configurationPath) ? (
                  <div style={{ display: 'flex', flexDirection: isSm ? 'column' : 'row', flexFlow: isSm ? 'no-wrap' : 'wrap', justifyContent: 'flex-end', gap: '10px', marginTop: '10px' }}>
                    {profileId === 1 && establishmentId ? (
                      <>
                        {(isUserAdmin && isEstablishmentEdit) && (
                          <>
                            {(+premiumPackage == 0) && (
                              <Button
                                type="button"
                                color="success"
                                loading={isLoading}
                                onClick={() => dispatch(setActivePremiumPackage(userId, establishmentId))}
                              >
                                Ativar Pacote Premium
                              </Button>
                            )}
                            {(+premiumPackage == 1) && (
                              <Button
                                type="button"
                                color="error"
                                loading={isLoading}
                                onClick={() => dispatch(setDisablePremiumPackage(userId, establishmentId))}
                              >
                                Desativar Pacote Premium
                              </Button>
                            )}
                          </>
                        )}
                        <Button
                          type="button"
                          color={debitWarning === 1 ? '#FF6600' : 'success'}
                          loading={isLoading}
                          onClick={handleEstablishmentDebit}
                          value={debitWarning}
                        >
                          Lembrete de débito
                        </Button>
                        <Button
                          type="button"
                          color={appPermission === 1 ? 'error' : 'success'}
                          loading={isLoading}
                          onClick={handleEstablishmentPermission}
                          value={appPermission}
                        >
                          {appPermission === 1
                            ? 'Desativar estabelecimento'
                            : 'Ativar estabelecimento'
                          }
                        </Button>
                      </>
                    ) : null}
                    {(!userRestrictions?.includes(RESTRICTION_PAGE.CREATE_ESTABLISHMENT) || !userRestrictions?.includes(RESTRICTION_PAGE.EDIT_ESTABLISHMENT)) && (
                      <Button color="success" loading={isLoading}>
                        {registerPath || isEditing ? 'Adicionar' : 'Salvar'}
                      </Button>
                    )}
                  </div>
                ) : null}
              </Form>
            </Grid>
          );
        }}
      </Formik>
    </Card>
  );
}

BusinessInfo.defaultProps = {
  formikProps: {
    handleChange: () => {},
    setFieldValue: () => {},
    values: {
      establishmentName: '',
      companyName: '',
      documentId: '',
      phone: '',
      email: '',
      postalCode: '',
      street: '',
      number: 0,
      complement: '',
      district: '',
      city: '',
      state: '',
      vacanciesMarks: ''
    },
    errors: {
      companyName: false,
      documentId: false,
      phone: false,
      email: false,
      postalCode: false,
      street: false,
      number: false,
      complement: false,
      district: false,
      city: false,
      state: false,
      vacanciesMarks: false
    }
  }
}

BusinessInfo.propTypes = {
  formikProps: PropTypes.shape()
}