import {
  fetchComissionConfigList,
  fetchComissionConfig,
  createComissionConfig,
  updateComissionConfig
} from './services';

import {
  setComissionsConfig,
  clearComissionsConfig,
  setComissionsConfigLoading,
  setComissionsErrors,
  clearComissionsErrors
} from './actions';

import { toast } from 'react-toastify';

export const loadComissionConfigList = ({ userId, establishmentId, serviceId }) => dispatch => {
  dispatch(setComissionsConfigLoading(true));

  return fetchComissionConfigList({ userId, establishmentId, serviceId })
    .then(res => {
      if(res.data?.content?.length > 0) {
        return dispatch(setComissionsConfig(res.data?.content[res.data?.content?.length - 1]));
      }

      toast.warn('Serviço não possui configuração de comissionamento.');

      dispatch(clearComissionsConfig());
    })
    .catch(() => toast.error('Houve um problema ao carregar a configuração do comissionamento.'))
    .finally(() => dispatch(setComissionsConfigLoading(false)));
}

export const loadComissionConfig = (userId, uuid) => dispatch => {
  dispatch(setComissionsConfigLoading(true));

  return fetchComissionConfig(userId, uuid)
    .then(res => dispatch(setComissionsConfig(res.data)))
    .catch(() => toast.error('Houve um problema ao carregar a configuração do comissionamento.'))
    .finally(() => dispatch(setComissionsConfigLoading(false)));
}

export const upsertComissionConfig = (userId, establishmentId, params) => dispatch => {
  dispatch(setComissionsConfigLoading(true));

  if(!params.uuid) {
    return createComissionConfig(userId, establishmentId, params)
      .then(res => {
        dispatch(clearComissionsErrors());
        dispatch(setComissionsConfig(res.data));
        toast.success('Configuração do comissionamento cadastrada com sucesso.');
        return Promise.resolve();
      })
      .catch(errors => {
        toast.error('Houve um problema ao cadastrar a configuração do comissionamento.');
        dispatch(setComissionsErrors(errors));
        return Promise.reject();
      })
      .finally(() => dispatch(setComissionsConfigLoading(false)));
  }

  return updateComissionConfig(userId, establishmentId, params)
    .then(res => {
      dispatch(clearComissionsErrors());
      dispatch(setComissionsConfig(res.data));
      toast.success('Configuração do comissionamento atualizada com sucesso.');
      return Promise.resolve();
    })
    .catch(errors => {
      toast.error('Houve um problema ao atualizar a configuração do comissionamento.');
      dispatch(setComissionsErrors(errors));
      return Promise.reject();
    })
    .finally(() => dispatch(setComissionsConfigLoading(false)));
}