import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Form } from 'formik';
import { Grid, Typography } from '@material-ui/core';

import Modal from 'components/Modal';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Button from 'components/Button';

import { upsertProductBrand } from 'pages/ProductsBrands/store/thunk';

import { validations } from './validations';

export default function ProductsBrandsRegistration({ open, onClose }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { submit, isSingleProductBrandLoading } = useSelector(state => state.productsBrands);

  const handleSubmit = values => {
    const params = {
      ...values
    }

    delete params['id'];

    dispatch(upsertProductBrand(userId, establishmentId, values?.id, params))
      .then(() => onClose());
  }

  const initialValues = {
    id: '',
    name: '',
    description: ''
  }

  return(
    <Modal
      id='products-brands-registration-modal'
      title={submit?.id ? 'Editar marca' : 'Nova marca'}
      maxWidth='sm'
      open={open}
      onClose={onClose}
    >
      <Formik
        enableReinitialize
        initialValues={submit?.id ? submit : initialValues}
        onSubmit={handleSubmit}
        validationSchema={validations}
      >
        {({ ...formikProps }) => {
          return(
            <Form>
              <Loader isLoading={isSingleProductBrandLoading}>
                <Grid container>
                  <Grid xs={12} style={{ borderBottom: '1px solid #E4E7EA', marginBottom: 10 }}>
                    <Typography color='primary' variant='subtitle2'>Nova categoria</Typography>
                  </Grid>
                  <Grid xs={12}>
                    <Input
                      id='name'
                      name='name'
                      type='text'
                      label='Nome'
                      value={formikProps.values.name}
                      error={formikProps.errors.name}
                      helperText={formikProps.errors.name}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <Input
                      id='description'
                      name='description'
                      type='text'
                      label='Descrição'
                      value={formikProps.values.description}
                      error={formikProps.errors.description}
                      helperText={formikProps.errors.description}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  <Grid xs={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
                    <Button
                      type='button'
                      color='error'
                      onClick={onClose}
                    >
                      Fechar
                    </Button>
                    <Button
                      type='submit'
                      color='success'
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Loader>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}