import {
  SET_PRODUCT_CATEGORIES,
  SET_PRODUCT_CATEGORIES_IS_LOADING,
  SET_SINGLE_PRODUCT_CATEGORY_IS_LOADING,
  SET_SINGLE_PRODUCT_CATEGORY,
  CLEAR_SINGLE_PRODUCT_CATEGORY
} from './constants';

const defaultState = {
  isSingleProductCategoryLoading: false,
  list: [],
  total: 0,
  isLoading: false,
  submit: {
    id: null,
    parentProductTypeId: '',
    description: ''
  }
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_PRODUCT_CATEGORIES: {
      return {
        ...state,
        list: action.data,
        total: action.data?.length
      }
    }
    case SET_PRODUCT_CATEGORIES_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
    case SET_SINGLE_PRODUCT_CATEGORY_IS_LOADING: {
      return {
        ...state,
        isSingleProductCategoryLoading: action.isLoading
      }
    }
    case SET_SINGLE_PRODUCT_CATEGORY: {
      const {
        id,
        parentProductTypeId,
        description
      } = action.data;

      return {
        ...state,
        submit: {
          id,
          parentProductTypeId,
          description
        }
      }
    }
    case CLEAR_SINGLE_PRODUCT_CATEGORY: {
      return {
        ...state,
        submit: defaultState.submit
      }
    }
    default: {
      return state;
    }
  }
}