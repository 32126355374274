import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Grid, Typography, FormControlLabel, Switch, Checkbox } from '@material-ui/core';
import { Formik, Form } from 'formik';
import Card from 'components/Card';
import Loader from 'components/Loader';
import WarningMessage from 'components/WarningMessage';
import CardFooter from 'components/CardFooter';
import Button from 'components/Button';

import { loadConfigurations, upsertConfigurations } from '../../store/thunk';

import { checkedValueByType } from 'helpers/common';

export default function Operation() {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { isLoading } = useSelector(state => state.operational);
  const { settingHistoryUserName, settingHistoryDateTime } = useSelector(state => state.operational);
  const operation = useSelector(state => state.operational.operation);
  const vehicleEntry = useSelector(state => state.operational.vehicleEntry);

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadConfigurations(userId, establishmentId));
    }
  }, [establishmentId]);

  const handleSubmitOperation = values => {
    const params = {
      serviceOnly: checkedValueByType(values?.serviceOnly, 'number'),
      manualCovenant: checkedValueByType(values?.manualCovenant, 'number'),
      manualTime: checkedValueByType(values?.manualTime, 'number'),
      prePaidExit: checkedValueByType(values?.prePaidExit, 'number'),
      recurrentClientEntryOption: checkedValueByType(values?.recurrentClientEntryOption, 'number'),
      requireReceiptCovantTypePrice: checkedValueByType(values?.requireReceiptCovantTypePrice, 'number'),
      requireReceiptExpense: checkedValueByType(values?.requireReceiptExpense, 'number'),
      page: 'Operação'
    }

    dispatch(upsertConfigurations(userId, establishmentId, params));
  }

  const handleSubmitVehicleEntry = values => {
    const params = {
      requiredPlateOrPhoto: checkedValueByType(values?.requiredPlateOrPhoto, 'number'),
      requiredVehiclePlate: (+values?.requiredPlateOrPhoto == 1) ? '0' : checkedValueByType(values?.requiredVehiclePlate, 'number'),
      requiredVehicleModel: checkedValueByType(values?.requiredVehicleModel, 'number'),
      requiredVehicleColor: checkedValueByType(values?.requiredVehicleColor, 'number'),
      requiredVehicleDamage: checkedValueByType(values?.requiredVehicleDamage, 'number'),
      requiredEntryPhoto: (+values?.requiredPlateOrPhoto == 1) ? '0' : checkedValueByType(values?.requiredEntryPhoto, 'number'),
      requiredClientRegisterInfo: checkedValueByType(values?.requiredClientRegisterInfo, 'number'),
      page: 'Entrada de Veículo'
    }

    dispatch(upsertConfigurations(userId, establishmentId, params));
  }

  const operationInitialValues = {
    serviceOnly: checkedValueByType(operation?.serviceOnly, 'boolean'),
    manualCovenant: checkedValueByType(operation?.manualCovenant, 'boolean'),
    manualTime: checkedValueByType(operation?.manualTime, 'boolean'),
    prePaidExit: checkedValueByType(operation?.prePaidExit, 'boolean'),
    recurrentClientEntryOption: checkedValueByType(operation?.recurrentClientEntryOption, 'boolean'),
    requireReceiptCovantTypePrice: checkedValueByType(operation?.requireReceiptCovantTypePrice, 'boolean'),
    requireReceiptExpense: checkedValueByType(operation?.requireReceiptExpense, 'boolean')
  }

  const vehicleEntryInitialValues = {
    requiredPlateOrPhoto: checkedValueByType(vehicleEntry?.requiredPlateOrPhoto, 'boolean'),
    requiredVehiclePlate: checkedValueByType(vehicleEntry?.requiredVehiclePlate, 'boolean'),
    requiredVehicleModel: checkedValueByType(vehicleEntry?.requiredVehicleModel, 'boolean'),
    requiredVehicleColor: checkedValueByType(vehicleEntry?.requiredVehicleColor, 'boolean'),
    requiredVehicleDamage: checkedValueByType(vehicleEntry?.requiredVehicleDamage, 'boolean'),
    requiredEntryPhoto: checkedValueByType(vehicleEntry?.requiredEntryPhoto, 'boolean'),
    requiredClientRegisterInfo: checkedValueByType(operation?.requiredClientRegisterInfo, 'boolean')
  }

  return(
    <>
      <Card title="Operação">
        <Grid xs={12} alignItems='center'>
          <Loader isLoading={isLoading}>
            <Formik
              enableReinitialize
              initialValues={operationInitialValues}
              onSubmit={handleSubmitOperation}
            >
              {({ ...formikProps }) => {
                return(
                  <Form>
                    <Grid container>
                      {(!!settingHistoryUserName && !!settingHistoryDateTime) && (
                        <WarningMessage
                          message={`Última alteração feita por ${settingHistoryUserName} em ${moment(settingHistoryDateTime).format('LLL')}`}
                        />
                      )}
                      <Grid item xs={12} style={{ marginTop: 10 }}>
                        <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                          <b>Permitir somente serviços</b>
                        </Typography>
                        <Typography color="textSecondary" style={{ margin: '10px 0 10px 0' }}>
                          Permite para quando selecionar um serviço, automaticamente o estacionamento não será cobrado
                        </Typography>
                        <FormControlLabel
                          control={
                            <Switch
                              name="serviceOnly"
                              color="primary"
                              value={formikProps?.values?.serviceOnly || ''}
                              checked={formikProps?.values?.serviceOnly || ''}
                              onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                            />
                          }
                          label={
                            <Typography color="textSecondary">Permitir</Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                          <b>Permitir convênio manual</b>
                        </Typography>
                        <Typography color="textSecondary" style={{ margin: '10px 0 10px 0' }}>
                          Permite que o operador manualmente indique qual é o convênio que o motorista está utilizando
                        </Typography>
                        <FormControlLabel
                          control={
                            <Switch
                              name="manualCovenant"
                              color="primary"
                              value={formikProps?.values?.manualCovenant || ''}
                              checked={formikProps?.values?.manualCovenant || ''}
                              onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                            />
                          }
                          label={
                            <Typography color="textSecondary">Permitir</Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                          <b>Permitir horário manual</b>
                        </Typography>
                        <Typography color="textSecondary" style={{ margin: '10px 0 10px 0' }}>
                          Permite que o operador manualmente indique qual é o horário corrente do aparelho (celular/tablet)
                        </Typography>
                        <FormControlLabel
                          control={
                            <Switch
                              name="manualTime"
                              color="primary"
                              value={formikProps?.values?.manualTime || ''}
                              checked={formikProps?.values?.manualTime || ''}
                              onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                            />
                          }
                          label={
                            <Typography color="textSecondary">Permitir</Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                          <b>Permitir a entrada sem cartão e impressão para clientes recorrentes</b>
                        </Typography>
                        <Typography color="textSecondary" style={{ margin: '10px 0 10px 0' }}>
                          Opção de não obrigar a entrega do cartão quando o cliente for mensalista. O sistema reconhece pela placa e não exige o cartão para a entrada
                        </Typography>
                        <FormControlLabel
                          control={
                            <Switch
                              name="recurrentClientEntryOption"
                              color="primary"
                              value={formikProps?.values?.recurrentClientEntryOption || ''}
                              checked={formikProps?.values?.recurrentClientEntryOption || ''}
                              onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                            />
                          }
                          label={
                            <Typography color="textSecondary">Permitir</Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                          <b>Forçar pagamento antecipado com saída do veículo</b>
                        </Typography>
                        <Typography color="textSecondary" style={{ margin: '10px 0 10px 0' }}>
                          Forçar que ao dar entrada no veículo o operador dê entrada com pagamento antecipado e que o veículo saia automaticamente após a entrada
                        </Typography>
                        <FormControlLabel
                          control={
                            <Switch
                              name="prePaidExit"
                              color="primary"
                              value={formikProps?.values?.prePaidExit || ''}
                              checked={formikProps?.values?.prePaidExit || ''}
                              onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                            />
                          }
                          label={
                            <Typography color="textSecondary">Forçar</Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                          <b>Obrigar comprovante ao selecionar uma tabela convênio</b>
                        </Typography>
                        <FormControlLabel
                          control={
                            <Switch
                              name="requireReceiptCovantTypePrice"
                              color="primary"
                              value={formikProps?.values?.requireReceiptCovantTypePrice || ''}
                              checked={formikProps?.values?.requireReceiptCovantTypePrice || ''}
                              onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                            />
                          }
                          label={
                            <Typography color="textSecondary">Obrigar</Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                          <b>Obrigar comprovante ao fazer um registro de despesa no aplicativo operacional</b>
                        </Typography>
                        <FormControlLabel
                          control={
                            <Switch
                              name="requireReceiptExpense"
                              color="primary"
                              value={formikProps?.values?.requireReceiptExpense || ''}
                              checked={formikProps?.values?.requireReceiptExpense || ''}
                              onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                            />
                          }
                          label={
                            <Typography color="textSecondary">Obrigar</Typography>
                          }
                        />
                      </Grid>
                      <Grid xs={12}>
                        <CardFooter>
                          <Button type="submit" color="primary">
                            Salvar
                          </Button>
                        </CardFooter>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Loader>
        </Grid>
      </Card>
      {/* <Card title="Entrada de Veículo" style={{ marginTop: 20 }}>
        <Grid xs={12} alignItems='center'>
          <Loader isLoading={isLoading}>
            <Formik
              enableReinitialize
              initialValues={vehicleEntryInitialValues}
              onSubmit={handleSubmitVehicleEntry}
            >
              {({ ...formikProps }) => {
                return(
                  <Form>
                    <Grid container>
                      {(!!settingHistoryUserName && !!settingHistoryDateTime) && (
                        <WarningMessage
                          message={`Última alteração feita por ${settingHistoryUserName} em ${moment(settingHistoryDateTime).format('LLL')}`}
                        />
                      )}
                      <Grid item xs={12} style={{ marginTop: 10 }}>
                        <Typography color="primary" style={{ borderBottom: '1px solid #E4E7EA' }}>
                          <b>Obrigatoriedades para dar entrada em um veículo</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ margin: '20px 0 20px 0' }}>
                        <Typography color="textSecondary">Escolha as opções que deseja obrigar o preenchimento para permitir dar entrada em um veículo</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="requiredPlateOrPhoto"
                              type="checkbox"
                              value={formikProps?.values?.requiredPlateOrPhoto || ''}
                              checked={formikProps?.values?.requiredPlateOrPhoto || ''}
                              onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                            />
                          }
                          label={
                            <Typography color="primary">Digitação ou foto da placa do veículo</Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="requiredVehiclePlate"
                              type="checkbox"
                              disabled={+formikProps?.values?.requiredPlateOrPhoto == 1}
                              value={formikProps?.values?.requiredVehiclePlate || ''}
                              checked={
                                +formikProps?.values?.requiredPlateOrPhoto == 1
                                  ? ''
                                  : formikProps?.values?.requiredVehiclePlate || ''
                              }
                              onChange={event => {
                                formikProps.setFieldValue(event.target.name, event.target.checked);

                                if(!event.target.checked) {
                                  return(
                                    formikProps.setFieldValue('requiredVehicleModel', 0),
                                    formikProps.setFieldValue('requiredVehicleColor', 0)
                                  );
                                }
                              }}
                            />
                          }
                          label={
                            <Typography color="primary">Digitação da <b>placa</b> do veículo</Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="requiredEntryPhoto"
                              type="checkbox"
                              disabled={+formikProps?.values?.requiredPlateOrPhoto == 1}
                              value={formikProps?.values?.requiredEntryPhoto || ''}
                              checked={
                                +formikProps?.values?.requiredPlateOrPhoto == 1
                                  ? ''
                                  : formikProps?.values?.requiredEntryPhoto || ''
                              }
                              onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                            />
                          }
                          label={
                            <Typography color="primary">Registro de <b>foto da placa</b> do veículo</Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="requiredVehicleModel"
                              type="checkbox"
                              value={formikProps?.values?.requiredVehicleModel || ''}
                              checked={formikProps?.values?.requiredVehicleModel || ''}
                              onChange={event => {
                                formikProps.setFieldValue(event.target.name, event.target.checked);

                                if(event.target.checked) {
                                  return formikProps.setFieldValue('requiredVehiclePlate', 1);
                                }
                              }}
                            />
                          }
                          label={
                            <Typography color="primary">Digitação do <b>modelo</b> do veículo</Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="requiredVehicleColor"
                              type="checkbox"
                              value={formikProps?.values?.requiredVehicleColor || ''}
                              checked={formikProps?.values?.requiredVehicleColor || ''}
                              onChange={event => {
                                formikProps.setFieldValue(event.target.name, event.target.checked);

                                if(event.target.checked) {
                                  return formikProps.setFieldValue('requiredVehiclePlate', 1);
                                }
                              }}
                            />
                          }
                          label={
                            <Typography color="primary">Digitação da <b>cor</b> do veículo</Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="requiredVehicleDamage"
                              type="checkbox"
                              value={formikProps?.values?.requiredVehicleDamage || ''}
                              checked={formikProps?.values?.requiredVehicleDamage || ''}
                              onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                            />
                          }
                          label={
                            <Typography color="primary">Registro de <b>avaria</b> do veículo (Checklist)</Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="requiredClientRegisterInfo"
                              type="checkbox"
                              value={formikProps?.values?.requiredClientRegisterInfo || ''}
                              checked={formikProps?.values?.requiredClientRegisterInfo || ''}
                              onChange={event => formikProps.setFieldValue(event.target.name, event.target.checked)}
                            />
                          }
                          label={
                            <Typography color="primary">
                              Obrigar cadastro de cliente com <b>nome e telefone</b>
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid xs={12}>
                        <CardFooter>
                          <Button color="primary" type="submit">
                            Salvar
                          </Button>
                        </CardFooter>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Loader>
        </Grid>
      </Card> */}
    </>
  );
}