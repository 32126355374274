import { newAPI } from '../../../services';

export const fetchServiceOrders = async url => {
  const response = await newAPI.get(url);
  return response.data;
}

export const fetchResumeOrders = async url => {
  const response = await newAPI.get(url);
  return response.data;
}

export const fetchExport = async (userId, establishmentId, extraProps) => {
  const { startDate, endDate, page, pageSize, qp } = extraProps;

  const url = establishmentId
    ? `${userId}/establishment/${establishmentId}/serviceorders/export/json?startDate=${startDate}&endDate=${endDate}&pg=${page}&limit=${pageSize}${qp}`
    : `${userId}/serviceorders/export/json?startDate=${startDate}&endDate=${endDate}&pg=${page}&limit=${pageSize}${qp}`;

  const response = await newAPI.get(url);
  return response.data;
}

export const fetchPaidServiceOrders = async (userId, establishmentId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/serviceorders/paid`);
  return response.data;
}