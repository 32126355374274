import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Menu, MenuItem, IconButton, Typography } from '@material-ui/core';
import { AspectRatio } from '@material-ui/icons';

import { setOption } from '../ModalDrawerSwitcher/store/actions';

const IconDrawer = require('../../assets/img/icon_change_drawer.svg');
const IconModal = require('../../assets/img/icon_change_modal.svg');

export default function ModalDrawerChangeView() {
  const dispatch = useDispatch();

  const [element, setElement] = useState(null);
  const isOpen = !!element;

  const handleOpen = event => {
    if(event) {
      setElement(event.currentTarget);
    }
  }

  const handleClose = () => {
    setElement(null);
  }

  return(
    <>
      <IconButton onClick={handleOpen}>
        <AspectRatio />
      </IconButton>
      <Menu
        anchorEl={element}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        getContentAnchorEl={null}
        open={isOpen}
        onClose={handleClose}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MenuItem
            onClick={() => {
              dispatch(setOption('modal'));
              handleClose();
            }}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10, padding: 10, backgroundColor: 'transparent' }}
          >
            <img src={IconModal} />
            <Typography>Padrão</Typography>
          </MenuItem>
          <div style={{ width: 1, height: 60, backgroundColor: '#AEAEAE' }} />
          <MenuItem
            onClick={() => {
              dispatch(setOption('drawer'));
              handleClose();
            }}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10, padding: 10, backgroundColor: 'transparent' }}
          >
            <img src={IconDrawer} />
            <Typography>Barra lateral</Typography>
          </MenuItem>
        </div>
      </Menu>
    </>
  );
}