import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { path } from 'ramda';
import moment from 'moment';
import VMasker from 'vanilla-masker';

import { Grid, Typography, Card, CardHeader, CardContent, Collapse } from '@material-ui/core';
import Button from 'components/Button';
import Loader from 'components/Loader';
import MTable from 'components/MTable';
import Payroll from 'components/Payroll';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPen} from '@fortawesome/free-solid-svg-icons';

import { setEmployeeUuid, clearPayroll } from '../store/actions';
import { loadPayrolls, loadSinglePayroll, loadPartialPayment } from '../store/thunk';
import { loadFilters } from 'components/Filter/store/thunk';

import { convertToReal } from 'helpers/converters';

const headers = [
  { title: 'Data do vencimento', field: 'dueDate', sortable: false },
  { title: 'Data do pagamento', field: 'paymentDateTime', sortable: false },
  { title: 'Situação', field: 'situationId', sortable: false },
  { title: 'Valor', field: 'amount', sortable: false }
];

export default function ComissionedPayrolls() {
  const dispatch = useDispatch();

  const history = useHistory();

  const { name, uuid } = history?.location?.state?.data;

  const [isPayrollModalOpen, setIsPayrollModalOpen] = useState(false);
  const [showInvoices, setShowInvoices] = useState([0]);

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { list, isLoading } = useSelector(state => state.comissionedPayrolls);
  const { payrollSituations } = useSelector(state => state.comissionedList.filters);

  useEffect(() => {
    const params = {
      userId,
      establishmentId,
      pg: 1,
      limit: '',
      employeeId: uuid,
      orderBy: 'dueDate',
      sort: 'asc'
    }

    if(!establishmentId) {
      return;
    }

    dispatch(loadFilters(userId, establishmentId));

    dispatch(loadPayrolls(params))
      .finally(() => dispatch(setEmployeeUuid(uuid)));
  }, [establishmentId]);

  const getPayrollAmount = payroll => {
    const { situationId, amount, paidAmount } = payroll || {};

    if(+situationId == 2) {
      return !!paidAmount ? `R$ ${VMasker.toMoney(paidAmount || '')}` : '';
    }

    return !!amount ? `R$ ${VMasker.toMoney(amount || '')}` : '';
  }

  const handleInvoicesToggle = i => {
    if (showInvoices.includes(i)) {
      setShowInvoices(showInvoices.filter(id => id !== i));
    } else {
      setShowInvoices(showInvoices.concat(i));
    }

    return { showInvoices }
  }

  const handleEditPayroll = async payroll => {
    dispatch(clearPayroll());

    const { uuid } = payroll;

    setIsPayrollModalOpen(true);
    dispatch(loadSinglePayroll(userId, establishmentId, uuid));
    dispatch(loadPartialPayment(userId, establishmentId, uuid));
  }

  return(
    <Grid
      container
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <Loader isLoading={isLoading}>
        {isPayrollModalOpen && (
          <Payroll
            isPayrollModalOpen={isPayrollModalOpen}
            setIsPayrollModalOpen={setIsPayrollModalOpen}
            pageOrigin='/folhas-de-pagamento'
          />
        )}
        {(list?.length > 0) && (
          <>
            <Grid item xs={12}>
              <Typography paragraph variant='h5' color='primary'>
                Folhas de pagamento do funcionário: {name}
              </Typography>
            </Grid>
            {list?.map((payroll, index) => {
              const currentYear = path([0, 'dueDate'], payroll)?.split('-')[0];
              const currentInvoice = showInvoices.includes(index);

              return(
                <Card>
                  <CardHeader
                    style={{ backgroundColor: '#022A5C' }}
                    title={
                      <Button
                        fullWidth
                        disableElevation
                        color='inherit'
                        style={{ textDecoration: 'none', color: 'white' }}
                        onClick={() => handleInvoicesToggle(index)}
                      >
                        <Typography variant='h6'>
                          {currentYear}
                        </Typography>
                        <FontAwesomeIcon
                          style={{ marginLeft: 10 }}
                          icon={faChevronDown}
                          color='white'
                        />
                      </Button>
                    }
                  />
                  <Collapse
                    in={currentInvoice}
                    key={index}
                  >
                    <CardContent>
                      <MTable
                        headers={headers}
                        data={payroll.map(payrollData => {
                          return {
                            ...payrollData,
                            dueDate: payrollData?.dueDate ? moment(payrollData?.dueDate).format('DD/MM/YYYY') : '-',
                            paymentDateTime: payrollData?.paymentDateTime ? moment(payrollData?.paymentDateTime).format('DD/MM/YYYY') : '-',
                            situationId: +payrollData?.partialPayment > 0
                              ? `${payrollSituations?.options?.find(situation => +situation?.id == +payrollData?.situationId)?.name} / Pagamento parcial`
                              : payrollSituations?.options?.find(situation => +situation?.id == +payrollData?.situationId)?.name,
                            amount: getPayrollAmount(payrollData)
                          }
                        })}
                        actions={[
                          {
                            icon: () => (
                              <FontAwesomeIcon
                                icon={faPen}
                                color='#022A5C'
                                size='xs'
                              />
                            ),
                            tooltip: 'Editar folha de pagamento',
                            onClick: (_, rowData) => handleEditPayroll(rowData)
                          }
                        ]}
                      />
                    </CardContent>
                  </Collapse>
                </Card>
              );
            })}
          </>
        )}
        {(list?.length == 0) && (
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography paragraph variant='h6' color='textSecondary'>
              Não há folhas de pagamento encontradas para esse cliente
            </Typography>
          </Grid>
        )}
      </Loader>
    </Grid>
  );
}