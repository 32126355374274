import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Grid, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Card from 'components/Card';
import Loader from 'components/Loader';
import Select from 'components/Select';
import NumericInput from 'components/NumericInput';
import Button from 'components/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

import { setIsConsumptionModified } from '../../../store/actions';
import { loadServiceProduct, deleteServiceProduct, editServiceProduct } from '../../../store/thunk';
import { loadServicesCategories } from 'pages/Services/store/thunk';
import { loadFilters } from 'components/Filter/store/thunk';

import { formatServicesCategories } from 'helpers/formatters';

import { useAutoCompleteStyles } from './styles';

export default function Consumption({ formikProps }) {
  const dispatch = useDispatch();

  const history = useHistory();

  const isEdit = history.location.pathname == '/editar-produto-consumo-e-venda';

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const autoCompleteStyles = useAutoCompleteStyles();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { selects } = useSelector(state => state.filters);
  const { consumptionResponseErrors, measurementUnitGroupId, isLoading } = useSelector(state => state.productsUseAndSellRegistration);
  const { measurementUnits } = useSelector(state => state.products.filters);
  const { storageProductUuid } = useSelector(state => state.productsUseAndSellRegistration.basicAndStorageResponse);
  const { servicesCategories } = useSelector(state => state.services);

  const formattedMeasurementUnits = measurementUnits
    ?.filter(unit => [measurementUnitGroupId, null]?.includes(unit?.groupId))
    ?.map(unit => {
      return {
        value: unit?.id,
        label: `${unit?.description} (${unit?.abbreviation})`
      }
    });

  const services = selects
    ?.find(filter => filter?.id == 'services')
    ?.options
    ?.map(service => {
      return {
        value: service?.serviceId,
        label: service?.description,
        categories: service?.categories?.filter(category => !!category)
      }
    })
    ?.filter(service => !!service?.value);

  const { values, errors, setFieldValue } = formikProps;

  useEffect(() => {
    dispatch(loadFilters(userId, establishmentId));
    dispatch(loadServicesCategories(userId));
  }, []);

  useEffect(() => {
    if(isEdit && !!storageProductUuid) {
      dispatch(loadServiceProduct(userId, establishmentId, storageProductUuid));
    }
  }, [history.location.pathname]);

  const updateConsumption = (field, value, index) => {
    let consumptionArray = values?.consumptionByService;
    const currentItem = values?.consumptionByService[index];

    const updatedCurrentItem = {
      ...currentItem,
      [field]: value
    }

    consumptionArray[index] = updatedCurrentItem;

    if(currentItem?.id) {
      dispatch(setIsConsumptionModified(true));
    }

    return setFieldValue('consumptionByService', consumptionArray);
  }

  const handleRemoveConsumptionService = consumption => {
    const { id, storageProductUuid } = consumption;

    if(!id) {
      return;
    }

    dispatch(deleteServiceProduct(userId, establishmentId, { id, storageProductUuid }));
  }

  const handleEditConsumptionService = consumption => {
    const {
      storageProductUuid,
      storageProductName,
      measurementUnitId,
      measurementUnitName,
      productTypeId,
      productTypeDescription,
      netContent,
      serviceId,
      toBeUsedContent
    } = consumption;

    const params = {
      storageProductUuid,
      storageProductName,
      measurementUnitId,
      measurementUnitName,
      productTypeId,
      productTypeDescription,
      netContent,
      serviceId,
      toBeUsedContent
    }

    dispatch(editServiceProduct(userId, establishmentId, consumption?.id, params));
  }

  return(
    <Card title="Parametrize o consumo por serviço" style={{ marginBottom: 20 }}>
      <Grid container>
        <Grid xs={12}>
          <Loader isLoading={isLoading}>
            {values?.consumptionByService?.map((consumption, index) => {
              return(
                <Grid container spacing={2} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: isMobile ? 50 : 0 }}>
                  <Grid item md={4} xs={12}>
                    <Autocomplete
                      id="serviceId"
                      getOptionLabel={option => option.label}
                      classes={autoCompleteStyles}
                      options={services}
                      loading={false}
                      loadingText={<p>Carregando...</p>}
                      noOptionsText={<p>Sem opções</p>}
                      disabled={!!consumption?.id}
                      value={services?.find(service => +service?.value == +consumption?.serviceId) || {}}
                      error={
                        (Object.keys(errors).length > 0 && errors?.consumptionByService[index]?.serviceId) ||
                        (values?.consumptionByService?.length - 1 == index) && !!consumptionResponseErrors?.serviceId
                      }
                      helperText={
                        (Object.keys(errors).length > 0 && errors?.consumptionByService[index]?.serviceId) ||
                        consumptionResponseErrors?.serviceId
                      }
                      onChange={(_, service) => {
                        if(service) {
                          updateConsumption("serviceId", service?.value, index);
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Serviço"
                          variant="outlined"
                        />
                      )}
                      renderOption={props => {
                        return(
                          <span {...props}>
                            <Typography color='primary'>{props.label}</Typography>
                            {(props?.categories?.length > 0) && (
                              <Typography color='textSecondary'>Categoria(s): {formatServicesCategories(props?.categories, servicesCategories)}</Typography>
                            )}
                          </span>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <NumericInput
                      name="toBeUsedContent"
                      label="Consumo por Serviço"
                      value={consumption?.toBeUsedContent}
                      onChange={value => updateConsumption("toBeUsedContent", value, index)}
                      error={
                        (Object.keys(errors).length > 0 && errors?.consumptionByService[index]?.toBeUsedContent) ||
                        (values?.consumptionByService?.length - 1 == index) && !!consumptionResponseErrors?.toBeUsedContent
                      }
                      helperText={
                        (Object.keys(errors).length > 0 && errors?.consumptionByService[index]?.toBeUsedContent) ||
                        consumptionResponseErrors?.toBeUsedContent
                      }
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Select
                      name="measurementUnitIdConsumptionService"
                      label="Unidade de Medida do Consumo"
                      options={formattedMeasurementUnits}
                      value={consumption?.measurementUnitIdConsumptionService}
                      onChange={event => updateConsumption(event.target.name, event.target.value, index)}
                      error={
                        (Object.keys(errors).length > 0 && errors?.consumptionByService[index]?.measurementUnitIdConsumptionService) ||
                        (values?.consumptionByService?.length - 1 == index) && !!consumptionResponseErrors?.measurementUnitId
                      }
                      helperText={
                        (Object.keys(errors).length > 0 && errors?.consumptionByService[index]?.measurementUnitIdConsumptionService) ||
                        consumptionResponseErrors?.measurementUnitId
                      }
                    />
                  </Grid>
                  <Grid item md={2} xs={12} style={{ display: 'flex', alignItems: 'center', gap: 10, marginTop: 5 }}>
                    <Button
                      type={!!consumption?.id ? "button" : "submit"}
                      onClick={() => !!consumption?.id ? handleEditConsumptionService(consumption) : null}
                      color="primary"
                      style={{ height: 50 }}
                    >
                      <FontAwesomeIcon
                        size="md"
                        color="#FFFFFF"
                        icon={!!consumption?.id ? faPen : faPlus}
                      />
                    </Button>
                    {!!consumption?.id && (
                      <Button
                        type="button"
                        color="error"
                        variant="outlined"
                        style={{ height: 50, backgroundColor: '#FFFFFF' }}
                        onClick={() => handleRemoveConsumptionService(consumption)}
                      >
                        <FontAwesomeIcon
                          size="lg"
                          color="#E53935"
                          icon={faTrash}
                        />
                      </Button>
                    )}
                  </Grid>
                </Grid>
              )
            })}
          </Loader>
        </Grid>
      </Grid>
    </Card>
  );
}