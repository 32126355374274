import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { numericFormatter } from 'react-number-format';
import moment from 'moment';

import { Formik, Form } from 'formik';
import { Grid, Typography, Chip } from '@material-ui/core';
import ModalDrawerSwitcher from 'components/ModalDrawerSwitcher';
import Modal from 'components/Modal';
import Loader from 'components/Loader';
import Select from 'components/Select';
import Input from 'components/Input';
import NumericInput from 'components/NumericInput';
import Button from 'components/Button';
import SingleDatePicker from 'components/SingleDatePicker';

import {
  increaseStorage,
  decreaseStorage,
  accuseEmptyStorage,
  accuseExceedingStorage,
  migrateStock
} from '../../pages/StorageHistory/store/thunk';

import { clearStorageHistoryErrors } from '../../pages/StorageHistory/store/actions';

import { loadProduct } from '../../pages/ProductsRegistration/store/thunk';
import { loadProduct as loadSellProduct } from '../../pages/ProductsSellRegistration/store/thunk';
import { loadProductsList, loadProductsFilters } from '../../pages/Products/store/thunk';

import { storageMovementValidations } from './validations';

export default function StorageMovement({
  isStorageMovementModalOpen,
  setIsStorageMovementModalOpen,
  productName,
  storageProductUuid,
  isStorageMovementSell,
  isStorageMovementConsume,
  pageOrigin,
  productType,
  productListCurrentPage
}) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { isModalLoading, errors } = useSelector(state => state.storageHistory);
  const { storages, measurementUnits } = useSelector(state => state.products.filters);
  const { storageId, measurementUnitId, netContent } = useSelector(state => state.productsRegistration.basic);
  const { quantityToUse } = useSelector(state => state.productsRegistration.storage);
  const productSellStorageId = useSelector(state => state.productsSellRegistration.storage.storageId);
  const { quantityToSell } = useSelector(state => state.productsSellRegistration.storage);
  const { qp } = useSelector(state => state.filters);
  const { isConsumeList, isSellList } = useSelector(state => state.products);

  const formattedStorages = storages?.map(storage => {
    return {
      value: storage?.id,
      label: storage?.name
    }
  });

  const formattedMeasurementUnits = measurementUnits?.map(unit => {
    return {
      value: unit?.id,
      label: unit?.description,
      abbreviation: unit?.abbreviation
    }
  });

  const [isStorageEmptyOrExceedingConfirmationModalOpen, setIsStorageEmptyOrExceedingConfirmationModalOpen] = useState(false);
  const [selectedStorageMode, setSelectedStorageMode] = useState(isSellList ? 'toSell' : 'toUse');
  const [selectedMovementMode, setSelectedMovementMode] = useState('');

  const storageModes = [
    { label: 'Venda', value: 'toSell' },
    { label: 'Consumo', value: 'toUse' }
  ];

  const isToSell = selectedStorageMode == 'toSell';
  const isToUse = selectedStorageMode == 'toUse';

  const movementModes = [
    { label: 'Entrada', value: 'increase' },
    { label: 'Saída', value: 'decrease' },
    { label: 'Acusar excedente', value: 'exceeding' },
    { label: 'Acusar zerado', value: 'empty' },
    { label: isToUse ? 'Migrar produto para venda' : 'Migrar produto para consumo', value: 'migrate' }
  ];

  const isStorageIncrease = selectedMovementMode == 'increase';
  const isStorageDecrease = selectedMovementMode == 'decrease';
  const isStorageExceeding = selectedMovementMode == 'exceeding';
  const isStorageEmpty = selectedMovementMode == 'empty';
  const isMigrate = selectedMovementMode == 'migrate';

  const measurementUnitName = formattedMeasurementUnits?.find(unit => +unit?.value == +measurementUnitId)?.label;
  const measurementUnitAbbreviation = formattedMeasurementUnits?.find(unit => +unit?.value == +measurementUnitId)?.abbreviation;

  useEffect(() => {
    if(isStorageMovementSell) {
      setSelectedStorageMode('toSell');
    }

    if(isStorageMovementConsume) {
      setSelectedStorageMode('toUse');
    }
  }, [isStorageMovementSell, isStorageMovementConsume]);

  useEffect(() => {
    dispatch(clearStorageHistoryErrors());
    dispatch(loadProductsFilters(userId, establishmentId));

    if(isStorageMovementSell) {
      dispatch(loadSellProduct(userId, establishmentId, storageProductUuid));
      return;
    }

    if(isStorageMovementConsume) {
      dispatch(loadProduct(userId, establishmentId, storageProductUuid));
      return;
    }
  }, [isStorageMovementSell, isStorageMovementConsume]);

  const getProductType = () => {
    if(isConsumeList) {
      return 'toUse';
    }

    if(isSellList) {
      return 'toSell';
    }
  }

  const getSubmitButtonLabel = () => {
    if(isStorageIncrease || isStorageDecrease) {
      return 'Cadastrar';
    }

    if(isStorageExceeding) {
      return 'Acusar estoque excedente';
    }

    if(isStorageEmpty) {
      return 'Acusar estoque zerado';
    }

    if(isMigrate) {
      return isToUse ? 'Migrar produto para venda' : 'Migrar produto para consumo';
    }

    return 'Cadastrar';
  }

  const calculateCurrentStorage = (quantityToUse, netContent) => {
    return !!+quantityToUse
      ? numericFormatter((+quantityToUse * +netContent).toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })
      : '0';
  }

  const currentStorageFormula = () => {
    if(isToSell) {
      return parseInt(quantityToSell || 0);
    }

    if(isToUse) {
      return `${numericFormatter((+netContent * +quantityToUse).toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })} ${isToUse ? `${measurementUnitName} (s)` : ''}`;
    }
  }

  const afterEditionStorageFormula = quantity => {
    if(isToSell) {
      const futureQnt = isStorageIncrease
        ? parseInt(+quantityToSell + +quantity)
        : parseInt(+quantityToSell - +quantity);

      return futureQnt;
    }

    if(isToUse) {
      const futureQnt = isStorageIncrease
        ? +quantityToUse + +quantity
        : +quantityToUse - +quantity;

      return `${numericFormatter((+netContent * futureQnt).toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })} ${isToUse ? `${measurementUnitName} (s)` : ''}`;
    }
  }

  const handleSubmit = data => {
    if(isStorageIncrease) {
      const params = {
        quantity: data.quantity,
        dateTime: `${data.date} ${moment(moment.now()).format('HH:mm:ss')}`
      }

      dispatch(increaseStorage(userId, establishmentId, storageProductUuid, productType, pageOrigin, isToSell, params))
        .then(() => {
          if(pageOrigin == '/produtos') {
            const queryParams = qp?.concat(`&type=${getProductType()}`);
            dispatch(loadProductsList({ userId, establishmentId, page: productListCurrentPage, qp: queryParams }));
          }

          setIsStorageMovementModalOpen(false);
        });
    }

    if(isStorageDecrease) {
      const params = {
        quantity: data.quantity,
        dateTime: `${data.date} ${moment(moment.now()).format('HH:mm:ss')}`
      }

      dispatch(decreaseStorage(userId, establishmentId, storageProductUuid, productType, pageOrigin, isToSell, params))
        .then(() => {
          if(pageOrigin == '/produtos') {
            const queryParams = qp?.concat(`&type=${getProductType()}`);
            dispatch(loadProductsList({ userId, establishmentId, page: productListCurrentPage, qp: queryParams }));
          }

          setIsStorageMovementModalOpen(false);
        });
    }

    if(isStorageExceeding) {
      const params = {
        quantity: data.quantity,
        ...(isToSell && { dateTime: `${data.date} ${moment(moment.now()).format('HH:mm:ss')}` })
      }

      dispatch(accuseExceedingStorage(userId, establishmentId, storageProductUuid, productType, pageOrigin, isToSell, params))
        .then(res => {
          if(!res.error) {
            if(pageOrigin == '/produtos') {
              const queryParams = qp?.concat(`&type=${getProductType()}`);
              dispatch(loadProductsList({ userId, establishmentId, page: productListCurrentPage, qp: queryParams }));
            }

            setIsStorageMovementModalOpen(false);
          }
        });
    }

    if(isStorageEmpty) {
      const params = {
        dateTime: `${data.date} ${moment(moment.now()).format('HH:mm:ss')}`
      }

      dispatch(accuseEmptyStorage(userId, establishmentId, storageProductUuid, productType, pageOrigin, isToSell, params))
        .then(() => {
          if(pageOrigin == '/produtos') {
            const queryParams = qp?.concat(`&type=${getProductType()}`);
            dispatch(loadProductsList({ userId, establishmentId, page: productListCurrentPage, qp: queryParams }));
          }

          setIsStorageMovementModalOpen(false);
        });
    }

    if(isMigrate) {
      const params = {
        quantity: data.quantity,
        fromType: isToUse ? 'use' : 'sell',
        toType: isToUse ? 'sell' : 'use'
      }

      dispatch(migrateStock(userId, establishmentId, storageProductUuid, productType, pageOrigin, params))
        .then(() => {
          if(pageOrigin == '/produtos') {
            const queryParams = qp?.concat(`&type=${getProductType()}`);
            dispatch(loadProductsList({ userId, establishmentId, page: productListCurrentPage, qp: queryParams }));
          }

          setIsStorageMovementModalOpen(false);
        });
    }
  }

  const initialValues = {
    date: moment(moment.now()).format('YYYY-MM-DD'),
    quantity: '',
    isStorageEmpty
  }

  return(
    <ModalDrawerSwitcher
      id='storage-movement-drawer'
      title='Movimentar Estoque'
      open={isStorageMovementModalOpen}
      onClose={() => setIsStorageMovementModalOpen(false)}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={storageMovementValidations}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ ...formikProps }) => {
          return(
            <Form style={{ height: '100%' }}>
              {isStorageEmptyOrExceedingConfirmationModalOpen && (
                <Modal
                  title="Confirmação de ação"
                  open={isStorageEmptyOrExceedingConfirmationModalOpen}
                  onClose={() => setIsStorageEmptyOrExceedingConfirmationModalOpen(false)}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>
                        {isStorageExceeding && "Tem certeza que deseja acusar o estoque excedente?"}
                        {isStorageEmpty && "Tem certeza que deseja acusar o estoque zerado?"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: 10, marginTop: 10 }}>
                      <Button
                        color="error"
                        onClick={() => setIsStorageEmptyOrExceedingConfirmationModalOpen(false)}
                      >
                        Cancelar
                      </Button>
                      <Button
                        color="success"
                        onClick={() => {
                          setIsStorageEmptyOrExceedingConfirmationModalOpen();
                          return formikProps.submitForm();
                        }}
                      >
                        Confimar
                      </Button>
                    </Grid>
                  </Grid>
                </Modal>
              )}
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <div>
                  <Loader isLoading={isModalLoading}>
                    <Grid container style={{ marginBottom: 10 }}>
                      <Grid item xs={12}>
                        <Chip
                          style={{ backgroundColor: 'rgba(2, 42, 92, 0.2)' }}
                          label={
                            <Typography color='primary' variant='subtitle1'>{productName}</Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                    {(isStorageMovementSell && isStorageMovementConsume) && (
                      <Grid container alignContent='center' spacing={2}>
                        <Grid item xs={12}>
                          <Select
                            id="storageType"
                            label="Estoque"
                            options={storageModes}
                            value={selectedStorageMode}
                            onChange={event => setSelectedStorageMode(event.target.value)}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Grid container alignContent='center' spacing={2}>
                      <Grid item xs={12} md={(isStorageIncrease || isStorageDecrease || isStorageExceeding) ? 6 : 12}>
                        <Select
                          id="movementModes"
                          label="Movimentação"
                          options={movementModes}
                          value={selectedMovementMode}
                          onChange={event => {
                            formikProps.resetForm();
                            setSelectedMovementMode(event.target.value);
                          }}
                        />
                      </Grid>
                      {(isStorageIncrease || isStorageDecrease) && (
                        <>
                          <Grid item xs={12} md={6}>
                            <SingleDatePicker
                              name="date"
                              label="Data"
                              initialDate={formikProps.values.date}
                              onDateChange={date => formikProps.setFieldValue("date", moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"))}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Select
                              disabled
                              id="storage"
                              label="Estoque"
                              options={formattedStorages}
                              value={isToSell ? productSellStorageId : storageId}
                            />
                          </Grid>
                          {isToUse && (
                            <Grid item xs={12} md={6}>
                              <Select
                                disabled
                                id="measurementUnit"
                                label="Unidade de medida"
                                options={formattedMeasurementUnits}
                                value={measurementUnitId}
                              />
                            </Grid>
                          )}
                          {isToUse && (
                            <Grid item xs={12} md={6}>
                              <NumericInput
                                disabled
                                id="averageQntPerItem"
                                label="Qnt. und. medida por item"
                                type="decimal"
                                value={netContent}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography color="primary">
                              Estoque atual: {currentStorageFormula()}
                            </Typography>
                          </Grid>
                          {isToSell && (
                            <Grid item xs={12} md={6}>
                              <Input
                                id="quantity"
                                type="number"
                                label={isStorageIncrease ? "Quantidade de produto a adicionar" : "Quantidade de produto a remover"}
                                value={formikProps.values.quantity}
                                error={!!errors?.quantity || formikProps.errors.quantity}
                                helperText={errors?.quantity || formikProps.errors.quantity}
                                onChange={formikProps.handleChange}
                              />
                            </Grid>
                          )}
                          {isToUse && (
                            <Grid item xs={12} md={6}>
                              <NumericInput
                                id="quantity"
                                label={isStorageIncrease ? "Quantidade de produto a adicionar" : "Quantidade de produto a remover"}
                                value={formikProps.values.quantity}
                                error={!!errors?.quantity || formikProps.errors.quantity}
                                helperText={errors?.quantity || formikProps.errors.quantity}
                                onChange={value => formikProps.setFieldValue("quantity", value)}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography color="primary">
                              Estoque após edição: {afterEditionStorageFormula(formikProps.values.quantity)}
                            </Typography>
                          </Grid>
                        </>
                      )}
                      {isStorageExceeding && (
                        <>
                          <Grid item xs={12} md={6}>
                            {isToSell && (
                              <Input
                                id="quantity"
                                type="number"
                                label="Quantidade"
                                value={formikProps.values.quantity}
                                error={!!errors?.quantity || formikProps.errors.quantity}
                                helperText={errors?.quantity || formikProps.errors.quantity}
                                onChange={formikProps.handleChange}
                              />
                            )}
                            {isToUse && (
                              <NumericInput
                                id="quantity"
                                label="Quantidade"
                                value={formikProps.values.quantity}
                                error={!!errors?.quantity || formikProps.errors.quantity}
                                helperText={errors?.quantity || formikProps.errors.quantity}
                                onChange={value => formikProps.setFieldValue("quantity", value)}
                              />
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Chip
                              style={{ backgroundColor: 'rgba(2, 42, 92, 0.2)' }}
                              label={
                                <Typography color="primary">
                                  Estoque atual: {numericFormatter(netContent?.toString() || '0', { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })} x {numericFormatter(formikProps.values.quantity?.toString() || '0', { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })} = {calculateCurrentStorage(formikProps.values.quantity, netContent)} {measurementUnitAbbreviation}
                                </Typography>
                              }
                            />
                          </Grid>
                        </>
                      )}
                      {isStorageExceeding && (
                        <Grid item xs={12}>
                          <Typography color="primary">
                            Ajuste de estoque serve para corrigir e registrar quando há inconsistência entre o sistema e a realidade do seu estoque.
                            <br />
                            <br />
                            Significa que o sistema acusou fim do estoque, porém ainda existe produto em estoque.
                            <br />
                            Segue alguns motivos para isso:
                            <br />
                            <br />
                            1 - Adição de produtos sem registro no sistema;
                            <br />
                            2 - Consumo médio do produto por serviço menor que o estipulado no sistema;
                            <br />
                            3 - Cadastrou o produto, mas não parametrizou o consumo por serviço, logo não está sendo registrado o consumo no sistema.
                            <br />
                            <br />
                            <b>Ao acusar excedente, determine o montante real em unidade de medida que seu estoque possui.</b>
                          </Typography>
                        </Grid>
                      )}
                      {isStorageEmpty && (
                        <Grid item xs={12}>
                          <Typography color="primary">
                            Ajuste de estoque serve para corrigir e registrar quando há inconsistência entre o sistema e a realidade do seu estoque.
                            <br />
                            <br />
                            Significa que na prática o seu produto acabou antes do sistema identificar.
                            <br />
                            Veja os motivos para isso ter ocorrido:
                            <br />
                            <br />
                            1 - Evasão de produto;
                            <br />
                            2 - Consumo do produto por serviço maior que o estipulado no sistema.
                            <br />
                            <br />
                            <b>Ao acusar estoque zerado, seu estoque vai ser zerado e essa informação ficará no histórico de movimentação do produto.</b>
                          </Typography>
                        </Grid>
                      )}
                      {isMigrate && (
                        <>
                          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Chip
                              style={{ backgroundColor: 'rgba(2, 42, 92, 0.2)' }}
                              label={
                                <Typography color='primary'>
                                  Total: {isToUse ? numericFormatter(quantityToUse?.toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 }) : quantityToSell}
                                </Typography>
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            {isToUse && (
                              <Input
                                id="quantity"
                                type="number"
                                label="Migrar a quantidade de"
                                value={formikProps.values.quantity}
                                error={!!errors?.quantity || formikProps.errors.quantity}
                                helperText={errors?.quantity || formikProps.errors.quantity}
                                onChange={formikProps.handleChange}
                              />
                            )}
                            {isToSell && (
                              <NumericInput
                                id="quantity"
                                label="Migrar a quantidade de"
                                value={formikProps.values.quantity}
                                error={!!errors?.quantity || formikProps.errors.quantity}
                                helperText={errors?.quantity || formikProps.errors.quantity}
                                onChange={value => formikProps.setFieldValue("quantity", value)}
                              />
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Select
                              disabled
                              id="selectedStorage"
                              label="Estoque destino"
                              options={formattedStorages}
                              value={storageId}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Loader>
                </div>
                <Grid container>
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: 10, marginTop: 20 }}>
                    <Button
                      color="tertiary"
                      variant="outlined"
                      onClick={() => setIsStorageMovementModalOpen(false)}
                    >
                      Fechar
                    </Button>
                    <Button
                      type={(isStorageExceeding || isStorageEmpty) ? "button" : "submit"}
                      color="success"
                      disabled={selectedMovementMode == ''}
                      loading={isModalLoading}
                      onClick={() => {
                        if(isStorageExceeding || isStorageEmpty) {
                          formikProps.validateForm().then(res => {
                            if(Object.keys(res).length == 0) {
                              setIsStorageEmptyOrExceedingConfirmationModalOpen(true);
                            }
                          });
                        }
                      }}
                    >
                      {getSubmitButtonLabel()}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Form>
          );
        }}
      </Formik>
    </ModalDrawerSwitcher>
  );
}