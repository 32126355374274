import * as types from './constants';

export const setProductCategories = data => ({
  type: types.SET_PRODUCT_CATEGORIES,
  data
});

export const setProductCategoriesIsLoading = isLoading => ({
  type: types.SET_PRODUCT_CATEGORIES_IS_LOADING,
  isLoading
});

export const setSingleProductCategoryIsLoading = isLoading => ({
  type: types.SET_SINGLE_PRODUCT_CATEGORY_IS_LOADING,
  isLoading
});

export const setSingleProductCategory = data => ({
  type: types.SET_SINGLE_PRODUCT_CATEGORY,
  data
});

export const clearSingleProductCategory = () => ({
  type: types.CLEAR_SINGLE_PRODUCT_CATEGORY
});