import { toast } from 'react-toastify';

import {
  fetchProductCategories,
  createProductCategory,
  updateProductCategory,
  deleteProductCategory
} from './services';

import {
  setProductCategories,
  setProductCategoriesIsLoading,
  setSingleProductCategoryIsLoading
} from './actions';

export const loadProductCategories = (userId, establishmentId) => dispatch => {
  dispatch(setProductCategoriesIsLoading(true));

  return fetchProductCategories(userId, establishmentId)
    .then(({ data }) => dispatch(setProductCategories(data)))
    .finally(() => dispatch(setProductCategoriesIsLoading(false)));
}

export const upsertProductCategory = (userId, establishmentId, id, params) => dispatch => {
  dispatch(setSingleProductCategoryIsLoading(true));

  if(id) {
    return updateProductCategory(userId, establishmentId, id, params)
      .then(() => {
        toast.success('Categoria editada com sucesso.');
        dispatch(loadProductCategories(userId, establishmentId));
        return Promise.resolve();
      })
      .catch(() => {
        toast.error('Houve um erro ao editar a categoria.');
        return Promise.reject();
      })
      .finally(() => dispatch(setSingleProductCategoryIsLoading(false)));
  }

  return createProductCategory(userId, establishmentId, params)
    .then(() => {
      toast.success('Categoria cadastrada com sucesso.');
      dispatch(loadProductCategories(userId, establishmentId));
      return Promise.resolve();
    })
    .catch(() => {
      toast.error('Houve um erro ao cadastrar a categoria.');
      return Promise.reject();
    })
    .finally(() => dispatch(setSingleProductCategoryIsLoading(false)));
}

export const removeProductCategory = (userId, establishmentId, id) => dispatch => {
  dispatch(setProductCategoriesIsLoading(true));

  return deleteProductCategory(userId, establishmentId, id)
    .then(() => {
      dispatch(loadProductCategories(userId, establishmentId));
      toast.success('Categoria excluída com sucesso.');
    })
    .catch(() => toast.error('Houve um problema ao excluir a categoria.'))
    .finally(() => dispatch(setProductCategoriesIsLoading(false)));
}