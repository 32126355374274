import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { numericFormatter } from 'react-number-format';

import { Grid, Typography, Chip, TextField, InputAdornment, Tooltip } from '@material-ui/core';
import Card from 'components/Card';
import Loader from 'components/Loader';
import NumericInput from 'components/NumericInput';
import Select from 'components/Select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export default function Storage({ formikProps }) {
  const history = useHistory();

  const isEdit = history.location.pathname == '/editar-produto';

  const { values, errors, handleChange, setFieldValue } = formikProps;

  const { basicAndStorageResponseErrors, isLoading } = useSelector(state => state.productsRegistration);
  const { storages, measurementUnits } = useSelector(state => state.products.filters);
  const submitBasic = useSelector(state => state.productsRegistration.basic);

  const formattedStorages = storages?.map(storage => {
    return {
      value: storage?.id,
      label: storage?.name
    }
  });

  const selectedMeasurementUnit = measurementUnits
    ?.find(unit => +unit?.id == +submitBasic?.measurementUnitId)
    ?.abbreviation;

  const calculateCurrentStorage = (quantityToUse, netContent) => {
    return !!+quantityToUse
      ? numericFormatter((+quantityToUse * +netContent).toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })
      : '0';
  }

  const calculateMinimalStorage = (quantityToUse, minQuantityToUse, netContent) => {
    return !!+quantityToUse
      ? numericFormatter((+minQuantityToUse * +netContent).toString(), { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })
      : '0';
  }

  return(
    <Card title="Cadastre as informações de quantidade do estoque">
      <Grid container>
        <Grid xs={12}>
          <Loader isLoading={isLoading}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Select
                  disabled
                  name="storageId"
                  label="Estoque"
                  options={formattedStorages}
                  value={submitBasic.storageId}
                  error={errors?.storageId || !!basicAndStorageResponseErrors?.storageId}
                  helperText={errors?.storageId || basicAndStorageResponseErrors?.storageId}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Tooltip
                placement="top"
                title={
                  <Typography>Quantidade atualizada de produtos em estoque.</Typography>
                }
              >
                <Grid item xs={12} md={6}>
                  <NumericInput
                    disabled={isEdit}
                    name="quantityToUse"
                    type="text"
                    label="Estoque Atual"
                    value={values?.quantityToUse}
                    onChange={value => setFieldValue("quantityToUse", value)}
                    error={errors?.quantityToUse || !!basicAndStorageResponseErrors?.quantityToUse}
                    helperText={errors?.quantityToUse || basicAndStorageResponseErrors?.quantityToUse}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <FontAwesomeIcon
                            size="lg"
                            color="#022A5C"
                            icon={faExclamationCircle}
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Tooltip>
              <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }}>
                <Chip
                  style={{ backgroundColor: 'rgba(2, 42, 92, 0.2)' }}
                  label={
                    <Typography color="primary">
                      Estoque atual: {numericFormatter(submitBasic?.netContent?.toString() || '0', { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })} x {numericFormatter(values?.quantityToUse?.toString() || '0', { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })} = {numericFormatter(calculateCurrentStorage(values?.quantityToUse, submitBasic?.netContent)?.toString() || '0', { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })} {selectedMeasurementUnit}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Tooltip
                  placement="top"
                  title={
                    <Typography>Quantidade considerada segura para evitar a falta do produto.</Typography>
                  }
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="minQuantityToUse"
                    type="number"
                    label="Estoque Mínimo"
                    value={values?.minQuantityToUse}
                    onChange={handleChange}
                    error={errors?.minQuantityToUse || !!basicAndStorageResponseErrors?.minQuantityToUse}
                    helperText={errors?.minQuantityToUse || basicAndStorageResponseErrors?.minQuantityToUse}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <FontAwesomeIcon
                            size="lg"
                            color="#022A5C"
                            icon={faExclamationCircle}
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center' }}>
                <Chip
                  style={{ backgroundColor: 'rgba(2, 42, 92, 0.2)' }}
                  label={
                    <Typography color="primary">
                      Estoque mínimo: {numericFormatter(values?.minQuantityToUse?.toString() || '0', { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })} = {numericFormatter(calculateMinimalStorage(+values?.quantityToUse, +values?.minQuantityToUse, submitBasic?.netContent)?.toString() || '0', { thousandSeparator: '.', decimalSeparator: ',', decimalScale: 2, fixedDecimalScale: 2 })} {selectedMeasurementUnit}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Loader>
        </Grid>
      </Grid>
    </Card>
  );
}