import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';

import { Paper, Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import Filter from 'components/Filter/components';
import FilterMobile from 'components/FilterMobile/components';
import MTable from 'components/MTable';
import Button from 'components/Button';
import ProductsBrandsRegistration from 'components/ProductsBrandsRegistration';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faTrash, faPen } from '@fortawesome/free-solid-svg-icons';

import { loadProductBrands, removeProductBrand } from '../store/thunk';
import { setSingleProductBrand, clearSingleProductBrand } from '../store/actions';

const headers = [
  { title: 'Nome', field: 'name' },
  { title: 'Descrição', field: 'description' }
];

export default function ProductsBrands() {
  const dispatch = useDispatch();

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { list, total, isLoading } = useSelector(state => state.productsBrands);

  const [isBrandRegistrationModalOpen, setIsBrandRegistrationModalOpen] = useState(false);
  const [search, setSearch] = useState('');

  const [productsBrandsPage, setProductsBrandsPage] = useState(1);
  const productsBrandsPageSize = 10;

  const productBrandsData = list
    ?.filter(brand => {
      if(search) {
        const name = brand?.name?.toLowerCase()?.trim();
        const description = brand?.description?.toLowerCase()?.trim();

        return name?.includes(search?.toLowerCase()) || description?.includes(search?.toLowerCase());
      }

      return brand;
    });

  const productBrandsDataPage = productBrandsData
    ?.slice(productsBrandsPageSize * productsBrandsPage - productsBrandsPageSize, productsBrandsPageSize * productsBrandsPage);

  const productsBrandsPageCount = !!search ? Math.ceil(productBrandsData?.length / productsBrandsPageSize) : Math.ceil(total / productsBrandsPageSize);

  useEffect(() => {
    setProductsBrandsPage(1);
    dispatch(loadProductBrands(userId, establishmentId));
  }, [establishmentId]);

  return(
    <Paper>
      {isBrandRegistrationModalOpen && (
        <ProductsBrandsRegistration
          open={ProductsBrandsRegistration}
          onClose={() => setIsBrandRegistrationModalOpen(false)}
        />
      )}
      {isMobile ? (
        <FilterMobile
          filter
          hasSearchTerm
          handlePageRequest={({ qp }) => {
            const { search } = qs.parse(qp);
            setSearch(search);
          }}
          actionButton={
            <Button
              type="button"
              color="success"
              onClick={() => {
                dispatch(clearSingleProductBrand());
                setIsBrandRegistrationModalOpen(true);
              }}
              startIcon={
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  color="#FFFFFF"
                  size="xs"
                />
              }
            >
              Nova Marca
            </Button>
          }
        />
      ) : (
        <Filter
          filter
          hasSearchTerm
          handlePageRequest={({ qp }) => {
            const { search } = qs.parse(qp);
            setSearch(search);
          }}
          actionButton={
            <Button
              type="button"
              color="success"
              onClick={() => {
                dispatch(clearSingleProductBrand());
                setIsBrandRegistrationModalOpen(true);
              }}
              startIcon={
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  color="#FFFFFF"
                  size="xs"
                />
              }
            >
              Nova Marca
            </Button>
          }
        />
      )}
      <MTable
        loading={isLoading}
        headers={headers}
        data={productBrandsDataPage}
        actions={[
          rowData => ({
            icon: () => (
              <FontAwesomeIcon
                icon={faTrash}
                color={!rowData?.establishmentUuid ? "rgba(246, 108, 107, 0.5)" : "#F66C6B"}
                size="xs"
              />
            ),
            disabled: !rowData?.establishmentUuid,
            tooltip: !rowData?.establishmentUuid ? 'Marca padrão não pode ser excluída' : 'Excluir marca',
            onClick: () => dispatch(removeProductBrand(userId, establishmentId, rowData?.id))
          }),
          rowData => ({
            icon: () => (
              <FontAwesomeIcon
                icon={faPen}
                color={!rowData?.establishmentUuid ? "rgba(2, 42, 92, 0.5)" : "#022A5C"}
                size="xs"
              />
            ),
            disabled: !rowData?.establishmentUuid,
            tooltip: !rowData?.establishmentUuid ? 'Marca padrão não pode ser editada' : 'Editar marca',
            onClick: () => {
              dispatch(setSingleProductBrand(rowData));
              setIsBrandRegistrationModalOpen(true);
            }
          })
        ]}
      />
      <Grid
        container
        xs={12}
        style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          count={productsBrandsPageCount}
          page={productsBrandsPage}
          onChange={(_, page) => setProductsBrandsPage(page)}
        />
      </Grid>
    </Paper>
  );
}