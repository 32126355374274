import * as yup from 'yup';

export const validations = yup.object().shape({
  name: yup
    .string()
    .required('O nome é obrigatório')
    .nullable(),
  description: yup
    .string()
    .required('A descrição é obrigatória')
    .nullable()
});