import { newAPI } from 'services';
import qs from 'qs';

export const fetchProductCategories = async (userId, establishmentId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/storage/product-types`);
  return response.data;
}

export const createProductCategory = async (userId, establishmentId, params) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/storage/product-types`, qs.stringify(params));
  return response.data;
}

export const updateProductCategory = async (userId, establishmentId, id, params) => {
  const response = await newAPI.patch(`${userId}/establishment/${establishmentId}/storage/product-types/${id}`, qs.stringify(params));
  return response.data;
}

export const deleteProductCategory = async (userId, establishmentId, id) => {
  const response = await newAPI.delete(`${userId}/establishment/${establishmentId}/storage/product-types/${id}`);
  return response.data;
}