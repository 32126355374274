import * as yup from 'yup';

export const validations = yup.object().shape({
  parentProductTypeId: yup
    .string()
    .required('O grupo da categoria é obrigatório')
    .nullable(),
  description: yup
    .string()
    .required('O nome é obrigatório')
    .nullable()
});