import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Form } from 'formik';
import { Grid, Typography } from '@material-ui/core';

import Modal from 'components/Modal';
import Loader from 'components/Loader';
import Select from 'components/Select';
import Input from 'components/Input';
import Button from 'components/Button';

import { upsertProductCategory } from 'pages/ProductsCategories/store/thunk';

import { validations } from './validations';

export default function ProductsCategoriesRegistration({ open, onClose }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { productTypes } = useSelector(state => state.products.filters);
  const { submit, isSingleProductCategoryLoading } = useSelector(state => state.productsCategories);

  const categories = productTypes
    ?.filter(type => !type?.parentProductTypeId)
    ?.map(type => {
      return {
        value: type?.id,
        label: type?.description
      }
    });

  const handleSubmit = values => {
    const params = {
      ...values
    }

    delete params['id'];

    dispatch(upsertProductCategory(userId, establishmentId, values?.id, params))
      .then(() => onClose());
  }

  const initialValues = {
    id: null,
    parentProductTypeId: '',
    description: ''
  }

  return(
    <Modal
      id='products-categories-registration-modal'
      title={submit?.id ? 'Editar categoria' : 'Nova categoria'}
      maxWidth='sm'
      open={open}
      onClose={onClose}
    >
      <Formik
        enableReinitialize
        initialValues={submit?.id ? submit : initialValues}
        onSubmit={handleSubmit}
        validationSchema={validations}
      >
        {({ ...formikProps }) => {
          return(
            <Form>
              <Loader isLoading={isSingleProductCategoryLoading}>
                <Grid container>
                  <Grid xs={12} style={{ borderBottom: '1px solid #E4E7EA', marginBottom: 10 }}>
                    <Typography color='primary' variant='subtitle2'>Nova categoria</Typography>
                  </Grid>
                  <Grid xs={12}>
                    <Select
                      id='parentProductTypeIdSelect'
                      name='parentProductTypeId'
                      label='Grupo da categoria'
                      options={categories}
                      value={formikProps.values.parentProductTypeId}
                      error={formikProps.errors.parentProductTypeId}
                      helperText={formikProps.errors.parentProductTypeId}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <Input
                      id='descriptionInput'
                      name='description'
                      type='text'
                      label='Nome'
                      value={formikProps.values.description}
                      error={formikProps.errors.description}
                      helperText={formikProps.errors.description}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  <Grid xs={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
                    <Button
                      type='button'
                      color='error'
                      onClick={onClose}
                    >
                      Fechar
                    </Button>
                    <Button
                      type='submit'
                      color='primary'
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Loader>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}