import { SET_OPTION } from './constants';

const defaultState = {
  option: 'modal'
}

export default (state = defaultState, action) => {
  switch(action.type) {
    case SET_OPTION: {
      return {
        ...state,
        option: action.option
      }
    }
    default: {
      return state;
    }
  }
}